import { connect } from 'react-redux';
import FlightBookingCryptoPayment from './FlightBookingCryptoPayment.ui';
import { resetToInitial } from '../../store/flightBookings/actions';
import { setIsPriceChangedModalOpen } from '../../store/dialogs/actions';
import { ITripReducer } from '../../store/trip/reducer';
import { IDialogsReducer } from '../../store/dialogs/reducer';

const mapStateToProps = ({
  dialogsReducer,
  tripReducer,
}: {
  dialogsReducer: IDialogsReducer;
  tripReducer: ITripReducer;
}) => {
  return {
    isPriceChangedModalOpen: dialogsReducer.isPriceChangedModalOpen,
    tripPurposeId: tripReducer.settings.tripPurposeId,
  };
};

const mapDispatchToProps = {
  setIsPriceChangedModalOpen,
  resetToInitial,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingCryptoPayment);
