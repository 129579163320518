import { InsetInput } from '@heathmont/moon-core-tw';
import { IInsetPassword } from './types';
import { useState } from 'react';

const InsetPassword: React.FC<IInsetPassword> = ({
  component,
  label,
  error,
  value,
  name,
  onChange,
  onBlur,
}) => {
  const Component = component;
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Component
      className='[&_input]:pr-16 rounded-moon-i-md [&_input]:rounded-moon-i-md'
      placeholder=' '
      error={error}
      value={value}
      name={name}
      type={visible ? 'text' : 'password'}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
    >
      <InsetInput.Label>{label}</InsetInput.Label>
      <span
        className='text-moon-14 underline cursor-pointer absolute right-4 top-2/4 -translate-y-1/2 z-10 hover:[text-shadow:0_0_0.01px_#000000]'
        onClick={() => setVisible(prevValue => !prevValue)}
      >
        {visible ? 'Hide' : 'Show'}
      </span>
    </Component>
  );
};

export default InsetPassword;

