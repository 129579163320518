import { mergeClassnames } from '@heathmont/moon-core-tw';

export const contentCN = mergeClassnames([
  'px-[40px]',
  'py-[20px]',
  'max-tablet:px-[16px]',
  'max-tablet:py-[16px]',
  'bg-goku',
  'rounded-[12px]',
]);

export const linkCN = mergeClassnames([
  'text-trunks',
  'cursor-pointer',
  'underline',
  'hover:text-[#5b5b5b]',
  'hover:underline',
]);
