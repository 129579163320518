import { useMemo } from 'react';
import { IFlightBookingOffer } from './types';
import {
  detailsReferenceCN,
  referenceCN,
  detailsCN,
} from './styles';
import { extractFlightStops } from '../FlightBookingGroup/utils';
import FlightBookingOfferAdditionalDetails from '../FlightBookingOfferAdditionalDetails';
import FlightBookingFareRulesDialog from '../FlightBookingFareRulesDialog';
import FlightBookingSummary from '../FlightBookingSummary';
import FlightOfferSegment from '../FlightOfferSegment';
import {
  hasOvernightLayover,
  getCityByIataCode,
} from '../../utils/flight-offer';
import { extractFareDetails, extractFlightOfferDetailedFareRules } from '../../utils/fare-rules';
import { getFlightDurationText } from '../FlightItinerary/utils';
import FlightBookingOfferTravelerInfo from '../FlightBookingOfferTravelerInfo';

const FlightBookingOffer: React.FC<IFlightBookingOffer> = ({
  includedDetailedFareRules,
  isMoreFareRulesModalOpen,
  withAdditionalInfo,
  withTravelersInfo,
  fareRulesOfferId,
  withReference,
  reference,
  travelers = [],
  isLoading,
  offer,
  setIsMoreFareRulesModalOpen,
  getAdditionalServices,
  getFareDetails,
}) => {
  const detailedFareRules = useMemo(() => {
    return extractFlightOfferDetailedFareRules(offer, includedDetailedFareRules);
  }, [includedDetailedFareRules]);

  const fareDetails = useMemo(() => {
    return extractFareDetails({
      travelerId: travelers[0]?.id,
      segmentId: offer.itineraries[0].segments[0].id,
      offer,
    });
  }, [travelers, offer]);

  return (
    <div className='w-full flex flex-col gap-[18px]'>
      {offer.itineraries.map((itinerary, itineraryIndex) => {
        const departure = itinerary.segments[0].departure;
        const arrival = itinerary.segments[itinerary.segments.length - 1].arrival;
        const stops = extractFlightStops(itinerary);
      
        return (
          <div className='w-full' key={itineraryIndex}>
            <FlightBookingSummary
              isBookingOverview
              overnightLayover={hasOvernightLayover(itinerary)}
              departureDate={departure.at}
              destination={getCityByIataCode(arrival.iataCode)}
              withShadow={false}
              withBorder={true}
              isLoading={isLoading}
              duration={getFlightDurationText(departure, arrival)}
              origin={getCityByIataCode(departure.iataCode)}
              stops={stops}
            />

            <div className='w-full'>
              {itinerary.segments.map((segment, segmentIndex) => (
                <FlightOfferSegment
                  isLoading={isLoading}
                  isFirst={segmentIndex === 0}
                  segment={segment}
                  stop={itinerary.segments[segmentIndex + 1] ? stops[segmentIndex] : null}
                  key={segmentIndex}
                />
              ))}
            </div>

            {(withTravelersInfo || withReference) && (
              <div className={detailsCN}>
                {withTravelersInfo && !!getAdditionalServices && !!getFareDetails && travelers.map((traveler, index) => (
                  <FlightBookingOfferTravelerInfo
                    additionalServices={getAdditionalServices(traveler.id, itinerary.segments[0].id)}
                    fareDetails={getFareDetails(traveler.id, itinerary.segments[0].id)}
                    traveler={traveler}
                    index={index}
                    key={traveler.id}
                  />
                ))}

                {
                  withReference && (
                    <div className={detailsReferenceCN}>
                      <div className={referenceCN}>
                        Reference
                      </div>
                      <div className={`${referenceCN} font-bold`}>
                        {reference}
                      </div>
                    </div>
                  )
                }
              </div>
            )}
          </div>
        );
      })}

      {withAdditionalInfo &&  (
        <FlightBookingOfferAdditionalDetails
          detailedFareRules={detailedFareRules}
          fareDetails={fareDetails}
          isLoading={isLoading}
          offer={offer}
          setIsMoreFareRulesModalOpen={setIsMoreFareRulesModalOpen}
        />
      )}

      {isMoreFareRulesModalOpen && fareRulesOfferId === offer?.id && (
        <FlightBookingFareRulesDialog
          detailedFareRules={detailedFareRules}
          onClose={() => setIsMoreFareRulesModalOpen(false)}
        />
      )}
    </div>
  );
};

export default FlightBookingOffer;
