import { FormEvent, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { InsetInput, Hint } from '@heathmont/moon-core-tw';
import InsetPassword from '../InsetPassword';
import request from '../../utils/request';
import { getInitialValues, changePasswordSchema } from './schema';
import { showToast } from '../../utils/common';
import EditAccountFormActions from '../EditAccountFormActions';
import { extractErrorFromResponse } from '../../utils/errors';

const ChangePasswordForm = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: changePasswordSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    onSubmit: () => {},
  });
  const { values, errors, touched, dirty, setFieldValue, setFieldTouched, resetForm, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      await request.update('white-label/user/password', values);
      resetForm({ values: getInitialValues(), isValidating: false });
      validateForm(getInitialValues());
      showToast('Your password has been updated.', 'success');
    } catch (err) {
      showToast(extractErrorFromResponse(err), 'error');
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <form className='w-full flex flex-col gap-2 tablet:gap-4' onSubmit={onSubmit}>
        <div className='w-full flex flex-col'>
          <InsetPassword
            component={InsetInput}
            label={'Current password'}
            error={!!(errors?.password && touched?.password)}
            value={values.password}
            name={'password'}
            onChange={({ target: { value } }) => setFieldValue('password', value)}
            onBlur={() => setFieldTouched('password')}
          />
          {!!(errors?.password && touched?.password) && (
            <Hint error>{errors?.password}</Hint>
          )}
        </div>
        <div className='w-full flex flex-col'>
          <InsetPassword
            component={InsetInput}
            label={'New password'}
            error={!!(errors?.newPassword && touched?.newPassword)}
            value={values.newPassword}
            name={'newPassword'}
            onChange={({ target: { value } }) => setFieldValue('newPassword', value)}
            onBlur={() => setFieldTouched('newPassword')}
          />
          {!!(errors?.newPassword && touched?.newPassword) && (
            <Hint error>{errors?.newPassword}</Hint>
          )}
        </div>
        <div className='w-full flex flex-col'>
          <InsetPassword
            component={InsetInput}
            label={'Confirm new password'}
            error={!!(!errors?.newPassword && errors?.confirmPassword && touched?.confirmPassword)}
            value={values.confirmPassword}
            name={'confirmPassword'}
            onChange={({ target: { value } }) => setFieldValue('confirmPassword', value)}
            onBlur={() => setFieldTouched('confirmPassword')}
          />
          {!!(!errors?.newPassword && errors?.confirmPassword && touched?.confirmPassword) && (
            <Hint error>{errors?.confirmPassword}</Hint>
          )}
        </div>

        <EditAccountFormActions
          isSubmitLoading={isSubmitLoading}
          errors={errors}
          dirty={dirty}
          discardChanges={() => {
            resetForm({ values: getInitialValues(), isValidating: false });
            validateForm(getInitialValues());
          }}
        />
      </form>
    </FormikProvider>
  );
};

export default ChangePasswordForm;

