import { combineReducers } from 'redux';
import flightBookingsReducer from './flightBookings/reducer';
import dialogsReducer from './dialogs/reducer';
import authReducer from './auth/reducer';
import tripReducer from './trip/reducer';

export default combineReducers({
  flightBookingsReducer,
  dialogsReducer,
  authReducer,
  tripReducer,
});
