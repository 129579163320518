import { connect } from 'react-redux';
import { IAuthReducer } from '../../store/auth/reducer';
import { setCurrentUser } from '../../store/auth/actions';
import App from './App.ui';

const mapStateToProps = ({ authReducer }: { authReducer: IAuthReducer }) => ({
  isAuthenticated: authReducer.isAuthenticated,
  token: authReducer.token,
});

const mapDispatchToProps = {
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
