
import React from 'react';
import { IFlightBookingFareRulesDialog } from './types';
import Modal from '../Modal';
import Accordion from '../Accordion';
import { getCityByIataCode } from '../../utils/flight-offer';
import { parseFareRule } from './utils';

const FlightBookingFareRulesDialog:  React.FC<IFlightBookingFareRulesDialog> = ({
  detailedFareRules,
  onClose,
}) => {
   return (
    <Modal
      title="Fare rules"
      resetButtonLabel="Close"
      onReset={onClose}
      onClose={onClose}
    >
      {
        detailedFareRules.map((fareRule) => {
          const { segment, descriptionType, text } = fareRule;
          const origin = getCityByIataCode(segment.departure.iataCode) || segment.departure.iataCode;
          const destination = getCityByIataCode(segment.arrival.iataCode) || segment.arrival.iataCode;

          return (
            <Accordion
              openByDefault={false}
              withShadow
              title={`${origin} - ${destination}`}
              key={+fareRule.segmentId}
            >
              <div>
                <div className='text-moon-16 font-semibold mx-[0] my-[10px] text-trunks'>
                  {descriptionType}
                </div>
                <div className='whitespace-pre-line break-words text-moon-16 text-left'>
                  {parseFareRule(text)}
                </div>
              </div>               
            </Accordion>
          );
        })
      }
    </Modal>
  )
};

export default FlightBookingFareRulesDialog;
