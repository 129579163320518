import forOwn from 'lodash/forOwn';

export const getErrorMessages = (errors = []) => {
  const errorMessages = [];

  if (typeof errors === 'string') {
    errorMessages.push(errors);
  } else if (typeof errors === 'object') {
    forOwn(errors, (err) => {
      if (typeof err === 'string') {
        errorMessages.push(err);
      }

      if (typeof err === 'object') {
        errorMessages.push(...Object.values(err));
      }
    });
  }

  return errorMessages;
};

export const extractErrorFromResponse = (err) => {
  const errors = err?.response?.data?.error;

  if (err?.response?.status === 422 && errors) {
    const errorMsgs = getErrorMessages(errors);
    return errorMsgs.join(' ');
  }

  if (Array.isArray(errors)) {
    return errors.join(' ');
  }

  if (typeof errors === 'string') {
    return errors;
  }

  return 'Something unpredictable happened. Please try again later or contact the travel manager.';
};

export const extractPaymentError = (err: any): { errorMessage: string, canRetry: boolean  } => {
  let errorMessage = 'Travel app or payment provider is unavailable. Please try again later';
  let canRetry = true;

  if (err?.response?.status === 403) {
    errorMessage = 'You are not authorized to access this page';
    canRetry = false;
  }

  return {
    errorMessage,
    canRetry,
  };
};
