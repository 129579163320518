import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { IFlightBookingPassengersForm } from './types';
import { getOptionsOnInit } from './utils';
import FlightBookingPassengerForm from '../FlightBookingPassengerForm';
import { getInitialPassengerDocument } from '../../store/flightBookings/utils';
import PassengersFormWrapper from '../PassengersFormWrapper';
import { Passenger } from '../../enums/Passenger';

const FlightBookingPassengersForm: React.FC<IFlightBookingPassengersForm> = ({
  additionalBagPerPassenger,
  additionalBagErrors,
  documentRequired,
  isPageLoading,
  bagsOptions,
  formik,
  handleSelectBaggage,
}) => {
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, validateForm } = formik;
  const [options, setOptions] = useState({
    travelDocumentsTypes: [],
    countries: [],
    airlines: [],
    loading: true,
  });

  useEffect(() => {
    getOptionsOnInit(options, setOptions);
    validateForm();
  }, []);

  useEffect(() => {
    const updatedPassengers = values.passengers.map(passenger => ({
      ...passenger,
      documentRequired,
      document: documentRequired
        ? getInitialPassengerDocument(passenger.document)
        : null,
    }));
    setValues({ passengers: updatedPassengers });
    validateForm({ passengers: updatedPassengers });
  }, [documentRequired]);

  const extractBaggageError = (index: number) => {
    return get(additionalBagErrors, [index], null);
  };

  const extractAdditionalBaggagePerUser = (passengerId: number) => {
    return additionalBagPerPassenger
      .filter(additionalBag => additionalBag?.passengerId ===passengerId)
      .map(additionalBag => additionalBag.value);
  };

  return (
    <PassengersFormWrapper
      additionalBagErrors={additionalBagErrors}
      isLoading={isPageLoading || options.loading}
      formik={formik}
      renderPassengerForm={(passenger, index) => (
        <FlightBookingPassengerForm
          travelDocumentsTypes={options.travelDocumentsTypes}
          additionalBagError={extractBaggageError(index)}
          additionalBags={extractAdditionalBaggagePerUser(passenger.id)}
          passenger={passenger as Passenger}
          countries={options.countries}
          airlines={options.airlines}
          touched={get(touched, ['passengers', index], {})}
          errors={get(errors, ['passengers', index], {})}
          bags={bagsOptions}
          handleSelectBaggage={(selectedBag) => handleSelectBaggage(selectedBag, passenger.id)}
          setFieldTouched={(field) => setFieldTouched(`passengers.${index}.${field}`, true, false)}
          setFieldValue={(field, value) => setFieldValue(`passengers.${index}.${field}`, value)}
        />
      )}
    />
  );
};

export default FlightBookingPassengersForm;
