import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BookingOverviewWrapper from '../../components/BookingOverviewWrapper';
import FlightBookingOffer from '../../components/FlightBookingOffer';
import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { Trip } from '../../enums/Trip';
import {
  getPassengerAdditionalServices,
  transformFlightToFlightOffer,
  hasPaymentsWithInvoice,
  handleDownloadInvoice,
  handleDownloadTicket,
  handleFetchTrip,
  getFareDetails,
  getSubTitle,
  getTitle,
} from './utils';

const BookingOverview = () => {
  const isMobile = useIsMobile();
  const [trip, setTrip] = useState<Trip>(null); 
  const [isTripLoading, setIsTripLoading] = useState<boolean>(true);
  const [isTicketsDownloading, setIsTicketsDownloading] = useState<boolean>(false);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState<boolean>(false);
  const { tripId } = useParams();

  useEffect(() => {
    handleFetchTrip({
      tripId,
      setIsTripLoading,
      setTrip,
    });
  }, [tripId]);

  const renderFlights = useCallback(() => {
    return trip?.flights.map(flight => (
      <FlightBookingOffer
        withTravelersInfo
        withReference
        isLoading={isTripLoading}
        reference={flight.reference}
        travelers={flight.passengers}
        offer={transformFlightToFlightOffer(flight)}
        key={flight.id}
        getAdditionalServices={(passengerId, segmentId) => getPassengerAdditionalServices({
          passengerId,
          passengers: flight.passengers,
          segmentId,
        })}
        getFareDetails={() => getFareDetails(flight)}
      />
    ));
  }, [trip]);

  const hasPayment = useMemo(() => hasPaymentsWithInvoice(trip?.payments), [trip?.payments]);

  const title = useMemo(() => getTitle(trip?.totalCost), [trip?.totalCost])

  return (
    <PageWrapper
      withBackgroundImage
      backgroundColor
      lightAuth
      isMobile={isMobile}
      page={'booking-overview'}
    >
      {!isTripLoading && (
        <BookingOverviewWrapper
          isTicketsDownloading={isTicketsDownloading}
          isInvoiceDownloading={isInvoiceDownloading}
          hasPayment={hasPayment}
          subTitle={getSubTitle(trip?.status)}
          notFound={!trip}
          title={title}
          handleDownloadInvoice={() => handleDownloadInvoice({ tripId, setIsInvoiceDownloading })}
          handleDownloadTicket={() => handleDownloadTicket({ tripId, setIsTicketsDownloading })}
          renderFlights={renderFlights}
        />
      )}
    </PageWrapper>
  );
};

export default BookingOverview;
