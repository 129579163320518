import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

export const detailsItemCN = ({ start, center, end }: { start?: boolean, center?: boolean, end?: boolean }) => mergeClassnames([
  'flex',
  'flex-col',
  'w-[calc(100%/3-4px)]',
  'mb-[10px]',
  'max-tablet:w-[calc(100%/3-8px)]',
  start && 'items-start',
  center && 'items-center',
  end && 'items-end',
]);

export const detailsTitleCN = mergeClassnames([
  'font-normal',
  'text-moon-14',
  'text-trunks',
]);

export const detailsValueCN = mergeClassnames([
  textEllipsisCN,
  'font-normal',
  'text-moon-20',
  'text-popo',
  'max-w-full',
  'max-tablet:text-moon-16'
]);
