import Footer from '../../components/Footer';
import PageWrapper from '../../components/PageWrapper';
import SignInForm from '../../components/SignInForm';
import useIsMobile from '../../hooks/useIsMobile';
import { ISignIn } from './types';

const SignIn: React.FC<ISignIn> = ({
  isContactFormOpen,
  setIsContactFormOpen,
  setAccessToken,
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper authPage isMobile={isMobile} page={'sign-in'}>
      <SignInForm setAccessToken={setAccessToken} />
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default SignIn;
