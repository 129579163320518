import { showToast } from '../../utils/common';
import request from '../../utils/request';
import { isCryptoPaymentReceived } from '../../utils/payments';
import { isBookingHasLowCostOffers } from '../../utils/flights';

export const handleFetchTransaction = async ({
  amaClientRef,
  initial,
  proceedAfterSuccessfulPayment,
  setPaymentDetails,
  setPaymentError,
  setTransaction,
  setIsLoading,
  setCanRetry,
  setOffers,
}: {
  amaClientRef: string,
  initial: boolean,
  proceedAfterSuccessfulPayment: (params: { transactionKey: string, isLowCostBooking: boolean, initial: boolean, trips: any[] }) => void,
  setPaymentDetails: (details: string) => void,
  setPaymentError: (error: boolean) => void,
  setTransaction: (transaction) => void;
  setIsLoading: (isLoading: boolean) => void,
  setCanRetry: (canRetry: boolean) => void; 
  setOffers: (offers: any[]) => void;
}) => {
  initial && setIsLoading(true);

  try {
    const { data } = await request.get(`white-label/payments/status/${amaClientRef}`);

    if (data?.error) {
      setPaymentDetails(data?.details);
      setCanRetry(data?.canRetry);
      setPaymentError(true);
      setIsLoading(false);
      return ;
    }

    if (isCryptoPaymentReceived(data?.transaction?.status)) {
      return proceedAfterSuccessfulPayment({
        isLowCostBooking: isBookingHasLowCostOffers(data?.offers),
        transactionKey: data?.transaction?.transaction_key,
        initial,
        trips: data?.trips,
      });
    }

    setPaymentDetails(data?.details);
    setTransaction(data?.transaction);
    setOffers(data?.offers);
    setPaymentError(data?.error);
    setCanRetry(data?.canRetry);
    setIsLoading(false);
  } catch (err) {
    setPaymentDetails('Travel app or crypto payment provider is unavailable. Please try again later');
    setPaymentError(true);
    setCanRetry(true);
    setIsLoading(false);
  }
};

export const handleRefreshTransaction = async ({
  amaClientRef,
  proceedAfterSuccessfulPayment,
  setRefreshTransactionLoading,
  setPaymentDetails,
  setPaymentError,
  setTransaction,
  setIsLoading,
  setCanRetry,
  setOffers,
}: {
  amaClientRef: string,
  proceedAfterSuccessfulPayment: (params: { transactionKey: string, isLowCostBooking: boolean, initial: boolean, trips: any[] }) => void,
  setRefreshTransactionLoading: (refreshTransactionLoading: boolean) => void,
  setPaymentDetails: (details: string) => void,
  setPaymentError: (error: boolean) => void,
  setTransaction: (transaction) => void;
  setIsLoading: (isLoading: boolean) => void,
  setCanRetry: (canRetry: boolean) => void;
  setOffers: (offers: any[]) => void;
}) => {
  try {
    setRefreshTransactionLoading(true);

    await request.get(`white-label/payments/refresh/${amaClientRef}`);

    setIsLoading(true);
    setPaymentError(false);
    setRefreshTransactionLoading(false);
  
    await handleFetchTransaction({
      amaClientRef,
      initial: true,
      proceedAfterSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setTransaction,
      setIsLoading,
      setCanRetry,
      setOffers,
    });
  } catch (err) {
    setRefreshTransactionLoading(false);
    return showToast(err.message, 'error');
  }
};
