import queryString from 'query-string';
import request from '../../utils/request';
import { CABIN } from '../../enums/CabinTypes';
import { initialSearch } from '../../store/flightBookings/reducer';
import { flightBookingSearchSchema } from '../../components/FlightBookingSearchForm/schema';
import { NUMBER_OF_STOPS } from '../../enums/NumberOfStopsOption';
import { formatDestinations } from '../../utils/destinations';
import { FlightSearchParams } from '../../enums/FlightSearchParams';

export const parseSearchQuery = (search: string): FlightSearchParams => {
  const { cabin, passengers, destination, origin, departureDate, returnDate } = queryString.parse(search);
  return {
    cabin: (Array.isArray(cabin) ? cabin[0] as CABIN : cabin as CABIN) || initialSearch.cabin,
    passengers: (Array.isArray(passengers) ? +passengers[0] : +passengers) || initialSearch.passengers,
    destination: (Array.isArray(destination) ? destination[0] : destination) || initialSearch.destination,
    origin: (Array.isArray(origin) ? origin[0] : origin) || initialSearch.origin,
    departureDate: (Array.isArray(departureDate) ? departureDate[0] : departureDate) || initialSearch.departureDate,
    returnDate: (Array.isArray(returnDate) ? returnDate[0] : returnDate) || initialSearch.returnDate,
  };
};

export const handleFetchOptions = async (
  iataCode: string,
  setOption: (options: object) => void,
  setOptions: (options: any) => void,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    setLoading && setLoading(true);
  
    const { data } = await request.get('white-label/locations', { params: { iataCode } });
    
    if (data?.locations?.length) {
      const destinations = formatDestinations({
        destinations: data.locations,
        popular: false,
      });
      setOptions(destinations.options);
      setOption(destinations.options[0]);
    } else {
      setOptions([]);
    }

    setLoading && setLoading(false);
  } catch (err) {
    setOptions([]);
    setLoading && setLoading(false);
  }
};

export const formatOriginDestinations = (searchParams: FlightSearchParams) => {
  const originDestinations = [
    {
      destinationLocationCode: searchParams.destination,
      departureDateTimeRange: {
        date: searchParams.departureDate,
      },
      originLocationCode: searchParams.origin,
    }
  ]

  if (searchParams.returnDate) {
    originDestinations.push({
      destinationLocationCode: searchParams.origin,
      departureDateTimeRange: {
        date: searchParams.returnDate,
      },
      originLocationCode: searchParams.destination,
    })
  }

  return originDestinations;
};

export const generateFlightOffersQuery = ({
  tripPurposeId,
  searchParams,
  amaClientRef = null,
  airlines,
  filters,
  sortBy,
  offset = 10,
  limit = 10,
  page = 1,
}) => {
  const originDestinations = formatOriginDestinations(searchParams);

  return {
    originDestinations: formatOriginDestinations(searchParams),
    travelers: searchParams.passengers,
    cabin: searchParams.cabin,
    departureTimes: originDestinations.length > 1 
      ? [filters?.departureTimeOrigin, filters?.departureTimeReturn]
      : [filters?.departureTimeOrigin],
    arrivalTimes: originDestinations.length > 1 
      ? [filters?.arrivalTimeOrigin, filters?.arrivalTimeReturn]
      : [filters?.arrivalTimeOrigin],
    maxDuration: filters?.maxDuration !== 'clear'
      ? filters?.maxDuration
      : undefined,
    maxNumberOfConnections: filters?.maxNumberOfConnections !== 'clear'
      ? filters?.maxNumberOfConnections
      : NUMBER_OF_STOPS.TWO_STOPS_MAX,
    maxPrice: filters?.maxPrice
      ? filters?.maxPrice
      : undefined,
    sortBy,
    withBaggage: filters?.withBaggage !== 'clear'
      ? !!filters?.withBaggage
      : undefined,
    airlines: airlines.every(airline => airline.checked)
      ? []
      : airlines.filter(airline => airline.checked).map(airline => airline.code),
    offset: (page - 1) * offset,
    limit,
    amaClientRef,
    tripPurposeId,
  };
};

export const validateSearchParams = async (searchParams) => {
  try {
    await flightBookingSearchSchema.validate({
      ...searchParams,
        departureDate: searchParams.departureDate
          ? new Date(searchParams.departureDate)
          : searchParams.departureDate,
        returnDate: searchParams.returnDate
          ? new Date(searchParams.returnDate)
          : searchParams.returnDate,
        destination: { value: searchParams.destination },
        origin: { value: searchParams.origin },
      }, {
        strict: true,
      });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
