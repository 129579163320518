import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import { usePageTitle } from '../../hooks/usePageTitle';
import { IPageWrapper } from './types';
import {
  backgroundImageCN,
  bannerCN,
  wrapperCN,
} from './styles';
import { detectScrollBarWidth } from './utils';

import BannerImg from '../../static/banner.png';
import SkyBackGroundImg from '../../static/sky-bg.png';
import Header from '../Header';

const PageWrapper: React.FC<IPageWrapper> = ({
  withBackgroundImage,
  backgroundColor,
  lightLogo = false,
  lightAuth = false,
  authPage = false,
  isMobile,
  children,
  page,
  renderBanner,
  onScroll,
}) => {
  const pageTitle = usePageTitle(`html.${page}.title`);

  useEffect(() => {
    detectScrollBarWidth();
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <main
        className={wrapperCN({ backgroundColor })}
        style={{ scrollbarGutter: !renderBanner ? 'stable both-edges' : 'auto' }}
        id='container'
        onScroll={onScroll}
      >
        <Header
          lightLogo={lightLogo}
          lightAuth={lightAuth}
          isMobile={isMobile}
          authPage={authPage}
        />

        {renderBanner && (
          <div
            className={bannerCN}
            style={{
              background: `url(${BannerImg}) no-repeat center center scroll`,
              backgroundSize: 'cover',
            }}
          >
            <Container maxWidth="lg">
              {renderBanner()}
            </Container>
          </div>
        )}

        {withBackgroundImage ? (
          <div
            className={backgroundImageCN}
            style={{
              background: `url(${SkyBackGroundImg}) no-repeat center center scroll`,
              backgroundSize: 'cover',
            }}
          >
            <Container maxWidth="lg">
              {children}
            </Container>
          </div>
        ) : (
          <Container maxWidth="lg">
            {children}
          </Container>
        )}
      </main>
    </>
  );
};

export default PageWrapper;
