import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Table } from '@heathmont/moon-table-v8-tw/lib/es';
import type { ColumnDef } from "@heathmont/moon-table-v8-tw/lib/es/private/types";
import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import request from '../../utils/request';
import { IAccountBookings } from './types';
import Footer from '../../components/Footer';
import { ACCOUNT_TAB } from '../../enums/AccountTabs';
import AccountWrapper from '../../components/AccountWrapper';
import { Trip } from '../../enums/Trip';
import { formatDate } from './utils';
import { routerLink } from '../../styles/buttons';
import { Link } from 'react-router-dom';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';

type DataTypeHelper = {
  destinationLabel: JSX.Element | string;
  reference: JSX.Element | string;
  tripDates: JSX.Element | string;
  index: JSX.Element | string;
};

const AccountBookings: React.FC<IAccountBookings> = ({
  isContactFormOpen,
  currentUser,
  setIsContactFormOpen,
}) => {
  const [trips, setTrips] = useState<Trip[]>(Array(5).fill(0));
  const [isTripsLoading, setIsTripsLoading] = useState<boolean>(true);
  const isMobile = useIsMobile();

  const columns = useMemo<ColumnDef<{}, DataTypeHelper>[]>(
    () => [
      {
        id: "index",
        header: () => "#",
        accessorKey: "index",
        cell: (props) => props.getValue(),
        maxSize: 70,
      },
      {
        id: "reference",
        header: () => "Reference",
        accessorKey: "reference",
        cell: (props) => props.getValue(),
        maxSize: 120,
      },
      {
        id: "destinationLabel",
        header: () => "Destination",
        accessorKey: "destinationLabel",
        cell: (props) => props.getValue(),
        maxSize: 120,
      },
      {
        id: "tripDates",
        header: () => "Trip dates",
        accessorKey: "tripDates",
        cell: (props) => props.getValue(),
        size: 220,
        minSize: 220,
      },
    ],
    [],
  );

  const data = useMemo(
    () => {
      return trips.map((trip, index) => ({
        destinationLabel: isTripsLoading 
          ? <Skeleton style={{ height: '100%', width: '100%' }} inline />
          : trip.destination?.city,
        reference: isTripsLoading ? (
          <Skeleton style={{ height: '100%', width: '100%' }} inline />
        ): (
          <div className={textEllipsisCN}>
            <Link className={mergeClassnames(routerLink, textEllipsisCN)} to={`/bookings/${trip.id}`}>
              {trip.reference}
            </Link>
          </div>
        ),
        tripDates: isTripsLoading ? (
          <Skeleton style={{ height: '100%', width: '100%' }} inline />
        ) : (
          <div className={textEllipsisCN}>
            {formatDate(trip.tripStartDate)} — {formatDate(trip.tripFinishDate)}
          </div>
        ),
        index: isTripsLoading ? (
          <Skeleton style={{ height: '100%', width: '100%' }} inline />
        ) : (
          index + 1
        ),
      }));
    },
    [trips, isTripsLoading],
  );

  const handleFetchUserTrips = async () => {
    try {
      setIsTripsLoading(true);
      const response = await request.get('white-label/user/trips');
  
      if (response?.data?.trips?.length) {
        setTrips(response.data.trips);
      } else {
        setTrips([]);
      }
    } catch (err) {
      setTrips([]);
    } finally {
      setIsTripsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchUserTrips();
  }, []);

  return (
    <PageWrapper isMobile={isMobile} page={'bookings'}>
      <AccountWrapper currentUser={currentUser} currentTab={ACCOUNT_TAB.BOOKINGS}>
        {trips.length ? (
           <div className="border border-beerus rounded-lg overflow-hidden">
            <Table
              columns={columns}
              rowGap="0"
              data={data}
            />
          </div>
        ) : (
          <p className='text-moon-16 font-normal text-bulma'>
            There are no bookings yet. To start booking follow the <Link className={routerLink} to="/">link</Link>
          </p>
        )}
      </AccountWrapper>
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default AccountBookings;
