import { UserOption } from '../../enums/UserOption';

const hasMatches = (value: string, query: string) => {
  return value
    .toLowerCase()
    .replace(/\s+/g, '')
    .includes(query.toLowerCase().replace(/\s+/g, ''));
};

export const filterOptions = (query: string, users: UserOption[]) => {
  if (query === '') {
    return users;
  }

  return users.filter(({ firstName, lastName, email }) => {
    return  hasMatches(firstName, query)
      || hasMatches(lastName, query)
      || hasMatches(email, query)
      || hasMatches(`${firstName} ${lastName}`, query);
  });
};
