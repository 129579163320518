import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { TripSettings } from '../../enums/TripSettings';
import { SET_TRIP_SETTINGS } from './actionTypes';

export interface ITripReducer {
  settings: TripSettings;
};

const getInitialState = ():ITripReducer => {
  let tripSettings;

  try {
    tripSettings = sessionStorage.getItem('tripSettings');
    tripSettings = JSON.parse(tripSettings);
  } catch (err) {
    tripSettings = {};
  }

  return {
    settings: {
      tripPurposeDetails: tripSettings?.tripPurposeDetails ?? '',
      paymentRequired: tripSettings?.paymentRequired ?? true,
      useCompanyEmail: tripSettings?.useCompanyEmail ?? true,
      tripPurposeId: tripSettings?.tripPurposeId ?? null,
      paymentTypes: tripSettings?.paymentTypes ?? [PAYMENT_TYPE.CRYPTO, PAYMENT_TYPE.CARD],
      email: tripSettings?.email ?? '',
    },
  };
};

const tripReducer = (state = getInitialState(), action): ITripReducer => {
  switch (action.type) {
    case SET_TRIP_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
  
    default:
      return state;
  }
};

export default tripReducer;
