import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import Accordion from '../../components/Accordion';
import {
  sectionSubTitleCN,
  sectionTitleCN,
  paragraphCN,
  linkCN,
} from './styles';
import { pageContentCN, pageContentWrapperCN } from '../../styles/wrappers';
import { IFlightBookingPrivacyAndPolicy } from './types';
import Footer from '../../components/Footer';
import { mergeClassnames } from '@heathmont/moon-core-tw';

const FlightBookingPrivacyAndPolicy: React.FC<IFlightBookingPrivacyAndPolicy> = ({
  setIsContactFormOpen,
  isContactFormOpen,
}) => {
  const isMobile = useIsMobile();

  return (
    <PageWrapper
      isMobile={isMobile}
      page={'privacy-and-policy'}
    >
      <div className={mergeClassnames(pageContentWrapperCN, 'mb-0')}>
        <div className={mergeClassnames(pageContentCN, 'mb-0')}>
          <Accordion
            openByDefault
            withoutArrow
            disabled
            title='Privacy Policy'
          >
            <div>
              <h3 className={sectionTitleCN} >1. Introduction</h3>
              <p className={paragraphCN}>
                1.1. Heathmont Travel is a travel undertaking that uses personal data in its day-to-day operations for the purpose of provision, arrangement, organisation and mediation of various travel and tourism services. In our activities, we follow the General Data Protection Regulation (GDPR), the Estonian Personal Data Protection Act (PDPA) and other established data protection rules. This Privacy Notice is aimed at the data subject specified in the GDPR and in the PDPA, i.e. at natural persons whose personal data are processed for provision of the service.
              </p>
              <p className={paragraphCN}>
                1.2. We presume that you (Data Subjects) are aware of and care about the processing of your personal data and therefore we herewith assure you that Heathmont Travel takes adherence to the rules established with regard to processing your data very seriously. The Privacy Notice describes the principles and practice used by Heathmont Travel regarding the entire chain of processing personal data from the collection and use to deletion, thereby focusing on the protection of personal data. The protection of personal data is an ongoing responsibility and therefore we revise the rules of the Privacy Notice from time to time, check its compliance with the established requirements and, where necessary, update its contents.
              </p>

              <h3 className={sectionTitleCN} >2. Data Protection Officer (DPO)</h3>
              <p className={paragraphCN}>
                2.1. Heathmont Travel, registry code 14790371, legal address Suur-Patarei 13, Tallinn 10415, Harju County, Estonia, has appointed a specialist to ensure compliance with data protection requirements.
              </p>
              <p className={paragraphCN}>
                The DPO is available via email: <a className={linkCN} href={`mailto:dpo@yolo.com`} target="_blank">dpo@yolo.com</a> 
              </p>

              <h3 className={sectionTitleCN} >3. Collection of data</h3>
              <p className={paragraphCN}>
                3.1. Heathmont Travel collects personal data mainly from its customers. As a rule, these are data that are needed for the provision of travel and tourism services chosen by the customer and may be clarified in the framework of performance of a specific travel service and vary depending on the means of transport (e.g. bus, train, plane, ship) as well as the destination (a domestic trip, a trip within the territory of the EU and equivalent countries, a trip outside the EU). Usually, in order to be able to travel, such data as the first name and surname, a travel/identity document with a photo that allows for identifying the person, the personal identification code (upon absence thereof, the date of birth, gender and age) as well as contact details such as the e-mail address, telephone number and address of the place of residence are required. Upon travelling outside the EU, the list of data may be longer, depending on the laws and requirements of the country of destination (e.g. nationality, passport details. visa, vaccination, etc.). We use your personal data in order to perform the contract between us and provide you with travel and tourism services. We do not sell your personal data to or share them with third parties, except those that we need to share them with in accordance with the law or for the purpose of performance of the service contract concluded between you and us.
              </p>
              <p className={paragraphCN}>
                3.2. If you wish to place an order via our webapp, we may need your contact details like your name, personal identification code, date of birth, details of the travel document, e-mail address, telephone number and in some cases also the address of the place of residence. This information is required only for the purpose of contacting you regarding your order and performance of the contract made or to be made with you. We share your personal data with the businesses that are directly involved in providing you with the service. We do not share your personal data with anyone else. At the time of placing the order we also ask you for information regarding payment for the order, such as your credit card number or bank payment details. Thereby we use a secure online connection to protect your personal data.
              </p>

              <h3 className={sectionTitleCN} >4. Processing personal data</h3>
              <h4 className={sectionSubTitleCN}>4.1. Processing of personal data in the course of provision and mediation of accommodation services</h4>
              <p className={paragraphCN}>
                4.1.1.  If you buy an accommodation service, your data (name, personal identification code and/or gender, date of birth, contact details) are required for complying with the requirements imposed on accommodation establishments as well as for provision of the service. The list of personal data may vary depending on requirements established in different countries. For that purpose it is necessary to know the data of the persons who will be using the accommodation service as well as the time when accommodation is needed. Generally, accommodation establishments are required to keep a register of accommodated persons, which contains the data required by law, and disclosed the data to law enforcement agencies, where required.
              </p>
              <p className={paragraphCN}>
                4.1.2. Depending on the substance of the service, we may need personal data of various types owing to the characteristics of the accommodation service. For instance, it may be related to the need to have certain mobility aids (a wheelchair, lift, etc.) available in the case of a person with restricted mobility or special dietary data in the case of intolerance of certain substances (in an event where dining has been ordered in addition to accommodation).
              </p>
              <p className={paragraphCN}>
                4.1.3. Upon sale of the accommodation service and related services, where the best price has been offered by a wholesaler (incl. aggregators and consolidators), we transfer your data to the wholesaler of the services (incl. aggregators and consolidators) who will, in turn, transfer the data to the specific provider of the accommodation service or of the related services.
              </p>
              <p className={paragraphCN}>
                4.1.4. We demand that the accommodation service providers as well as the wholesalers (incl. aggregators and consolidators) process personal data in accordance with the GDPR. Each party involved in the provision of the accommodation service is allowed to use personal data solely for the purpose of performing the contract. Upon provision of accommodation services, Heathmont Travel is not responsible for the processing of the data given to the accommodation establishment on the spot.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.2. Provision and mediation of passenger carriage services
              </h4>
              <p className={paragraphCN}>
                4.2.1. In order to provide passenger carrier services, we need from you such personal data as your name, personal identification code or passport number, details of the travel document, e-mail address, telephone number as well as service-related data. In the course of provision of passenger carriage services, information on the personal data of a special type may be disclosed (e.g. the need for a wheelchair in the case of restricted mobility, the data of children and people accompanying them). The law obligates the service provider to forward personal data to law enforcement agencies.
              </p>
              <p className={paragraphCN}>
                4.2.2 If you have bought from us the passenger carriage service mediation service, we usually transfer the collected data to the provider of the passenger carriage service or to the mediator of passenger carriage service providers who, in turn, transfers the data to the provider of the specific service.
              </p>
              <p className={paragraphCN}>
                4.2.3. Upon provision of passenger carriage services, Heathmont Travel is not responsible for the processing of the data given directly to the carrier.
              </p>
              <p className={paragraphCN}>
                4.2.4. We demand that our service providers process personal data in compliance with the GDPR. Each party involved in the provision of the accommodation service is allowed to use personal data solely for the purpose of performing the contract.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.3. Processing of personal data in the course of mediation of package travel
              </h4>
              <p className={paragraphCN}>
                4.3.1. If you buy a package tour, your data (name, personal identification code and/or gender, date of birth, contact details) are required for complying with the requirements established by the tour operator as well as for provision of the service. The list of personal data may vary depending on requirements established in different countries. For that purpose it is necessary to know the data of the persons who will be using the travel service as well as the time of travel.
              </p>
              <p className={paragraphCN}>
                4.3.2. Depending on the substance of the service, we may need personal data of various types owing to the characteristics of the passenger carriage service or accommodation service. For instance, it may be related to the need to have certain mobility aids (a wheelchair, lift, etc.) available in the case of a person with restricted mobility or special dietary data in the case of intolerance of certain substances (in an event where dining has been ordered in addition to accommodation).
              </p>
              <p className={paragraphCN}>
                4.3.3. Upon sale of package travel and related services, we may transfer your data to the tour operator who, in turn, transfers the data to the specific providers of the passenger carriage service or accommodation service or related services.
              </p>
              <p className={paragraphCN}>
                Upon provision of passenger carriage services, Heathmont Travel is not responsible for the processing of the data given directly to the tour operator.
              </p>
              <p className={paragraphCN}>
                4.3.4 We demand that tour operators process personal data in accordance with the GDPR. Each party involved in the provision of the travel service is allowed to use personal data solely for the purpose of performing the contract.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.4. Processing of personal data in the course of provision and mediation of conference services
              </h4>
              <p className={paragraphCN}>
                4.4.1. In the course of provision of conference services we process personal data, above all, for performance of the conference service contract, e.g. we register participants and issue invoices (name, personal identification code, date of birth, telephone, e-mail, address), organise translation services, organise photographer and operator services (photographs), organise and edit video transmissions, organise the making and distribution of memorabilia and publications (incl. name tags), organise dining (information on allergies and special diets, which constitute personal data of a special type). In the course of organising cultural and leisure time programmes, we also process personal data required for organising these (name, time of the programme, person’s preferences regarding various programmes), organising the accommodation (in the case of accommodation services see clause 3.1.1) and organising transport between the accommodation establishments and the venue of the conference (people’s names, time of transport, place of accommodation and conference). The list of personal data may vary depending on requirements established in different countries.
              </p>
              <p className={paragraphCN}>
                4.4.2. Upon provision of conference services, Heathmont Travel is not responsible for the processing of the data given directly to the service provider.
              </p>
              <p className={paragraphCN}>
                4.4.3 We demand that our service providers process personal data in compliance with the GDPR. Each party involved in the provision of the conference service is allowed to use personal data solely for the purpose of performing the contract.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.5. Processing personal data in the course of provision and mediation of guide, guide/interpreter and tour manager services
              </h4>
              <p className={paragraphCN}>
                4.5.1. To provide guide, guide/interpreter and tour manager services, we need from you such personal data as your name, personal identification code, time of provision of the service, the language pair and destinations that you will visit. In the course of provision of tour manager services, information on the personal data of a special type may be disclosed (e.g. the need for a wheelchair in the case of restricted mobility, the data of children and people accompanying them). Depending on the destination and the country of destination, your personal data may need to be transferred to a respective authority of the destination or the country of destination, where required.
              </p>
              <p className={paragraphCN}>
                4.5.2. If you have bought the guide, guide/interpreter and tour manager service mediation service from us, we transfer the data usually to the wholesaler of guide, guide/interpreter and tour manager services (incl. aggregators and consolidators) who, in turn, transfers the data to a provider of the specific service.
              </p>
              <p className={paragraphCN}>
                4.5.3. We demand that our service providers process personal data in compliance with the GDPR. Each person involved in providing guide, guide/interpreter and tour manager services may use personal data solely for performing the contract.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.6. Provision and mediation of the visa application service
              </h4>
              <p className={paragraphCN}>
                4.6.1. In order to provide the visa application service, we need from you such personal data as your name, personal identification code, details of your valid travel document, the country of destination, the preferable term of validity of the visa, the reason for visiting the country of destination, and other compulsory data requested by the country that you wish to visit.
              </p>
              <p className={paragraphCN}>
                4.6.2. We demand that our service providers process personal data in compliance with the GDPR. Each party involved in the provision of the service is allowed to use personal data solely for the purpose of performing the contract.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.7. Mediation of a travel-related insurance service
              </h4>
              <p className={paragraphCN}>
                4.7.1. To provide a travel-related insurance service via mediation service we may need such personal data as your name, personal identification code, place of residence, e-mail address and telephone number. In the course of performance of a respective contract, we may also learn information on your illness or that of your family member (insured event, travel disruption insurance), accident and medical expenses as well as personal data disclosed due to other unforeseeable insured events.
              </p>
              <p className={paragraphCN}>
                4.7.2. We transfer the personal data to the insurer whom we require to process personal data in compliance with the GDPR.
              </p>
              <h4 className={sectionSubTitleCN}>
                4.8. Provision or mediation of a means of transport rental service
              </h4>
              <p className={paragraphCN}>
                4.8.1. In order to provide or mediate a means of transport rental service, we need from you such personal data as your name, personal identification code, place of residence, contact details, to the required extent the details of a document certifying the right to drive a vehicle of the respective category, the credit card details, etc. In the course of performance of the contract we may learn, among other things, information on your preferences in choosing various cars, the names of the persons travelling in the car, the times and routes of the rides. We would like to point out that the things that you forget in the car may also contain information about you and in that regard Heathmont Travel is not liable for using the data.
              </p>
              <p className={paragraphCN}>
                4.8.2. In the case of provision or mediation of a means of transport rental service, we transfer the submitted personal data to the provider of the specific means of transport rental service and demand that the service provider comply with the GDPR when processing personal data. Heathmont Travel is not responsible for the handling of data that you give to the provider of the means of transport rental service on the spot.
              </p>
              <p className={paragraphCN}>
                4.8.3. If you have bought the means of transport rental service mediation service from us, we transfer the data required for booking the service usually to the mediator of the rental service and they, in turn, transfer the collected data to the provider of the specific service.
              </p>

              <h3 className={sectionTitleCN} >
                5. Sending travel confirmations and reminders
              </h3>
              <p className={paragraphCN}>
                5.1. We will send you informative email messages regarding your travel when you have made the purchase from us, during the course of the travel preparation or when your employer has appointed you as your company’s authorised person. These messages are confirmatory, so you know that your email has been registered with us. 
              </p>
              <p className={paragraphCN}>
                5.2. In connection with the provision of the service we have the right to send you a feedback email message when you have purchased from us or asked for an offer regarding a service. We send feedback email messages only to identify service bottlenecks and provide the best service. If there is a natural disaster or an emergency situation, we can also contact you to make certain that you are alright and see if we can help you.
              </p>

              <h3 className={sectionTitleCN} >
                6. Time and manner of keeping data
              </h3>
              <p className={paragraphCN}>
                6.1. Data collected on you shared regarding the travel details via your purchases are kept by Heathmont Travel as long as required by law and until the date of expiry of claims, after which the personal data are deleted. The data are kept in a single database or in multiple databases administered by a third party located in the European Union or in a contracting state of the European Economic Area. These third parties do not have access to the data and they do not use your personal data for any other purpose besides retention and backup.
              </p>

              <h3 className={sectionTitleCN} >
                7. Time and manner of use of your personal data by Heathmont Travel
              </h3>
              <p className={paragraphCN}>
                7.1. Your personal data are used mainly for providing you with a service with your consent.
              </p>
              <p className={paragraphCN}>
                7.2. Your personal data is also used for updating the online environment according to your preferences, interests, needs; and in order to learn to know your wishes and preferences better and improve the aspects of provision of the services of Heathmont Travel’s online environments.
              </p>
              <p className={paragraphCN}>
                7.3. Your personal data is shared with the service providers whose service is indispensable for the purpose of performance of the concluded contracts and provision of the services.
              </p>
              <p className={paragraphCN}>
                7.4. We can also share your personal data when such need arises from investigation of criminal offences, compliance with judicial demands, fulfillment of your vital needs or an act related to sales, purchase, merger, reorganisation, financing, liquidation, winding up or a similar business-related act. In such events we take any and all measures necessary to sufficiently protect your personal data.
              </p>

              <h3 className={sectionTitleCN} >
                8. Transfer of personal data outside the EU or equivalent regions
              </h3>
              <p className={paragraphCN}>
                8.1. Heathmont Travel is located in the Republic of Estonia, which is a member state of the European Union. The personal data that we collect are processed mainly in the Republic of Estonia as well as at the legal addresses of third parties. If data needs to be transferred outside the European Union or equivalent territories during the provision of a service for the purpose of performance of the contract, Article 45 of the GDPR demands that the level of protection guaranteed to the personal data be at least the same as in the territory of the EU. This is to inform you that our company does not have any other legal means besides contractual ones to give such a guarantee, which means that we are able to obtain confirmations regarding the sufficiency of the respective level of protection from those businesses with whom it is possible to conclude contracts and negotiate contract terms and conditions as well as to obligate such businesses to guarantee the respective level of protection themselves, but we are not in any way able to guarantee the sufficiency or GDPR compliance of such measure.
              </p>
              <p className={paragraphCN}>
                8.2. However, if we cannot reach an agreement with the respective service provider regarding the compliance of the level of protection of personal data with the requirements of the GDPR, we warn you that the level of protection of personal data in the respective country of destination is not at the same good level as that of the GDPR and explain that we do not have any means to in any way guarantee the high level of protection of personal data regarding the country of destination. If you still wish to receive a service at such a destination, you certify by purchasing the trip that we are allowed to transfer your personal data to such destination.
              </p>
              <p className={paragraphCN}>
                8.3. We never send to a service provider outside the EU more personal data than required at very minimum to approve the service or more than you would be required to submit to them if you ordered the same service directly from them, i.e. without using our services.
              </p>

              <h3 className={sectionTitleCN} >
                9. Data subject’s rights
              </h3>
              <p className={paragraphCN}>
                9.1. The Privacy Notice is meant to inform you what information Heathmont Travel collects about you and how it is used. If you have questions regarding your personal data, please contact us by e-mail <a className={linkCN} href={`mailto:dpo@yolo.com`} target="_blank">dpo@yolo.com</a>
              </p>
              <p className={paragraphCN}>
                9.2. If you would like to learn whether Heathmont Travel processes your personal data or you wish access to your personal data, please contact us by e-mail <a className={linkCN} href={`mailto:dpo@yolo.com`} target="_blank">dpo@yolo.com</a>
              </p>
              <p className={paragraphCN}>
                For further information on the rights of the data subject see here.
              </p>

              <h3 className={sectionTitleCN} >
                10. Security of your data
              </h3>
              <p className={paragraphCN}>
                10.1. In order to protect personal data and information that allows for identification, which you enter in our online environment, we use physical, technical and administrative protection measures. We regularly update and test our protection technologies. Our online networks are protected by firewalls and intrusion detection software. Access to your personal data is available only to the employees who need it for the purpose of providing you with the agreed service or on another legal ground.
              </p>
              <p className={paragraphCN}>
                10.2. We take sufficient measures to protect your personal data and our activity is subject to relevant information security legislation, but we would like to point out that no website, webapp or database is fully secure or hacking-proof. Protect yourself and help us prevent computer crime by protecting and keeping your passwords very carefully. Our online environment does not use spyware. If you suspect that your account has been hacked, contact us immediately.
              </p>
              <p className={paragraphCN}>
                10.3. Heathmont Travel  trains its staff in order to attain a higher level of awareness of the importance and need for protection of personal data. Our dedication is also manifested in internal rules that contain data protection provisions.
              </p>

              <h3 className={sectionTitleCN} >
                11. Amendment and revision of the Privacy Notice
              </h3>
              <p className={paragraphCN}>
                11.1. As any organisation, Heathmont Travel  also changes in time and space, which means that presumably the Privacy Notice will need to be amended and revised at one point in the future. For this reason we reserve the right to amend and revise the Privacy Notice at any time without informing you about it. We publish the amendments on the website of Heathmont Travel’s Privacy Notice. We can inform you by e-mail about any major amendments in the Privacy Notice, but the safest choice would be to regularly visit the Privacy Notice webapp in order to read the updated and effective terms and conditions. 
              </p>

              <h3 className={sectionTitleCN} >
                12. Questions, complaints
              </h3>
              <p className={paragraphCN}>
                12.1. If your personal data has changed, inform us about it. If you have any additional questions about your personal data, contact us. We will reply within the term prescribed by law. At the same time, be prepared that we may ask more detailed information from you in order to identify you before answering the questions. In order to ensure that the contracts serving as the basis for processing personal data sufficiently ensure the rights of the data subjects, we reserve the right to demand that a document certifying the data subject’s right of representation, which is submitted in the course of performance of the legal relationship between the parties or thereafter (among other things, in connection with data processing), and which is drawn up outside our travel agency, be authenticated by a notary or authenticated in an equivalent manner. We must be certain that the data subject consents to sending information and the information is transferred only to the right person or organisation. In most of the chases we correct or delete any inaccuracy that you have detected. In some cases we can fully or partially refuse to grant your request if the law allows for it or demands it.
              </p>
              <p className={paragraphCN}>
                12.2. If you have any questions or complaints, please contact our Data Protection Officer through email: <a className={linkCN} href={`mailto:dpo@yolo.com`} target="_blank">dpo@yolo.com</a>
              </p>

              <h3 className={sectionTitleCN} >
                13. Amendments to the Privacy Notice:
              </h3>
              <p className={paragraphCN}>
                13.1. We may periodically update this statement. For visitors to our webapp, an updated policy will always be available on the website and webapp.
              </p>
              <p className={`${paragraphCN} mb-[30px]`}>
                13.2. This privacy statement is effective as of April 3rd of 2024.
              </p>
            </div>
          </Accordion>
        </div>
        <Footer
          isContactFormOpen={isContactFormOpen}
          setIsContactFormOpen={setIsContactFormOpen}
        />
      </div>
    </PageWrapper>
  );
};

export default FlightBookingPrivacyAndPolicy;
