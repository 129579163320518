import { Link } from 'react-router-dom';
import { routerLink } from '../../styles/buttons';

const AuthFormLinks = () => {
  return (
    <div>
      <p>
        Remembered your password? <Link className={routerLink} to='/sign-in'>Sign in to your account</Link>
      </p>
      <p>
        Don’t have an account yet? <Link className={routerLink} to='/sign-up'>Sign up to start your journey</Link>
      </p>
    </div>
  );
};

export default AuthFormLinks;

