
export const getPassengerFullName = (passenger) => {
  const name = [];

  if (passenger.firstName) {
    name.push(passenger.firstName);
  }

  if (passenger.lastName) {
    name.push(passenger.lastName);
  }

  return name.length ? name.join(' ') : null;
};
