export enum FLIGHT_BOOKING_STEP {
  INITIAL_SEARCH = '',
  FLIGHT_LIST = 'flight-booking-list',
  PRICE_CONFIRMATION = 'flight-booking-overview',
  PASSENGERS = 'flight-booking-passengers',
  PAYMENT = 'flight-booking-payment',
  SUCCESS = 'flight-booking-confirmation',
  CRYPTO_PAYMENT = 'flight-booking-crypto-payment',
  CRYPTO_PAYMENT_CHECKOUT = 'crypto-payment-checkout',
  CARD_PAYMENT = 'flight-booking-card-payment',
  CARD_PAYMENT_CHECKOUT = 'card-payment-checkout',
  ADDITIONAL_PAYMENT = 'flight-booking-additional-payment',
  SUCCESSFUL_PAYMENT = 'flight-booking-successful-payment',
  PAYMENT_CHANGE = 'flight-booking-payment-change',
  REQUEST_CHANGES = 'flight-booking-request-changes',
  PRIVACY_AND_POLICY = 'privacy-and-policy',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  ABOUT_US = 'about-us',
};

export enum FLIGHT_BOOKING_STEP_STATUS {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
};

export const flightBookingSteps = {
  [FLIGHT_BOOKING_STEP.INITIAL_SEARCH]: {
    next:  null,
    back: null,
  },
  [FLIGHT_BOOKING_STEP.FLIGHT_LIST]: {
    next:  null,
    back: FLIGHT_BOOKING_STEP.INITIAL_SEARCH,
  },
  [FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION]: {
    next:  FLIGHT_BOOKING_STEP.PASSENGERS,
    back: FLIGHT_BOOKING_STEP.FLIGHT_LIST,
  },
  [FLIGHT_BOOKING_STEP.PASSENGERS]: {
    next:  FLIGHT_BOOKING_STEP.PAYMENT,
    back: FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION,
  },
  [FLIGHT_BOOKING_STEP.PAYMENT]: {
    next:  FLIGHT_BOOKING_STEP.SUCCESS,
    back: FLIGHT_BOOKING_STEP.PASSENGERS,
  },
  [FLIGHT_BOOKING_STEP.SUCCESS]: {
    next:  null,
    back: null,
  }
};
