import { connect } from 'react-redux';
import FlightBookingPrivacyAndPolicy from './FlightBookingPrivacyAndPolicy.ui';
import { setIsContactFormOpen } from '../../store/dialogs/actions';

const mapStateToProps = ({
  dialogsReducer,
}) => {
  return {
    isContactFormOpen: dialogsReducer.isContactFormOpen,
  };
};

const mapDispatchToProps = {
  setIsContactFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingPrivacyAndPolicy);
