import moment from 'moment';
import Yup from '../../utils/validation';
import { PassengerDocument } from '../../enums/PassengerDocument';

const VALIDATIONS = {
  DOCUMENT_EXPIRATION_DATE: {
    MAX: 50,
    MIN: 3,
  },
  DOCUMENT_NUMBER: {
    MAX: 15,
    MIN: 5,
  },
};

const validateMessages = {
  country: {
    required: 'Country issued document cannot be empty',
  },
  type: {
    required: 'Document type cannot be empty',
  },
  number: {
    alphanumeric: 'Document number can contain only alpha-numeric chars (without spaces)',
    required: 'Document number cannot be empty',
    min: `Document number cannot be less than ${VALIDATIONS.DOCUMENT_NUMBER.MIN} characters`,
    max: `Document number cannot be longer than ${VALIDATIONS.DOCUMENT_NUMBER.MAX} characters`,
  },
  validTill: {
    required: 'Document expiration date cannot be empty',
    max: `Document expiration date cannot be after ${VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MAX} years from today`,
    min: `Document expiration date can not be sooner than ${VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MIN} months after today`,
  },
};

export const userProfileSchema = Yup
  .object()
  .shape({
    typeId: Yup
      .number()
      .required(validateMessages.type.required),
    countryId: Yup
      .number()
      .required(validateMessages.country.required),
    number: Yup
      .string()
      .min(VALIDATIONS.DOCUMENT_NUMBER.MIN, validateMessages.number.min)
      .max(VALIDATIONS.DOCUMENT_NUMBER.MAX, validateMessages.number.max)
      .withoutSpaces()
      .test('only-alphanumeric', validateMessages.number.alphanumeric, (value = '') => (
        /^[a-z0-9]*$/i.test(value)
      ))
      .required(validateMessages.number.required),
    validTill: Yup
      .date()
      .required(validateMessages.validTill.required)
      .test('max-expiration-date', validateMessages.validTill.max, (value) => (
        moment().add(VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MAX, 'years').isAfter(value)
      ))
      .test('min-expiration-date', validateMessages.validTill.min, (value) => (
        moment().add(VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MIN, 'months').isBefore(value)
      ))
      .nullable(),
  });

export const getInitialValues = (document: PassengerDocument | null): PassengerDocument => {
  return {
    validTill: document?.validTill ?? '',
    countryId: document?.countryId ?? null,
    typeId: document?.typeId ?? null,
    number: document?.number ?? '',
  };
};
