import { InsetInput, Group, Hint } from '@heathmont/moon-core-tw';
import { ILoyaltyProgramSelector } from './types';
import SelectOptions from '../SelectOptions';
import { clearButtonCN } from './styles';
import { fieldLabelWrapperCN, fieldWrapperCN, fieldLabelCN, groupCN } from '../../styles/form-field';

const LoyaltyProgramSelector: React.FC<ILoyaltyProgramSelector> = ({
  loyaltyNumber,
  passengerId,
  airlines,
  touched,
  errors,
  setFieldTouched,
  setFieldValue,
}) => {
  const getFieldBaseProps = (field) => {
    return {
      name: `${field}_${passengerId}`, 
      id: `${field}_${passengerId}`,
    };
  };

  const resetFrequentFlayer = () => {
    setFieldValue('loyaltyNumber', { airlineId: null, number: '' });
  };

  return (
    <div className={fieldWrapperCN}>
      <div className={fieldLabelWrapperCN}>
        <h5 className={fieldLabelCN}>
          Frequent flyer (optional)
        </h5>

        <span className={clearButtonCN} onClick={resetFrequentFlayer}>
          Clear all
        </span>
      </div>

      <Group className={groupCN}>
        <Group.FirstInsetSelect
          {...getFieldBaseProps('loyaltyNumber.airlineId')}
          error={!!(errors?.airlineId && touched?.airlineId)}
          value={loyaltyNumber.airlineId || ''}
          label={'Frequent flyer program'}
          onChange={({ target: { value } }) => setFieldValue('loyaltyNumber.airlineId', value)}
          onBlur={() => setFieldTouched('loyaltyNumber.airlineId')}
        >
          <SelectOptions options={airlines} label='frequent flyer program' />
        </Group.FirstInsetSelect>
      
        <Group.LastInsetInput
          {...getFieldBaseProps('loyaltyNumber.number')}
          placeholder=' '
          error={!!(errors?.number && touched?.number)}
          value={loyaltyNumber.number}
          type='text'
          onChange={({ target: { value } }) => setFieldValue('loyaltyNumber.number', value)}
          onBlur={() => setFieldTouched('loyaltyNumber.number')}
        >
          <InsetInput.Label>Flyer number</InsetInput.Label>
        </Group.LastInsetInput>
      </Group>
      
      {!!(errors?.airlineId && touched?.airlineId) && (
        <Hint error>{errors?.airlineId}</Hint>
      )}
      {!!(errors?.number && touched?.number) && (
        <Hint error>{errors?.number}</Hint>
      )}
    </div>
  );
};

export default LoyaltyProgramSelector;
