import { isCryptoPaymentReceived } from '../../utils/payments';
import { showToast } from '../../utils/common';
import request from '../../utils/request';
import { extractPaymentError } from '../../utils/errors';

export const handleFetchTransaction = async ({
  initial,
  token,
  redirectToSuccessfulPayment,
  setPaymentDetails,
  setPaymentError,
  setTransaction,
  setIsLoading,
  setCanRetry,
}: {
  initial: boolean,
  token: string,
  redirectToSuccessfulPayment: () => void;
  setPaymentDetails: (details: string) => void,
  setPaymentError: (error: boolean) => void,
  setTransaction: (transaction) => void;
  setIsLoading: (isLoading: boolean) => void,
  setCanRetry: (canRetry: boolean) => void; 
}) => {
  initial && setIsLoading(true);

  try {
    const { data } = await request.get('white-label/payments/additional/status', {
      params: {
        token,
      }
    });

    if (isCryptoPaymentReceived(data?.transaction?.status)) {
      return redirectToSuccessfulPayment();
    }

    setPaymentDetails(data?.details);
    setTransaction(data?.transaction);
    setPaymentError(data?.error);
    setCanRetry(data?.canRetry);
    setIsLoading(false);
  } catch (err) {
    const { errorMessage, canRetry } = extractPaymentError(err);
    setPaymentDetails(errorMessage);
    setPaymentError(true);
    setCanRetry(canRetry);
    setIsLoading(false);
  }
};

export const handleRefreshTransaction = async ({
  token,
  coin,
  setRefreshTransactionLoading,
  setPaymentDetails,
  setPaymentError,
  refreshToken,
}: {
  token: string,
  coin: string,
  setRefreshTransactionLoading: (refreshTransactionLoading: boolean) => void;
  setPaymentDetails: (details: string) => void;
  setPaymentError: (error: boolean) => void;
  refreshToken: (newToken: string) => void;
}) => {
  try {
    setRefreshTransactionLoading(true);

    const response = await request.post('white-label/payments/additional/refresh', {
      token,
      coin,
    });

    setRefreshTransactionLoading(false);
    setPaymentDetails(null);
    setPaymentError(false);
    refreshToken(response?.data?.token);
  } catch (err) {
    setRefreshTransactionLoading(false);
    return showToast(err.message, 'error');
  }
};
