import { FormEvent, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { InsetInput, Hint, Group, InsetNativeSelect } from '@heathmont/moon-core-tw';
import request from '../../utils/request';
import { userProfileSchema, getInitialValues } from './schema';
import { showToast } from '../../utils/common';
import { IChangeProfileForm } from './types';
import SelectOptions from '../SelectOptions';
import { countyCodes, formatPhone, omitHelperPhoneFields } from '../../utils/phone';
import { genderOptions } from '../../enums/GenderType';
import EditAccountFormActions from '../EditAccountFormActions';
import { extractErrorFromResponse } from '../../utils/errors';

const ChangeProfileForm: React.FC<IChangeProfileForm> = ({
  currentUser,
  updateCurrentUserProfile,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userProfileSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(currentUser),
    onSubmit: () => {},
  });
  const { values, errors, touched, dirty, setFieldValue, setFieldTouched, resetForm, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    if (touched?.phoneCountryCode || touched?.phoneNumber) {
      setFieldTouched('phone');
    }
  }, [touched?.phoneCountryCode, touched?.phoneNumber]);

  useEffect(() => {
    setFieldValue('phone', formatPhone(values?.phoneCountryCode, values?.phoneNumber));
  }, [values?.phoneCountryCode, values?.phoneNumber]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      await request.update('white-label/user/profile', omitHelperPhoneFields(values));
      updateCurrentUserProfile(values);
      showToast('Your profile has been updated.', 'success');
    } catch (err) {
      showToast(extractErrorFromResponse(err), 'error');
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <form className='w-full flex flex-col gap-2 tablet:gap-4' onSubmit={onSubmit}>
        <div className='w-full flex flex-col'>
          <Group className='rounded-moon-i-md'>
            <Group.FirstInsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              placeholder=' '
              error={!!(errors?.firstName && touched?.firstName)}
              value={values.firstName}
              name='firstName'
              id='firstName'
              onChange={({ target: { value } }) => setFieldValue('firstName', value)}
              onBlur={() => setFieldTouched('firstName')}
            >
              <InsetInput.Label>First name as in your passport</InsetInput.Label>
            </Group.FirstInsetInput>
            <Group.LastInsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              placeholder=' '
              error={!!(errors?.lastName && touched?.lastName)}
              value={values.lastName}
              name='lastName'
              id='lastName'
              onChange={({ target: { value } }) => setFieldValue('lastName', value)}
              onBlur={() => setFieldTouched('lastName')}
            >
              <InsetInput.Label>Last name as in your passport</InsetInput.Label>
            </Group.LastInsetInput>
          </Group>

          {!!(errors?.firstName && touched?.firstName) && (
            <Hint error>{errors?.firstName}</Hint>
          )}
          {!!(errors?.lastName && touched?.lastName) && (
            <Hint error>{errors?.lastName}</Hint>
          )}
        </div>

        <div className='w-full flex flex-col'>
          <InsetNativeSelect
            className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
            error={!!(errors?.gender && touched?.gender)}
            value={values?.gender || ''}
            label={'Gender'}
            name='gender'
            id='gender'
            onChange={({ target: { value } }) => setFieldValue('gender', value)}
            onBlur={() => setFieldTouched('gender')}
          >
            <SelectOptions options={genderOptions} label='gender' />
          </InsetNativeSelect>

          {!!(errors?.gender && touched?.gender) && (
            <Hint error>{errors?.gender}</Hint>
          )}
        </div>

        <div className='w-full flex flex-col'>
          <Group className='rounded-moon-i-md'>
            <Group.FirstInsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              placeholder='Birth date'
              error={!!(errors?.birthday && touched?.birthday)}
              value={values.birthday}
              type='date'
              name='birthday'
              id='birthday'
              onChange={({ target: { value } }) => setFieldValue('birthday', value || '')}
              onBlur={() => setFieldTouched('birthday')}
            >
              <InsetInput.Label>Birth date</InsetInput.Label>
            </Group.FirstInsetInput>
            <Group.LastInsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              placeholder=' '
              disabled
              value={currentUser.email}
              type='email'
              name='email'
              id='email'
              onChange={() => {}}
            >
              <InsetInput.Label>Email</InsetInput.Label>
            </Group.LastInsetInput>
          </Group>
      
          {!!(errors?.birthday && touched?.birthday) && (
            <Hint error>{errors?.birthday}</Hint>
          )}
        </div>

        <div className='w-full flex flex-col'>
          <Group className='rounded-moon-i-md'>
            <Group.FirstInsetSelect
              className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
              error={!!(errors?.phoneCountryCode && touched?.phoneCountryCode) || !!(errors?.phone && touched?.phone)}
              value={values.phoneCountryCode || ''}
              label={'Country'}
              name='phoneCountryCode'
              id='phoneCountryCode'
              onChange={({ target: { value } }) => setFieldValue('phoneCountryCode', value)}
              onBlur={() => setFieldTouched('phoneCountryCode')}
            >
              <SelectOptions options={countyCodes} label='country' />
            </Group.FirstInsetSelect>
            <Group.LastInsetInput
              placeholder=' '
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              error={!!(errors?.phoneNumber && touched?.phoneNumber) || !!(errors?.phone && touched?.phone)}
              value={values.phoneNumber}
              type='number'
              name='phoneNumber'
              id='phoneNumber'
              onChange={({ target: { value } }) => setFieldValue('phoneNumber', value)}
              onBlur={() => setFieldTouched('phoneNumber')}
            >
              <InsetInput.Label>Telephone number</InsetInput.Label>
            </Group.LastInsetInput>
          </Group>
      
          {!!(errors?.phoneCountryCode && touched?.phoneCountryCode) && (
            <Hint error>{errors?.phoneCountryCode}</Hint>
          )}
          {!!(errors?.phoneNumber && touched?.phoneNumber) && (
            <Hint error>{errors?.phoneNumber}</Hint>
          )}
          {!!(touched?.phone && touched?.phone && !errors?.phoneCountryCode && !errors?.phoneNumber && errors?.phone) && (
            <Hint error>{errors?.phone}</Hint>
          )}
        </div>

        <EditAccountFormActions
          isSubmitLoading={isSubmitLoading}
          errors={errors}
          dirty={dirty}
          discardChanges={() => {
            resetForm({ values: getInitialValues(currentUser), isValidating: false });
            validateForm(getInitialValues(currentUser));
          }}
        />
      </form>
    </FormikProvider>
  );
};

export default ChangeProfileForm;
