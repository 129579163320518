import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from './texts';

export const headerBlockCN = mergeClassnames([
  'rounded-[12px]',
  'bg-goku',
  'px-[40px]',
  'py-[16px]',
  'flex',
  'items-start',
  'justify-center',
  'flex-col',
  'relative',
  'w-full',
  'shadow-moon-xl',
  'mb-[10px]',
  'max-tablet:px-[16px]',
  'max-tablet:py-[12px]',
]);

export const headerBlockTitleCN = mergeClassnames([
  'text-moon-24',
  'max-tablet:text-moon-18',
  'text-bulma',
  textEllipsisCN,
]);

export const headerBlockSubTitleCN = mergeClassnames([
  'text-moon-16',
  'max-tablet:text-moon-12',
  'text-trunks',
  textEllipsisCN,
]);
