import { connect } from 'react-redux';
import FlightBookingList from './FlightBookingList.ui';

import {
  setIsArrivalDepartureTimeChangeModalOpen,
  setIsAllFiltersModalOpen,
  setSelectedItineraries,
  setGroupsSuccess,
  setGroupsLoading,
  setSearchParams,
  setGroupsError,
  resetAirlines,
  selectOffer,
  resetFilter,
  setFilters,
  resetData,
  setSortBy,
  setPage,
} from '../../store/flightBookings/actions';
import { ITripReducer } from '../../store/trip/reducer';

const mapStateToProps = ({
  flightBookingsReducer,
  tripReducer,
}: {
  flightBookingsReducer: any;
  tripReducer: ITripReducer;
}) => {
  return {
    isArrivalDepartureTimeChangeModalOpen: flightBookingsReducer.isArrivalDepartureTimeChangeModalOpen,
    isAllFiltersModalOpen: flightBookingsReducer.isAllFiltersModalOpen,
    initialLoading: flightBookingsReducer.initialLoading,
    tripSettings: tripReducer.settings,
    searchParams: flightBookingsReducer.search,
    amaClientRef: flightBookingsReducer.amaClientRef,
    airlines: flightBookingsReducer.airlines,
    filters: flightBookingsReducer.filters,
    loading: flightBookingsReducer.loading,
    groups: flightBookingsReducer.groups,
    offset: flightBookingsReducer.offset,
    prices: flightBookingsReducer.prices,
    sortBy: flightBookingsReducer.sortBy,
    limit: flightBookingsReducer.limit,
    total: flightBookingsReducer.total,
    page: flightBookingsReducer.page,
  };
};

const mapDispatchToProps = {
  setIsArrivalDepartureTimeChangeModalOpen,
  setIsAllFiltersModalOpen,
  setSelectedItineraries,
  setGroupsSuccess,
  setGroupsLoading,
  setSearchParams,
  setGroupsError,
  resetAirlines,
  selectOffer,
  resetFilter,
  setFilters,
  setSortBy,
  resetData,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingList);
