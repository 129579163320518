import Footer from '../../components/Footer';
import PageWrapper from '../../components/PageWrapper';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import useIsMobile from '../../hooks/useIsMobile';
import { IForgotPassword } from './types';

const ForgotPassword: React.FC<IForgotPassword> = ({
  isContactFormOpen,
  setIsContactFormOpen,
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper authPage isMobile={isMobile} page={'forgot-password'}>
      <ForgotPasswordForm />
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default ForgotPassword;
