import { mergeClassnames } from '@heathmont/moon-core-tw';

export const logoWrapperCN = mergeClassnames([
  'relative',
  'flex',
  'justify-between',
  'items-center',
  'w-full',
  'p-10',
  'gap-[10px]',
  'h-[113px]',
  'max-laptop:p-6',
  'max-tablet:max-h-24',
  'max-tablet:justify-end',
  'max-mobile:p-4',
]);

export const logoCN = mergeClassnames([
  'cursor-pointer',
  'w-auto',
  'max-tablet:w-[42px]',
  'max-tablet:absolute',
  'max-tablet:top-2/4',
  'max-tablet:left-2/4',
  'max-tablet:-translate-x-1/2',
  'max-tablet:-translate-y-1/2',
]);
