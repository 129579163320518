import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { IAccountSecurity } from './types';
import Footer from '../../components/Footer';
import { ACCOUNT_TAB } from '../../enums/AccountTabs';
import AccountWrapper from '../../components/AccountWrapper';
import ChangePasswordForm from '../../components/ChangePasswordForm';

const AccountSecurity: React.FC<IAccountSecurity> = ({
  isContactFormOpen,
  currentUser,
  setIsContactFormOpen
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper isMobile={isMobile} page={'security'}>
      <AccountWrapper currentUser={currentUser} currentTab={ACCOUNT_TAB.SECURITY}>
        <ChangePasswordForm />
      </AccountWrapper>

      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default AccountSecurity;
