import { connect } from 'react-redux';
import AccountBookings from './AccountBookings.ui';
import {
  setIsContactFormOpen
} from '../../store/dialogs/actions';

const mapStateToProps = ({
  dialogsReducer,
  authReducer,
}) => {
  return {
    isContactFormOpen: dialogsReducer.isContactFormOpen,
    currentUser: authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  setIsContactFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBookings);
