import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IFlightBookingCryptoPayment } from './types';
import PageWrapper from '../../components/PageWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import {
  handleRefreshTransaction,
  handleFetchTransaction,
} from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { CryptoTransaction } from '../../enums/CryptoTransaction';
import FlightBookingLoader from '../../components/FlightBookingLoader';
import { handleSuccessfulPayment } from '../../utils/payments';
import { useConfirmOfferPriceAndAvailability } from '../../hooks/useConfirmOfferPriceAndAvailability';
import { calculateFlightTotalCost } from '../../hooks/useFlightOffersPriceConfirmation';
import { handleAcceptNewPriceAndContinue } from '../../utils/flights';
import FlightBookingPriceChangedDialog from '../../components/FlightBookingPriceChangedDialog';
import CryptoPaymentCheckout from '../../components/CryptoPaymentCheckout';

const FlightBookingCryptoPayment: React.FC<IFlightBookingCryptoPayment> = ({
  isPriceChangedModalOpen,
  tripPurposeId,
  setIsPriceChangedModalOpen,
  resetToInitial,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [offers, setOffers] = useState([]);
  const [transaction, setTransaction] = useState<CryptoTransaction | null>(null);
  const [paymentDetails, setPaymentDetails] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const [refreshTransactionLoading, setRefreshTransactionLoading] = useState<boolean>(false);
  const [lowCostBookingLoading, setLowCostBookingLoading] = useState<boolean>(false);
  const [lowCostBookingError, setLowCostBookingError] = useState<string | null>(null);
  const { amaClientRef } = useParams();
  const { isOfferAvailable, currentPrice, currentOffersState } = useConfirmOfferPriceAndAvailability({
    tripPurposeId,
    amaClientRef,
    needToCheck: !lowCostBookingLoading && !lowCostBookingError && !paymentError,
    offers,
  });
  const [isAcceptingNewPriceLoading, setIsAcceptingNewPriceLoading] = useState<boolean>(false)

  const redirectToSearchPage = () => {
    navigate('/');
  };

  const redirectToBookingSuccess = () => {
    navigate(`/${FLIGHT_BOOKING_STEP.SUCCESS}/${amaClientRef}`);
  };

  const redirectToChangePaymentMethodPage = () => {
    navigate(`/${FLIGHT_BOOKING_STEP.PAYMENT_CHANGE}/${amaClientRef}`);
  };

  const proceedAfterSuccessfulPayment = ({
    isLowCostBooking,
    transactionKey,
    initial,
    trips,
  }: {
    isLowCostBooking: boolean,
    transactionKey: string,
    initial: boolean,
    trips: any[]
  }) => {
    handleSuccessfulPayment({
      isLowCostBooking,
      transactionKey,
      amaClientRef,
      initial,
      trips,
      setLowCostBookingLoading,
      redirectToBookingSuccess,
      setLowCostBookingError,
    });
  };

  const handleCreateNewTransaction = () => {
    handleRefreshTransaction({
      amaClientRef,
      proceedAfterSuccessfulPayment,
      setRefreshTransactionLoading,
      setPaymentDetails,
      setPaymentError,
      setTransaction,
      setIsLoading,
      setCanRetry,
      setOffers,
    });
  };

  const handleRefetchTransaction = () => {
    handleFetchTransaction({
      amaClientRef,
      initial: false,
      proceedAfterSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setTransaction,
      setIsLoading,
      setCanRetry,
      setOffers,
    });
  };

  useEffect(() => {
    if (location?.state?.resetToInitial) {
      resetToInitial();
    }
  }, [location?.state?.resetToInitial]);

  useEffect(() => {  
    handleFetchTransaction({
      amaClientRef,
      initial: true,
      proceedAfterSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setTransaction,
      setIsLoading,
      setCanRetry,
      setOffers,
    });

    return () => {
      resetToInitial();
    };
  }, []);

  useEffect(() => {
    if (!isOfferAvailable) {
      setLowCostBookingError('The flights are no longer available');
    }
  }, [isOfferAvailable]);

  useEffect(() => {
    if (currentPrice && offers?.length && currentPrice !== calculateFlightTotalCost(offers)) {
      setIsPriceChangedModalOpen(true);
    }
  }, [currentPrice]);

  return (
    <PageWrapper
      isMobile={isMobile}
      page={'flight-booking-crypto-payment'}
    >
      {(lowCostBookingLoading || lowCostBookingError) && (
        <FlightBookingLoader
          bookingError={lowCostBookingError}
          offers={offers}
        />
      )}

      {!lowCostBookingLoading && !lowCostBookingError && (
        <CryptoPaymentCheckout
          needToRefetchTransaction={!paymentError && !lowCostBookingLoading && !lowCostBookingError && !isPriceChangedModalOpen}
          isRefreshLoading={refreshTransactionLoading}
          paymentDetails={paymentDetails}
          paymentError={paymentError}
          transaction={transaction}
          isLoading={isLoading}
          canRetry={canRetry}
          onPaymentMethodChange={redirectToChangePaymentMethodPage}
          setPaymentDetails={setPaymentDetails}
          fetchTransaction={handleRefetchTransaction}
          setPaymentError={setPaymentError}
          setCanRetry={setCanRetry}
          onRefresh={handleCreateNewTransaction}
        />
      )}

      {isPriceChangedModalOpen && (
        <FlightBookingPriceChangedDialog
          isLoading={isAcceptingNewPriceLoading}
          oldPrice={calculateFlightTotalCost(offers)}
          newPrice={currentPrice}
          onCancel={redirectToSearchPage}
          onAgree={() => handleAcceptNewPriceAndContinue({
            amaClientRef,
            offers: currentOffersState,
            setIsLoading: setIsAcceptingNewPriceLoading,
          })}
        />
      )}
    </PageWrapper>
  );
};

export default FlightBookingCryptoPayment;
