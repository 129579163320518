import { useEffect, useState } from 'react';
import { Button, InsetInput, InsetNativeSelect, Hint, Textarea } from '@heathmont/moon-core-tw';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import PageWrapper from '../../components/PageWrapper';
import { handleFetchFlight, handleRequestChanges, getArrivalCity, getDepartureCity, getDepartureDate, getReturnDate } from './utils';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import Accordion from '../../components/Accordion';
import { requestChangesSchema, initialValues } from './schema';
import { fieldWrapperCN, textCN } from './styles';
import { requestChangesOptions } from '../../enums/RequestChangesOption';
import SelectOptions from '../../components/SelectOptions';
import { paymentCheckoutContentCN, paymentCheckoutWrapperCN } from '../../styles/wrappers';

const FlightBookingRequestChanges = () => {
  const navigate = useNavigate();
  const { amaClientRef } = useParams();
  const location = useLocation();
  const { tripId } = queryString.parse(location.search);
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [traveler, setTraveler] = useState(null);
  const [flight, setFlight] = useState(null);
  const [isRequestChangesLoading, setIsRequestChangesLoading] = useState<boolean>(false);
  const [isRequestChangesSuccess, setIsRequestChangesSuccess] = useState<boolean>(false);
  const formik = useFormik({
    validationSchema: requestChangesSchema,
    validateOnBlur: true,
    initialValues,
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, validateForm, setTouched } = formik;  

  useEffect(() => {
    if (amaClientRef && tripId) {
      handleFetchFlight({
        amaClientRef,
        tripId: tripId as string,
        setIsLoading,
        setTraveler,
        setFlight,
        setError,
      });
      validateForm();
    }
  }, [amaClientRef, tripId]);

  useEffect(() => {
    if (traveler?.email) {
      setFieldValue('email', traveler?.email);
      validateForm({ ...values, email: traveler?.email });
    }
  }, [traveler?.email]);

  const handleRedirectToSearch = async () => {
    navigate('/');
  };

  const handleRetry = () => {
    setValues({ ...initialValues, email: traveler?.email });
    validateForm({ ...initialValues, email: traveler?.email });
    setTouched({}, false);
    setIsRequestChangesSuccess(false);
  };

  return (
    <PageWrapper isMobile={isMobile} page={'flight-booking-request-changes'}>
      <div className={paymentCheckoutWrapperCN}>
        <div className={paymentCheckoutContentCN}>
          {!!error && (
            <FlightBookingInfoView
              submitButtonLabel={'Flight search'}
              subTitle={error}
              onClick={handleRedirectToSearch}
            />
          )}

          {isRequestChangesSuccess && (
            <FlightBookingInfoView
              secondaryButtonLabel={'Back'}
              submitButtonLabel={'Flight search'}
              isSuccess
              subTitle={'Travel manager will contact you as soon as possible'}
              title={'Your request has been sent'}
              handleSecondaryButtonClick={handleRetry}
              onClick={handleRedirectToSearch}
            />
          )}

          {!error && !isRequestChangesSuccess && (
            <>
              <FlightBookingSummary
                departureDate={getDepartureDate(flight)}
                destination={getArrivalCity(flight)}
                returnDate={getReturnDate(flight)}
                isLoading={isLoading}
                origin={getDepartureCity(flight)}
              />

              <Accordion
                openByDefault={!isLoading}
                disabled={isLoading}
                title={(
                  <>
                    {isLoading ? (
                      <Skeleton style={{ height: '100%', width: '150px' }} inline />
                    ): (
                      'Request form'
                    )}
                  </>
                )}
                key={`${isLoading}`}
              >
                <FormikProvider value={formik}>
                  <div className='flex flex-col gap-[20px]'>
                    <div className='w-full'>
                      <p className={textCN}>Dear {traveler?.fullName ? traveler?.fullName : 'traveler'},</p>
                      <p className={textCN}>Please fill the form, if you would like to cancel trip or request changes <span className='font-medium'>[{flight?.reference}]</span>.</p>
                    </div>

                    <div className='w-full flex flex-col gap-[16px]'>
                      <div className={fieldWrapperCN}>
                        <InsetInput
                          placeholder=' '
                          className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                          error={!!(errors?.email && touched?.email)}
                          name='email' 
                          type='email'
                          value={values.email}
                          id='email'
                          onChange={({ target: { value } }) => setFieldValue('email', value)}
                          onBlur={() => setFieldTouched('email')}
                        >
                          <InsetInput.Label>Email</InsetInput.Label>
                        </InsetInput>

                        {!!(errors?.email && touched?.email) && (
                          <Hint error>{errors?.email}</Hint>
                        )}
                      </div>

                      <div className={fieldWrapperCN}>
                        <InsetNativeSelect
                          className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
                          error={!!(errors?.reason && touched?.reason)}
                          value={values?.reason || ''}
                          label={'Reason'}
                          onChange={({ target: { value } }) => setFieldValue('reason', value)}
                          onBlur={() => setFieldTouched('reason')}
                        >
                          <SelectOptions options={requestChangesOptions} label='reason' />
                        </InsetNativeSelect>
     
                        {!!(errors?.reason && touched?.reason) && (
                          <Hint error>{errors?.reason}</Hint>
                        )}
                      </div>

                      <div className={fieldWrapperCN}>
                        <Textarea
                          placeholder='Please, describe your request or reason'
                          className="rounded-moon-i-md [&_textarea]:rounded-moon-i-md resize-y"
                          error={!!(errors?.description && touched?.description)}
                          value={values.description}
                          rows={3}
                          onChange={({ target: { value } }) => setFieldValue('description', value)}
                          onBlur={() => setFieldTouched('description')}
                        />

                        {!!(errors?.description && touched?.description) && (
                          <Hint error>{errors?.description}</Hint>
                        )}
                      </div>
                    </div>

                    <Button
                      className='text-moon-20'
                      animation={isRequestChangesLoading ? 'progress' : undefined}
                      fullWidth
                      disabled={isRequestChangesLoading || !isEmpty(errors)}
                      size='xl'
                      onClick={() => handleRequestChanges({
                        amaClientRef,
                        description: values.description,
                        reason: values.reason,
                        tripId: tripId as string,
                        email: values.email,
                        setIsRequestChangesLoading,
                        setIsRequestChangesSuccess,
                      })}
                    >
                      Send
                    </Button>
                  </div>
                </FormikProvider>
              </Accordion>
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default FlightBookingRequestChanges;
