import moment from 'moment';
import Yup from '../../utils/validation';
import { GENDER } from '../../enums/GenderType';

const VALIDATIONS = {
  PASSWORD: {
    MAX: 50,
    MIN: 8,
  },
  REFERRAL_CODE: {
    MAX: 255,
    MIN: 2,
  },
  NAME: {
    MAX: 20,
    MIN: 2,
  },
  PASSENGER_AGE: {
    MAX: 100,
    MIN: 18,
  },
};

const validateMessages = {
  firstName: {
    validName: 'First name can contain only Latin characters and hyphens',
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `First name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  lastName: {
    validName: 'Last name can contain only Latin characters and hyphens',
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `Last name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  gender: {
    required: 'Gender cannot be empty',
  },
  email: {
    disallowedSymbol: 'Email cannot contain "+"',
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  birthday: {
    required: 'Birth date cannot be empty',
    max: `Age cannot be more then ${VALIDATIONS.PASSENGER_AGE.MAX} years old`,
    min: `Age cannot be less then ${VALIDATIONS.PASSENGER_AGE.MIN} years old`,
  },
  phoneCountryCode: {
    required: 'Country cannot be empty',
  },
  phoneNumber: {
    required: 'Telephone number cannot be empty',
  },
  referralCode: {
    required: 'Referral code cannot be empty',
    min: `Referral code cannot be less than ${VALIDATIONS.REFERRAL_CODE.MIN} characters`,
    max: `Referral code cannot be longer than ${VALIDATIONS.REFERRAL_CODE.MAX} characters`,
  },
  password: {
    required: 'Password cannot be empty',
    min: `Password cannot be less than ${VALIDATIONS.PASSWORD.MIN} characters`,
    max: `Password cannot be longer than ${VALIDATIONS.PASSWORD.MAX} characters`,
    upperCase: 'Password must contain an uppercase English character: A-Z',
    lowerCase: 'Password must contain an lowercase English character: a-z',
    digit: 'Password must contain a digit: 0-9',
    specialSymbol: 'Password must contain a special character: !#$%&()*+-?~@=',
  },
  confirmPassword: {
    required: 'Confirm password cannot be empty',
    match: 'Passwords do not match',
  },
};

export const userSchema = Yup
  .object()
  .shape({
    firstName: Yup
      .string()
      .required(validateMessages.firstName.required)
      .min(VALIDATIONS.NAME.MIN, validateMessages.firstName.min)
      .max(VALIDATIONS.NAME.MAX, validateMessages.firstName.max)
      .withoutSpaces()
      .validName(validateMessages.firstName.validName)
      .isInLatinCharacters(),
    lastName: Yup
      .string()
      .required(validateMessages.lastName.required)
      .min(VALIDATIONS.NAME.MIN, validateMessages.lastName.min)
      .max(VALIDATIONS.NAME.MAX, validateMessages.lastName.max)
      .withoutSpaces()
      .validName(validateMessages.lastName.validName)
      .isInLatinCharacters(),
    gender: Yup
      .string()
      .required(validateMessages.gender.required),
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
      .required(validateMessages.email.required),
    birthday: Yup
      .date()
      .required(validateMessages.birthday.required)
      .test('max-age', validateMessages.birthday.max, (value) => (
        moment().diff(moment(value), 'years') <= VALIDATIONS.PASSENGER_AGE.MAX
      ))
      .test('min-age', validateMessages.birthday.min, (value) => (
        moment().diff(moment(value), 'years') >= VALIDATIONS.PASSENGER_AGE.MIN
      ))
      .nullable(),
    phoneCountryCode: Yup
      .string()
      .required(validateMessages.phoneCountryCode.required),
    phoneNumber: Yup
      .number()
      .required(validateMessages.phoneNumber.required),
    phone: Yup
      .string()
      .isValidPhoneNumber(),
    referralCode: Yup
      .string()
      .required(validateMessages.referralCode.required)
      .min(VALIDATIONS.REFERRAL_CODE.MIN, validateMessages.referralCode.min)
      .max(VALIDATIONS.REFERRAL_CODE.MAX, validateMessages.referralCode.max)
      .withoutSpaces(),
    password: Yup
      .string()
      .required(validateMessages.password.required)
      .min(VALIDATIONS.PASSWORD.MIN, validateMessages.password.min)
      .max(VALIDATIONS.PASSWORD.MAX, validateMessages.password.max)
      .includeUpperCase(validateMessages.password.upperCase)
      .includeLowerCase(validateMessages.password.lowerCase)
      .includeDigit(validateMessages.password.digit)
      .includeSpecialSymbol(validateMessages.password.specialSymbol)
      .withoutSpaces(),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password')], validateMessages.confirmPassword.match)
      .required(validateMessages.confirmPassword.required),
  });

export const getInitialValues = ({
  referralCode,
  firstName,
  lastName,
  email,
}: {
  referralCode?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}): {
  phoneCountryCode?: string;
  confirmPassword: string;
  referralCode: string;
  phoneNumber?: string;
  firstName: string;
  birthday?: string;
  lastName: string;
  password: string;
  gender?: GENDER;
  email: string;
  phone: string;
} => {
  return {
    phoneCountryCode: undefined,
    confirmPassword: '',
    referralCode: referralCode ? referralCode : '',
    phoneNumber: undefined,
    firstName: firstName ? firstName : '',
    birthday: undefined,
    password: '',
    lastName: lastName ? lastName : '',
    gender: undefined,
    email: email ? email : '',
    phone: '',
  };
};