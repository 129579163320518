import { mergeClassnames } from '@heathmont/moon-core-tw';

export const stepperWrapperMobileCN = mergeClassnames([
  'hidden',
  'w-full',
  'justify-between',
  'items-center',
  'px-[16px]',
  'py-[6px]',
  'rounded-[12px]',
  'bg-goku',
  'text-moon-16',
  'font-normal',
  'mb-[12px]',
  'max-tablet:flex',
]);

export const stepperProgressMobileCN = mergeClassnames([
  'bg-piccolo',
  'text-goku',
  'px-[6px]',
  'py-[2px]',
  'text-center',
  'rounded-full',
]);

export const stepperWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'items-center',
  'gap-[10px]',
  'relative',
  'rounded-[12px]',
  'bg-goku',
  'pl-[16px]',
  'pr-[16px]',
  'py-[7px]',
  'overflow-hidden',
  'mb-[24px]',
  'max-tablet:hidden',
]);

export const activeProgressLineCN = (isPending: boolean, paymentRequired: boolean) => mergeClassnames([
  'h-[2px]',
  'bg-piccolo',
  'absolute',
  'bottom-[0]',
  'z-10',
  paymentRequired ? 'w-[calc(100%/4-2px)]' : 'w-[calc(100%/3-2px)]' ,
  isPending && 'bg-beerus',
]);

export const stepWrapperCN = ({
  isLoading,
  completed,
  progress,
  pending,
}: {
  isLoading: boolean;
  completed: boolean;
  progress: boolean;
  pending: boolean;
}) => mergeClassnames([
  'flex',
  'items-center',
  'justify-center',
  'flex-[1]',
  'font-normal',
  isLoading && 'pointer-events-none',
  completed && 'cursor-pointer text-trunks hover:text-bulma',
  progress && 'cursor-default text-bulma',
  pending && 'cursor-default text-trunks',
]);
