import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { IAccountDocument } from './types';
import Footer from '../../components/Footer';
import { ACCOUNT_TAB } from '../../enums/AccountTabs';
import AccountWrapper from '../../components/AccountWrapper';
import ChangeDocumentForm from '../../components/ChangeDocumentForm';

const AccountDocument: React.FC<IAccountDocument> = ({
  isContactFormOpen,
  currentUser,
  setIsContactFormOpen
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper isMobile={isMobile} page={'document'}>
      <AccountWrapper currentUser={currentUser} currentTab={ACCOUNT_TAB.DOCUMENT}>
        <ChangeDocumentForm />
      </AccountWrapper>

      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default AccountDocument;
