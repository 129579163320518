import { connect } from 'react-redux';
import FlightBookingOverview from './FlightBookingOverview.ui';
import {
  setIsTermsAndConditionsOpen,
  setIsMoreFareRulesModalOpen,
} from '../../store/dialogs/actions';
import { IDialogsReducer } from '../../store/dialogs/reducer';
import { ITripReducer } from '../../store/trip/reducer';

const mapStateToProps = ({
  flightBookingsReducer,
  dialogsReducer,
  tripReducer,
}: {
  flightBookingsReducer: any;
  dialogsReducer: IDialogsReducer;
  tripReducer: ITripReducer;
}) => {
  return {
    isMoreFareRulesModalOpen: dialogsReducer.isMoreFareRulesModalOpen,
    isTermsAndConditionsOpen: dialogsReducer.isTermsAndConditionsOpen,
    fareRulesOfferId: dialogsReducer.fareRulesOfferId,
    selectedOffers: flightBookingsReducer.selectedOffers,
    tripSettings: tripReducer.settings,
    amaClientRef: flightBookingsReducer.amaClientRef,
    travelers: flightBookingsReducer.passengers,
  };
};

const mapDispatchToProps = {
  setIsMoreFareRulesModalOpen,
  setIsTermsAndConditionsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingOverview);
