import { IFlightBookingOfferTravelerInfo } from './types';
import { detailsTitleCN, detailsValueCN, detailsItemCN } from './styles';
import { CABIN_LABEL } from '../../enums/CabinTypes';
import { getBaggageDescription, isBaggageIncluded } from '../../utils/flight-offer';

const FlightBookingOfferTravelerInfo: React.FC<IFlightBookingOfferTravelerInfo> = ({
  additionalServices,
  fareDetails, 
  traveler,
  index,
}) => {
  return (
    <>
      <div className={detailsItemCN({ start: true })}>
        <div className={detailsTitleCN}>
          Passenger {index + 1}
        </div>
        <div className={detailsValueCN} key={traveler.id}>
          {`${traveler.firstName} ${traveler.lastName}`}
        </div>
      </div>
      <div className={detailsItemCN({ center: true })}>
        <div className={detailsTitleCN}>
          Class
        </div>
        <div className={detailsValueCN}>
          {CABIN_LABEL[fareDetails.cabin]}
        </div>
      </div>
    
      <div className={detailsItemCN({ end: true })}>
        <div className={detailsTitleCN}>
          Baggage
        </div>

        {(isBaggageIncluded(fareDetails?.includedCheckedBags) || !additionalServices?.length) && (
          <div className={detailsValueCN}>
            {getBaggageDescription({
              detailed: false,
              bags: fareDetails?.includedCheckedBags,
            })}
          </div>
        )}

        {additionalServices?.length > 0 && additionalServices.map((extraBaggage, index) => (
          <div className={detailsValueCN} key={index}>
            {getBaggageDescription({
              detailed: false,
              label: 'extra',
              bags: extraBaggage,
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export default FlightBookingOfferTravelerInfo;
