import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IFlightBookingPayment } from './types';
import PageWrapper from '../../components/PageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import { updatePayment } from './utils';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import PaymentFormWrapper from '../../components/PaymentFormWrapper';
import { handleRedirectToCheckoutPage } from '../../utils/payments';
import { handleFetchBookingDetails } from '../FlightBookingConfirmation/utils';
import { showToast } from '../../utils/common';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import { initPayment } from '../../store/flightBookings/utils';
import { getDepartureCity, getArrivalCity, getDepartureDate, getReturnDate } from '../../utils/offers';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { paymentCheckoutContentCN, paymentCheckoutWrapperCN } from '../../styles/wrappers';
import { AssociatedRecord } from '../../enums/AssociatedRecord';
import { TripPurpose } from '../../enums/TripPurpose';
import { isBookingHasLowCostOffers } from '../../utils/flights';
import { extractErrorFromResponse } from '../../utils/errors';
import { isPaymentTypeSupportLowCostBookings } from '../FlightBookingPayment/utils';

const FlightBookingPaymentChange: React.FC<IFlightBookingPayment> = ({
  currentUser,
  payment,
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  setPaymentPayerType,
}) => {
  const navigate = useNavigate();
  const { amaClientRef } = useParams();
  const isMobile = useIsMobile();
  const [booking, setBooking] = useState<{
    associatedRecords: AssociatedRecord[];
    tripPurpose: TripPurpose;
    travelers: Passenger[];
    payment: Payment,
    offers: any[];
    price: number;
  }>({
    associatedRecords: [],
    tripPurpose: null,
    travelers: [],
    payment: null,
    offers: [],
    price: 0,  
  });
  const [isBookingLoading, setIsBookingLoading] = useState<boolean>(true);
  const [bookingError, setBookingError] = useState<boolean>(false);
  const [isAttachingLoading, setIsAttachingLoading] = useState<boolean>(false);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState<boolean>(false);
  const [isPaymentFormLoading, setIsPaymentFormLoading] = useState<boolean>(false);
  const isLowCostBooking = useMemo(() => isBookingHasLowCostOffers(booking?.offers), [booking?.offers]);
  const [isCryptoUnavailable, setIsCryptoUnavailable] = useState<boolean>(false);

  const isPaymentAvailable = useMemo(() => {
    return booking.tripPurpose?.paymentTypes.includes(payment.paymentType);
  }, [booking.tripPurpose?.paymentTypes, payment.paymentType]);

  const isOnlyOnePaymentAvailable = useMemo(() => {
    return booking.tripPurpose?.paymentTypes?.length === 1
      && booking.tripPurpose.paymentTypes.includes(payment.paymentType);
  }, [booking.tripPurpose?.paymentTypes, payment.paymentType]);

  const handleRedirect = () => {
    handleRedirectToCheckoutPage({
      paymentRequired: true,
      amaClientRef,
      paymentType: payment.paymentType,
      navigate,
    });
  };  

  const handleRedirectToSearch = () => {
    navigate('/');
  };  

  const handleAttachNewPayment = async () => {
    try {
      setIsAttachingLoading(true);
      await updatePayment({ amaClientRef, payment });
      setIsAttachingLoading(false);
      handleRedirect();
    } catch (err) {
      showToast(extractErrorFromResponse(err), 'error');
      setIsAttachingLoading(false);
    }
  };

  useEffect(() => {
    if (amaClientRef) {
      handleFetchBookingDetails({
        amaClientRef,
        setIsBookingLoading,
        handleError: () => setBookingError(true),
        setBooking,
      });
    }
  }, [amaClientRef]);

  useEffect(() => {
    if (booking?.payment) {
      setPaymentPayerDetails(initPayment({
        currentUser,
        payment: booking?.payment,
      }));
    }
  }, [booking?.payment, currentUser]);

  return (
    <PageWrapper isMobile={isMobile} page={'flight-booking-payment-change'}>
      <div className={paymentCheckoutWrapperCN}>
        <div className={paymentCheckoutContentCN}>
          {bookingError ? (
            <FlightBookingInfoView
              submitButtonLabel={'Flight search'}
              subTitle={'The booking for payment was not found'}
              title={'The booking was not found'}
              onClick={handleRedirectToSearch}
            />
          ) : (
            <>
              <FlightBookingSummary
                departureDate={getDepartureDate(booking?.offers)}
                destination={getArrivalCity(booking?.offers)}
                returnDate={getReturnDate(booking?.offers)}
                isLoading={isBookingLoading}
                origin={getDepartureCity(booking?.offers)}
              />
              <PaymentFormWrapper
                availablePaymentTypes={booking.tripPurpose?.paymentTypes || []}
                isCryptoUnavailable={isCryptoUnavailable}
                isLowCostBooking={isLowCostBooking}
                currentUser={currentUser}
                passengers={booking?.travelers}
                isLoading={isBookingLoading || isAttachingLoading}
                payment={payment}
                handleSelectPaymentType={handleSelectPaymentType}
                handleSelectPaymentCoin={handleSelectPaymentCoin}
                setIsPaymentFormLoading={setIsPaymentFormLoading}
                setIsCryptoUnavailable={setIsCryptoUnavailable}
                setPaymentPayerDetails={setPaymentPayerDetails}
                setIsPaymentFormValid={setIsPaymentFormValid}
                setPaymentPayerType={setPaymentPayerType}
              />
              <FlightBookingBackAndNext
                submitLabel='Confirm'
                withSearch={false}
                isLoading={isBookingLoading || isAttachingLoading || isPaymentFormLoading}
                disabled={(
                  !isPaymentFormValid
                  || !isPaymentAvailable
                  || (isLowCostBooking && !isPaymentTypeSupportLowCostBookings(payment?.paymentType) && !isOnlyOnePaymentAvailable)
                  || (payment?.paymentType === PAYMENT_TYPE.CRYPTO && isCryptoUnavailable)
                )}
                price={booking.price} 
                step={FLIGHT_BOOKING_STEP.SUCCESS}
                proceedContinue={handleAttachNewPayment}
              />
            </>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default FlightBookingPaymentChange;
