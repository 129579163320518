import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFlightBookingSearch } from './types';
import PageWrapper from '../../components/PageWrapper';
import Footer from '../../components/Footer';
import FlightBookingSearchForm from '../../components/FlightBookingSearchForm';
import FlightBookingHelloText from '../../components/FlightBookingHelloText';
import { DestinationOption } from '../../enums/Destination';
import { handleFetchOptions } from '../FlightBookingList/utils';
import useIsMobile from '../../hooks/useIsMobile';
import { formatValueToSearchParams } from './utils';
import TripPurposeSelectionDialog from '../../components/TripPurposeSelectionDialog';
import { TripSettings } from '../../enums/TripSettings';

const FlightBookingSearch: React.FC<IFlightBookingSearch> = ({
  isTripPurposeSelectionOpen,
  isContactFormOpen,
  searchParams,
  currentUser,
  setIsTripPurposeSelectionOpen,
  setIsContactFormOpen,
  setSearchParams,
  setTripSettings,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [origin, setOrigin] = useState<object | null>(null);
  const [originOptions, setOriginOptions] = useState<DestinationOption[]>([]);
  const [destination, setDestination] = useState<object | null>(null);
  const [destinationOptions, setDestinationOptions] = useState<DestinationOption[]>([]);
  const [searchValues, setSearchValues] = useState(null);

  useEffect(() => {
    if (searchParams.origin) {
      handleFetchOptions(searchParams.origin as string, setOrigin, setOriginOptions);
    }
  }, [searchParams.origin]);

  useEffect(() => {
    if (searchParams.destination) {
      handleFetchOptions(searchParams.destination as string, setDestination, setDestinationOptions);
    }
  }, [searchParams.destination]);

  const handleSearchWithPurpose = (tripSettings: TripSettings) => {
    const params = formatValueToSearchParams(searchValues);
    setSearchParams(params, tripSettings, {
      shouldResetAirlines: true,
      shouldResetFilters: true,
      shouldResetSortBy: true,
    });
    setTripSettings(tripSettings);
    navigate(`/flight-booking-list`);
    setIsTripPurposeSelectionOpen(false);
    setSearchValues(null);
  };

  const handleOpenTripPurposeSelectionDialog = (values) => {
    setSearchValues(values);
    setIsTripPurposeSelectionOpen(true);
  };

  return (
    <PageWrapper isMobile={isMobile} page={'flight-booking-search'}>
      <FlightBookingHelloText />
      <FlightBookingSearchForm
        withPopularDestinations
        destinationOptions={destinationOptions}
        originOptions={originOptions}
        searchParams={{ ...searchParams, destination, origin }}
        disabled={false}
        onSubmit={handleOpenTripPurposeSelectionDialog}
      />
      
      {
        isTripPurposeSelectionOpen && (
          <TripPurposeSelectionDialog
            currentUser={currentUser}
            onSubmit={handleSearchWithPurpose}
            onClose={() => setIsTripPurposeSelectionOpen(false)}
          />
        )
      }
      
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default FlightBookingSearch;
