import moment from 'moment';
import { convertTimeToFlightLocalTime, getFlightDuration } from '../../utils/common';
import { getCityByIataCode, getCountryByIataCode } from '../../utils/flight-offer';
import { FlightOfferStop } from '../../enums/FlightOfferStop';

export const getNoteAboutArrivalNextDay = (date) => {
  return `Arrives on ${moment.utc(date).format('dddd')}, ${moment.utc(date).format('DD MMMM YYYY')}`
};

export const getNoteAboutStop = (iataCode, duration) => {
  const city = getCityByIataCode(iataCode);
  const country = getCountryByIataCode(iataCode);

  return `${city || iataCode}${country ? ` (${country})` : ''}, ${duration}`
};

export const getFlightDurationText = (departure: FlightOfferStop, arrival: FlightOfferStop) => {
  const departureAt = departure.at;
  const departureCode = departure.iataCode;
  const arrivalAt = arrival.at;
  const arrivalCode = arrival.iataCode;
  const startDate = convertTimeToFlightLocalTime(departureAt, departureCode);
  const endDate = convertTimeToFlightLocalTime(arrivalAt, arrivalCode);
  return getFlightDuration(endDate, startDate);
};

export const formatFlightDate = (departure: FlightOfferStop, arrival: FlightOfferStop) => {
  if (!departure || !arrival) {
    return null;
  }

  const departureAt = departure.at;
  const arrivalAt = arrival.at;
  const departureTime = moment.utc(departureAt).format('HH:mm');
  const departureDate = moment.utc(departureAt).format('MMM DD');
  const arrivalTime = moment.utc(arrivalAt).format('HH:mm');
  const arrivalDate = moment.utc(arrivalAt).format('MMM DD');
  const arrivalFullDate = departureDate === arrivalDate
    ? arrivalTime
    : `${arrivalTime} (${arrivalDate})`;
  const arrivalDay = moment.utc(arrivalAt).format('YYYY-MM-DD')
  const departureDay = moment.utc(departureAt).format('YYYY-MM-DD')
  const departureArrivalDifference = moment(arrivalDay).diff(moment(departureDay), 'days');

  return {
    departureArrivalDifference,
    arrivalAtFull: arrivalFullDate,
    departureAt: departureTime,
    arrivalAt: arrivalTime,
  };
};
