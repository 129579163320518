import { useNavigate } from 'react-router-dom';
import { pageContentCN, pageContentWrapperCN } from '../../styles/wrappers';
import { IAccountWrapper } from './types';
import { headerBlockCN, headerBlockSubTitleCN, headerBlockTitleCN } from '../../styles/blocks';
import { contentCN, linkCN } from './styles';
import Tabs from '../../components/Tabs';
import { ACCOUNT_TAB, accountTabsOptions } from '../../enums/AccountTabs';
import { USER_STATUS } from '../../enums/UserStatus';
import { GenericAlarm, GenericUser, SecurityPassword, TravelAirplane, TravelPassport } from '@heathmont/moon-icons-tw';
import { useMemo } from 'react';
import { mergeClassnames } from '@heathmont/moon-core-tw';

const icons = {
  [ACCOUNT_TAB.PROFILE]: <GenericUser className="text-moon-24" />,
  [ACCOUNT_TAB.DOCUMENT]: <TravelPassport className="text-moon-24" />,
  [ACCOUNT_TAB.SECURITY]: <SecurityPassword className="text-moon-24" />,
  [ACCOUNT_TAB.BOOKINGS]: <TravelAirplane className="text-moon-24" />,
};

const AccountWrapper: React.FC<IAccountWrapper> = ({
  currentUser,
  currentTab,
  children,
}) => {
  const navigate = useNavigate();

  const tabs = useMemo(() => {
    return accountTabsOptions.map(accountTabsOption => ({
      ...accountTabsOption,
      icon: icons[accountTabsOption.value],
    }));
  }, []);

  return (
    <div className={mergeClassnames(pageContentWrapperCN, 'mb-0')}>
      <div className={mergeClassnames(pageContentCN, 'mb-0')}>
        <div className={headerBlockCN}>
          <div className={headerBlockTitleCN}>
            {currentUser.firstName} {currentUser.lastName}
          </div>
          <div className={headerBlockSubTitleCN}>
            {currentUser.email}
          </div>
        </div>
        <div className={contentCN}>
          {
            currentUser.status === USER_STATUS.INTERNAL && (
              <div className='flex items-center gap-2 text-trunks mb-4'>
                <GenericAlarm className='w-5 h-5 flex-shrink-0' />
                <p className='text-moon-14 font-normal max-mobile:text-moon-12'>
                  You can also follow the <a target="_blank" href={`${process.env.REACT_APP_BACK_OFFICE_URL}/edit-profile/details`} className={linkCN}>link</a> to the Back office to have more control over your profile and trips. The changes will affect the data in the Back office and vice versa.
                </p>
              </div>
            )
          }
          <Tabs
            value={currentTab}
            options={tabs}
            onChange={(value) => navigate(value)}
          />
          <div className='mt-4'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountWrapper;
