import { saveAs } from 'file-saver';
import uniq from 'lodash/uniq';
import { getErrorStatusMessage } from '../../utils/common';
import request from '../../utils/request';
import { PAYMENT_STATUS } from '../../enums/PaymentStatus';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { Passenger } from '../../enums/Passenger';
import { Trip } from '../../enums/Trip';
import { FeedbackModalState, getFeedbackModalState } from '../../utils/feedback-modal';
import { AssociatedRecord } from '../../enums/AssociatedRecord';
import { TripPurpose } from '../../enums/TripPurpose';

export const handleDownloadInvoice = async ({
  amaClientRef,
  paymentId,
  setIsInvoiceDownloading,
}: {
  amaClientRef: string;
  paymentId: string,
  setIsInvoiceDownloading: (isLoading: boolean) => void,
}) => {
  try {
    setIsInvoiceDownloading(true);

    const response = await request.post('white-label/payments/invoice', {
      amaClientRef,
      paymentId,
    });

    if (response?.data?.invoice) {
      const ticketPdf = await fetch(response?.data?.invoice?.url);
      const blob = await ticketPdf.blob();
      saveAs(blob, `${response?.data?.invoice?.name}.pdf`)
    }

    setIsInvoiceDownloading(false);
  } catch (e) {
    setIsInvoiceDownloading(false);
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later',
    });
  }
};

export const handleDownloadTicket = async ({
  amaClientRef,
  trips,
  setIsTicketsDownloading,
}: {
  amaClientRef: string,
  trips: any[],
  setIsTicketsDownloading: (isLoading: boolean) => void,
}) => {
  try {
    setIsTicketsDownloading(true);

    const response = await request.post('white-label/flights/ticket', {
      amaClientRef,
      ids: trips.map(trip => trip.id),
    });

    if (response?.data?.tickets?.length) {
      for (const ticket of response?.data?.tickets) {
        try {
          const ticketPdf = await fetch(ticket.url);
          const blob = await ticketPdf.blob();
          saveAs(blob, `${ticket.name}.pdf`)
        } catch (err) {
          getErrorStatusMessage({
            status: err?.response?.status,
            message: err?.response?.data?.error || 'Something went wrong. Try again later',
          });
        }
      }
    }

    setIsTicketsDownloading(false);
  } catch (e) {
    setIsTicketsDownloading(false);
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later',
    });
  }
};

const getBookingTotalCost = (offers) => {
  const total = offers.reduce((prev, offer) => prev + +(offer?.price?.price), 0);
  return Math.round(total * 100) / 100;
};


export const isFeedbackModalWasClosed = (amaClientRef: string) => {
  const state = getFeedbackModalState(amaClientRef);
  return state === FeedbackModalState.CLOSED;
};

export const handleFetchBookingDetails = async ({
  amaClientRef,
  setPrimaryContactUserId,
  setPrimaryContactTripId,
  setIsFeedbackFormOpen,
  setIsBookingLoading,
  handleError,
  setBooking,
}: {
  amaClientRef: string;
  setPrimaryContactUserId?: (userId: number) => void,
  setPrimaryContactTripId?: (tripId: number) => void,
  setIsFeedbackFormOpen?: (isOpen: boolean, amaClientRef: string) => void;
  setIsBookingLoading: (isLoading: boolean) => void;
  handleError: () => void;
  setBooking: (booking: any) => void;
}) => {
  setIsBookingLoading(true);
  try {
    const { data } = await request.get(`white-label/flights/details/${amaClientRef}`);
    setBooking({
      ...data,
      price: getBookingTotalCost(data?.offers),
    });
    setIsBookingLoading(false);

    if (!isFeedbackModalWasClosed(amaClientRef)) {
      setIsFeedbackFormOpen && setIsFeedbackFormOpen(true, amaClientRef);
    }
  } catch (err) {
    setIsBookingLoading(false);
    handleError();
  }
};

export const isInvoicePayment = (paymentType: PAYMENT_TYPE) => {
  return paymentType === PAYMENT_TYPE.INVOICE;
};

export const isSuccessPayment = (status: PAYMENT_STATUS) => {
  return status === PAYMENT_STATUS.SUCCESS;
};

export const defineTitleAndSubTitle = ({
  paymentRequired,
  paymentType,
  status,
  price,
}: {
  paymentRequired: boolean,
  paymentType: PAYMENT_TYPE,
  status: PAYMENT_STATUS,
  price: number,
}): {
  subTitle: string;
  title: string;
} => {
  if (!paymentRequired || isSuccessPayment(status)) {
    return {
      title: `Successful booking${price ? ` — €${price}` : ''}`,
      subTitle: 'We\'ll send you the ticket copy on your email',
    };
  }

  if (isInvoicePayment(paymentType)) {
    return {
      title: 'Booking has already been made',
      subTitle: 'Please pay the invoice during 24 hours',
    };
  }

  return {
    title: 'Booking has already been made',
    subTitle: 'Payment is still processing. We will notify you when payment is successful',
  };
};

export const getFlightReference = (associatedRecords: AssociatedRecord[]): string => {
  const flightReferences = [];

  associatedRecords.forEach((associatedRecord) => {
    if (associatedRecord.reference !== 'PENDING') {
      flightReferences.push(associatedRecord.reference);
    }
  });

  return uniq(flightReferences).join('/');
};
