import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { setNestedObjectValues, useFormik } from 'formik';
import { InsetInput, Checkbox, Hint } from '@heathmont/moon-core-tw';
import isEmpty from 'lodash/isEmpty';
import AuthForm from '../AuthForm';
import InsetPassword from '../InsetPassword';
import { routerLink } from '../../styles/buttons';
import request from '../../utils/request';
import { getInitialValues, authenticationSchema } from './schema';
import { ISignInForm } from './types';
import GoogleAuthButton from '../GoogleAuthButton';
import LineBreakWithText from '../LineBreakWithText';
import { useQuery } from '../../hooks/useQuery';
import { STATUS_CODE } from '../../constants';
import { extractErrorFromResponse } from '../../utils/errors';

const SignInForm: React.FC<ISignInForm> = ({
  setAccessToken,
}) => {
  const { getQuery } = useQuery();
  const { authCode, token } = useMemo(() => getQuery(), []) as { authCode: string, token: string };
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: authenticationSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setTouched, setFieldTouched, validateForm } = formik;

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      const response = await request.post('white-label/auth/sign-in', values);
      if (response?.data?.token) {
        setAccessToken(response?.data?.token);
      } else {
        setErrorMessage('Something went wrong');
      }
    } catch (err) {
      setErrorMessage(extractErrorFromResponse(err));
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleLoginViaGoogle = async () => {
    try {
      const response = await request.get('white-label/user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.user) {
        setAccessToken(token);
      } else {
        setErrorMessage('Something went wrong');
      }
    } catch (err) {
      setErrorMessage(extractErrorFromResponse(err));
    }
  };

  const handleTryAgain = () => {
    setErrorMessage(null);
  };

  const validateOnInit = async () => {
    const validationErrors = await validateForm();

    if (isEmpty(validationErrors)) {
      setTouched(setNestedObjectValues(values, true));
    }
  };

  useEffect(() => {
    validateOnInit();
  }, []);

  useEffect(() => {
    if (token) {
      handleLoginViaGoogle();
    }
  }, [token]);

  useEffect(() => {
    if (authCode && +authCode === STATUS_CODE.USER_UNAUTHORIZED) {
      const error = 'User with this Google account is not registered. Please try to log in using another Google account';
      setErrorMessage(error);
    }
  }, [authCode]);

  return (
    <AuthForm
      submitButtonTitle='Sign in'
      isLoading={isSubmitLoading}
      error={errorMessage}
      title='Sign in to you account'
      value={formik}
      handleTryAgain={handleTryAgain}
      renderAdditionalText={() => (
        <Link className={routerLink} to='/forgot-password'>Forgot your password?</Link>
      )}
      renderSubTitle={() => (
        <>
          If you do not have an account <Link className={routerLink} to='/sign-up'>Create account</Link>
        </>
      )}
      renderFields={() => (
        <>
          <div className='w-full flex flex-col'>
            <GoogleAuthButton />
          </div>

          <LineBreakWithText text='or' />

          <div className='w-full flex flex-col'>
            <InsetInput
              placeholder=' '
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              error={!!(errors?.email && touched?.email)}
              value={values.email}
              name='email' 
              type='email'
              id='email'
              onChange={({ target: { value } }) => setFieldValue('email', value)}
              onBlur={() => setFieldTouched('email')}
            >
              <InsetInput.Label>Email</InsetInput.Label>
            </InsetInput>

            {(!!errors?.email && touched?.email) && (
              <Hint error>{errors?.email}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <InsetPassword
              component={InsetInput}
              label={'Password'}
              error={!!(errors?.password && touched?.password)}
              value={values.password}
              name={'password'}
              onChange={({ target: { value } }) => setFieldValue('password', value)}
              onBlur={() => setFieldTouched('password')}
            />
            {!!(errors?.password && touched?.password) && (
              <Hint error>{errors?.password}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <Checkbox
              checked={values.remember}
              label={'Stay logged in for the next 30 days'}
              onChange={() => setFieldValue('remember', !values.remember)}
            />
          </div>
        </>
      )}
      onSubmit={onSubmit}
    />
  );
};

export default SignInForm;

