import { FormEvent, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { InsetInput, Hint, Group } from '@heathmont/moon-core-tw';
import request from '../../utils/request';
import { userProfileSchema, getInitialValues } from './schema';
import { showToast } from '../../utils/common';
import { IChangeDocumentForm } from './types';
import SelectOptions from '../SelectOptions';
import { formatCountries, formatDocumentTypes } from './utils';
import EditAccountFormActions from '../EditAccountFormActions';
import { extractErrorFromResponse } from '../../utils/errors';

const ChangeDocumentForm: React.FC<IChangeDocumentForm> = ({
  currentUser,
  updateCurrentUserDocument,
}) => {
  const [travelDocumentsTypes, setTravelDocumentsTypes] = useState([]);
  const [countries, setCountries] = useState([])
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userProfileSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(currentUser.document),
    onSubmit: () => {},
  });
  const { values, errors, touched, dirty, setFieldValue, setFieldTouched, resetForm, validateForm } = formik;

  const fetchOptions = async () => {
    try {
      const [
        travelDocumentsTypesResponse,
        countriesResponse,
      ] = await Promise.all([
        request.get('white-label/travel-documents-types'),
        request.get('white-label/countries'),
      ]);

      setTravelDocumentsTypes(formatDocumentTypes(travelDocumentsTypesResponse?.data?.travelDocumentsTypes));
      setCountries(formatCountries(countriesResponse?.data?.countries));
    } catch (err) {
      setTravelDocumentsTypes([]);
      setCountries([]);
    }
  };

  useEffect(() => {
    fetchOptions();
    validateForm();
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      await request.update('white-label/user/document', values);
      updateCurrentUserDocument(values);
      showToast('Your document has been updated.', 'success');
    } catch (err) {
      showToast(extractErrorFromResponse(err), 'error');
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <form className='w-full flex flex-col gap-2 tablet:gap-4' onSubmit={onSubmit}>
        <div className='w-full flex flex-col'>
          <Group className='rounded-moon-i-md'>
            <Group.FirstInsetSelect
              className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
              error={!!(errors?.typeId && touched?.typeId)}
              value={values.typeId || ''}
              label={'Document type'}
              name='typeId'
              id='typeId'
              onChange={({ target: { value } }) => setFieldValue('typeId', value)}
              onBlur={() => setFieldTouched('typeId')}
            >
              <SelectOptions options={travelDocumentsTypes} label='document type' />
            </Group.FirstInsetSelect>
          
            <Group.LastInsetSelect
              className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
              error={!!(errors?.countryId && touched?.countryId)}
              value={values.countryId || ''}
              label={'Country issued document'}
              name='countryId'
              id='countryId'
              onChange={({ target: { value } }) => setFieldValue('countryId', value)}
              onBlur={() => setFieldTouched('countryId')}
            >
              <SelectOptions options={countries} label='country issued document' />
            </Group.LastInsetSelect>
          </Group>

          {!!(errors?.typeId && touched?.typeId) && (
            <Hint error>{errors?.typeId}</Hint>
          )}
          {!!(errors?.countryId && touched?.countryId) && (
            <Hint error>{errors?.countryId}</Hint>
          )}
        </div>

        <div className='w-full flex flex-col'>
          <Group className='rounded-moon-i-md'>
            <Group.FirstInsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              placeholder=' '
              error={!!(errors?.number && touched?.number)}
              value={values.number}
              name='number'
              id='number'
              onChange={({ target: { value } }) => setFieldValue('number', value)}
              onBlur={() => setFieldTouched('number')}
            >
              <InsetInput.Label>Document number</InsetInput.Label>
            </Group.FirstInsetInput>
            <Group.LastInsetInput
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              placeholder='Document expiration date'
              error={!!(errors?.validTill && touched?.validTill)}
              value={values.validTill}
              type='date'
              name='validTill'
              id='validTill'
              onChange={({ target: { value } }) => setFieldValue('validTill', value || '')}
              onBlur={() => setFieldTouched('validTill')}
            >
              <InsetInput.Label>Document expiration date</InsetInput.Label>
            </Group.LastInsetInput>
          </Group>
      
          {!!(errors?.number && touched?.number) && (
            <Hint error>{errors?.number}</Hint>
          )}
          {!!(errors?.validTill && touched?.validTill) && (
            <Hint error>{errors?.validTill}</Hint>
          )}
        </div>

        <EditAccountFormActions
          isSubmitLoading={isSubmitLoading}
          errors={errors}
          dirty={dirty}
          discardChanges={() => {
            resetForm({ values: getInitialValues(currentUser.document), isValidating: false });
            validateForm(getInitialValues(currentUser.document));
          }}
        />
      </form>
    </FormikProvider>
  );
};

export default ChangeDocumentForm;
