import request from '../../utils/request';
import { showToast } from '../../utils/common';
import { extractErrorFromResponse } from '../../utils/errors';

export const handleSubmitContactForm = async ({
  description,
  email,
  name,
  setIsContactFormLoading,
  onClose,
}: {
  description: string,
  name: string,
  email: string;
  setIsContactFormLoading: (isContactFormLoading: boolean) => void,
  onClose: () => void;
}) => {
  try {
    setIsContactFormLoading(true);

    await request.post(`white-label/contact`, {
      description: description?.trim(),
      email: email?.trim(),
      name: name?.trim(),
    });

    setIsContactFormLoading(false);
    onClose();
    showToast('Your request has been sent', 'success');
  } catch (err) {
    setIsContactFormLoading(false);
    showToast(extractErrorFromResponse(err), 'error');
  }
};
