import { useMemo, useState } from 'react';
import { Combobox, Hint, MenuItem } from '@heathmont/moon-core-tw';
import { ControlsChevronDownSmall } from '@heathmont/moon-icons-tw';
import { ISearchSelect } from './types';
import { filterOptions } from './utils';
import { UserOption } from '../../enums/UserOption';
import { fieldWrapperCN } from '../FlightBookingContactForm/styles';

const UsersSearchSelect: React.FC<ISearchSelect> = ({
  placeholder,
  isError,
  users,
  label,
  value,
  error,
  setTouched,
  onChange,
}) => {
  const [query, setQuery] = useState<string>('');
  const filteredUsers = useMemo(() => filterOptions(query, users), [query, users]);

  const handleChangeOption = (selectedOption) => {
    onChange(selectedOption.id);
    setTouched();
  };

  const displayValue = (passenger: UserOption & { label: string }) => {
    return `${passenger.firstName} ${passenger.lastName}`;
  };

  return (
    <div className={fieldWrapperCN}>
      <Combobox
        isError={isError}
        value={users.find(option => +option.id === +value)}
        onQueryChange={setQuery}
        onChange={handleChangeOption}
      >
        {({ open }) => (
          <>
            <Combobox.InsetSelect
              displayValue={displayValue}
              className='rounded-moon-i-md'
              placeholder={placeholder}
              label={label}
              open={open}
            >
              <ControlsChevronDownSmall className='text-trunks' />
            </Combobox.InsetSelect>
            <Combobox.Transition>
              <Combobox.Options>
                {filteredUsers.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-trunks'>
                    User is not found
                  </div>
                ) : (
                  filteredUsers.map((user, index) => (
                    <Combobox.Option value={user} key={index}>
                      {({ selected, active }) => (
                        <MenuItem isActive={active} isSelected={selected}>
                          <MenuItem.MultiTitle
                            title={`${user.firstName} ${user.lastName}`}
                            text={<span>{user.email}</span>}
                          />
                        </MenuItem>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Combobox.Transition>
          </>
        )}
      </Combobox>
      {isError && (
        <Hint error>{error}</Hint>
      )}
    </div>
  );
};

export default UsersSearchSelect;
