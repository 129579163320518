import { connect } from 'react-redux';
import FlightBookingPassengers from './FlightBookingPassengers.ui';
import {
  toggleTransferRequired,
  updateAdditionalBags,
  updatePassengers,
} from '../../store/flightBookings/actions';
import { ITripReducer } from '../../store/trip/reducer';
import { setIsTermsAndConditionsOpen } from '../../store/dialogs/actions';
import { IDialogsReducer } from '../../store/dialogs/reducer';
import { IAuthReducer } from '../../store/auth/reducer';

const mapStateToProps = ({
  flightBookingsReducer,
  dialogsReducer,
  authReducer,
  tripReducer,
}: {
  flightBookingsReducer: any;
  dialogsReducer: IDialogsReducer;
  tripReducer: ITripReducer;
  authReducer: IAuthReducer;
}) => {
  return {
    isTermsAndConditionsOpen: dialogsReducer.isTermsAndConditionsOpen,
    transferRequired: flightBookingsReducer.transferRequired,
    additionalBags: flightBookingsReducer.additionalBags,
    selectedOffers: flightBookingsReducer.selectedOffers,
    tripSettings: tripReducer.settings,
    searchParams: flightBookingsReducer.search,
    amaClientRef: flightBookingsReducer.amaClientRef,
    currentUser: authReducer.currentUser,
    passengers: flightBookingsReducer.passengers,
  };
};

const mapDispatchToProps = {
  setIsTermsAndConditionsOpen,
  toggleTransferRequired,
  updateAdditionalBags,
  updatePassengers,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingPassengers);
