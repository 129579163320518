import { connect } from 'react-redux';
import SignIn from './SignIn.ui';
import { setIsContactFormOpen } from '../../store/dialogs/actions';
import { setAccessToken } from '../../store/auth/actions';

const mapStateToProps = ({
  dialogsReducer,
}) => {
  return {
    isContactFormOpen: dialogsReducer.isContactFormOpen,
  };
};

const mapDispatchToProps = {
  setIsContactFormOpen,
  setAccessToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
