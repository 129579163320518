import { Navigate, Outlet } from 'react-router-dom';
import { IProtectedRoute } from './types';

const ProtectedRoute: React.FC<IProtectedRoute> = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to='/sign-in' replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
