import Footer from '../../components/Footer';
import PageWrapper from '../../components/PageWrapper';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import useIsMobile from '../../hooks/useIsMobile';
import { IResetPassword } from './types';

const ResetPassword: React.FC<IResetPassword> = ({
  isContactFormOpen,
  setIsContactFormOpen,
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper authPage isMobile={isMobile} page={'reset-password'}>
      <ResetPasswordForm />
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default ResetPassword;
