import { saveAs } from 'file-saver';
import request from '../../utils/request';
import { getErrorStatusMessage } from '../../utils/common';
import { TripStatusType } from '../../enums/TripStatus';
import { Trip } from '../../enums/Trip';
import { isInvoicePayment, isSuccessPayment } from '../FlightBookingConfirmation/utils';
import { FlightOffer } from '../../enums/FlightOffer';
import { Flight } from '../../enums/Flight';
import { ExtraBaggage } from '../../enums/ExtraBaggage';
import { Passenger } from '../../enums/Passenger';
import { FareDetails } from '../../enums/FareDetails';

export const getTitle = (totalCost?: number) => {
  return `Booking overview${totalCost ? ` — €${totalCost}` : ''}`;
};

export const getSubTitle = (status: TripStatusType): string | null => {
  if (status === TripStatusType.PENDING) {
    return 'Your trip has been booked and waiting for manager approval';
  }

  if (status === TripStatusType.APPROVED) {
    return 'Your trip has been booked and waiting to be confirmed';
  }

  if (status === TripStatusType.PURCHASED) {
    return 'Your trip has been booked and confirmed';
  }

  if (status === TripStatusType.DECLINED) {
    return 'Your trip has been declined';
  }

  return null;
};

export const handleFetchTrip = async ({
  tripId,
  setIsTripLoading,
  setTrip,
}: {
  tripId: string;
  setIsTripLoading: (isLoading: boolean) => void;
  setTrip: (booking: Trip | null) => void;
}) => {
    try {
      setIsTripLoading(true);

      const response = await request.get(`white-label/user/trips/${tripId}`);

      if (response.data.trip) {
        setTrip(response.data.trip);
      } else {
        setTrip(null);
      }
    } catch (err) {
      setTrip(null);
    } finally {
      setIsTripLoading(false);
    }
  };

export const handleDownloadInvoice = async ({
  tripId,
  setIsInvoiceDownloading,
}: {
  tripId: string;
  setIsInvoiceDownloading: (isLoading: boolean) => void;
}) => {
  try {
    setIsInvoiceDownloading(true);

    const response = await request.get(`white-label/user/trips/${tripId}/invoices`);

    if (response?.data?.invoices?.length) {
      for (const invoice of response.data.invoices) {
        try {
          const invoicePdf = await fetch(invoice?.url);
          const blob = await invoicePdf.blob();
          saveAs(blob, `${invoice?.name}.pdf`)
        } catch (err) {
          getErrorStatusMessage({
            status: err?.response?.status,
            message: err?.response?.data?.error || 'Something went wrong. Try again later',
          });
        }
      }
    }
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later',
    });
  } finally {
    setIsInvoiceDownloading(false);
  }
};

export const handleDownloadTicket = async ({
  tripId,
  setIsTicketsDownloading,
}: {
  tripId: string;
  setIsTicketsDownloading: (isLoading: boolean) => void;
}) => {
  try {
    setIsTicketsDownloading(true);

    const response = await request.get(`white-label/user/trips/${tripId}/ticket`);

    if (response?.data?.ticket) {
      try {
        const ticketPdf = await fetch(response.data.ticket.url);
        const blob = await ticketPdf.blob();
        saveAs(blob, `${response.data.ticket.name}.pdf`)
      } catch (err) {
        getErrorStatusMessage({
          status: err?.response?.status,
          message: err?.response?.data?.error || 'Something went wrong. Try again later',
        });
      }
    }
  } catch (e) {
    getErrorStatusMessage({
      status: e?.response?.status,
      message: e?.response?.data?.error || 'Something went wrong. Try again later',
    });
  } finally {
    setIsTicketsDownloading(false);
  }
};

export const hasPaymentsWithInvoice = (payments): boolean => {
  return !!payments?.length && payments.some(payment => (
    isInvoicePayment(payment?.paymentType) || isSuccessPayment(payment?.status)
  ));
};

export const transformFlightToFlightOffer = (flight: Flight): FlightOffer => {
  return {
    ...flight,
    itineraries: flight.itineraries.map(itinerary => {
      return {
        ...itinerary,
        segments: itinerary.segments.map(segment => {
          return {
            ...segment,
            carrierName: segment.airline.title,
            carrierCode: segment.airline.code,
            arrival: {
              iataCode: segment.arrival.code,
              at: segment.arrival.timestamp,
            },
            departure: {
              iataCode: segment.departure.code,
              at: segment.departure.timestamp,
            },
          };
        })
      }
    }), 
  }
};

export const getPassengerAdditionalServices = ({
  passengerId,
  passengers,
  segmentId,
}: {
  passengerId: string | number;
  passengers: Passenger[];
  segmentId: string | number;
}): ExtraBaggage[] => {
  const passenger = passengers.find(passenger => +passenger.id === +passengerId);

  if (!passenger?.additionalServices?.length) {
    return [];
  }

  const extraBags: ExtraBaggage[] = [];

  passenger?.additionalServices.forEach(additionalService => {
    if (+additionalService.segmentId === +segmentId) {
      const extraBag = extraBags.find(extraBag => (
        extraBag.weight === additionalService.weight
        && extraBag.weightUnit === additionalService.weightUnit
      ));

      if (extraBag) {
        extraBag.quantity = extraBag.quantity + additionalService.quantity;
      } else {
        extraBags.push({ 
          weightUnit: additionalService.weightUnit,
          quantity: additionalService.quantity,
          weight: additionalService.weight,
        });
      }
    }
  });

  return extraBags;
};

export const getFareDetails = (flight: Flight): FareDetails => {
  if (flight?.itineraries?.length && flight?.itineraries[0].segments?.length) {
    const segment = flight.itineraries[0].segments[0];
    return {
      includedCheckedBags: segment.includedCheckedBags,
      cabin: segment.cabin,
    };
  };

  return {};
};


