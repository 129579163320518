import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { IAccount } from './types';
import Footer from '../../components/Footer';
import { ACCOUNT_TAB } from '../../enums/AccountTabs';
import AccountWrapper from '../../components/AccountWrapper';
import ChangeProfileForm from '../../components/ChangeProfileForm';

const Account: React.FC<IAccount> = ({
  isContactFormOpen,
  currentUser,
  setIsContactFormOpen
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper isMobile={isMobile} page={'account'}>
      <AccountWrapper currentUser={currentUser} currentTab={ACCOUNT_TAB.PROFILE}>
        <ChangeProfileForm />
      </AccountWrapper>

      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default Account;
