import { mergeClassnames } from '@heathmont/moon-core-tw';
import { Link as RouterLink } from 'react-router-dom';
import { ITermsAndConditionsLink } from './types';
import { btnLink } from '../../styles/buttons';

const TermsAndConditionsLink: React.FC<ITermsAndConditionsLink> = ({
  openTermsAndConditions,
}) => {
  const title = 'terms and conditions';

  const handleOpenTermsAndConditions = (e) => {
    e.preventDefault();
    openTermsAndConditions();
  };

  return (
    <>
      <a className={mergeClassnames(btnLink, 'inline', 'laptop:hidden')} onClick={handleOpenTermsAndConditions}>
        {title}
      </a>
      <RouterLink className={mergeClassnames(btnLink, 'hidden', 'laptop:inline')} to={'/terms-and-conditions'} target={'_blank'}>
        {title}
      </RouterLink>
    </>
  );
};

export default TermsAndConditionsLink;
