import { getFlightDuration } from '../../utils/common';
import { FlightOfferSegment } from '../../enums/FlightOfferSegment';
import { FlightOfferStop } from '../../enums/FlightOfferStop';
import { FlightItinerary } from '../../enums/FlightItinerary';
import { Logo } from '../../enums/Logo';
import { Stop } from '../../enums/Stop';

export const getAirlineLogoUrl = (carrierCode: string, carrierName: string) => {
  const filename = `${carrierCode}_${carrierName}.PNG`.replace(/\//g, '.');
  return encodeURI(`${process.env.REACT_APP_IMAGES_URL}/${filename}`);
};

export const getFlightAirlineCompanyLogo = (itinerary: FlightItinerary<FlightOfferSegment>): Logo => {
  const [ { carrierCode, carrierName } ] = itinerary.segments;

  return {
    url: getAirlineLogoUrl(carrierCode, carrierName),
    alt: `${carrierCode}_${carrierName}`,
  };
};

export const getStopDuration = (arrival: FlightOfferStop, departure: FlightOfferStop) => {
  if (departure) {
    return getFlightDuration(departure.at, arrival.at);
  }

  return null;
};

export const extractFlightStops = (itinerary: FlightItinerary<FlightOfferSegment>): Stop[] => {
  if (itinerary.segments.length > 1) {
    return itinerary.segments
      .map((s, index) => ({
        duration: getStopDuration(s.arrival, itinerary.segments[index + 1]?.departure),
        code: s.arrival.iataCode,
      }))
      .filter((s, index) => index + 1 !== itinerary.segments.length)
  }

  return [];
};

export const hasGroupOtherArrivalDepartureTimes = (group) => {
  return group?.originItineraries?.length > 1
    || group?.returnItineraries?.length > 1;
};
