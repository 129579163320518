import { useEffect, useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import {
  handleRefreshTransaction,
  handleFetchTransaction,
} from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { CryptoTransaction } from '../../enums/CryptoTransaction';
import CryptoPaymentCheckoutComponent from '../../components/CryptoPaymentCheckout';
import { useQuery } from '../../hooks/useQuery';

const CryptoPaymentCheckout = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [transaction, setTransaction] = useState<CryptoTransaction | null>(null);
  const [paymentDetails, setPaymentDetails] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const [refreshTransactionLoading, setRefreshTransactionLoading] = useState<boolean>(false);
  const { getQuery, setQuery } = useQuery();
  const { token } = useMemo(() => getQuery(), []) as { token: string };

  const redirectToSuccessfulPayment = (): void => {
    navigate(`/${FLIGHT_BOOKING_STEP.SUCCESSFUL_PAYMENT}/?token=${token}`);
  };

  const redirectToAdditionalPaymentPage = () => {
    navigate(`/${FLIGHT_BOOKING_STEP.ADDITIONAL_PAYMENT}/?token=${token}`);
  };

  const refreshToken = (newToken: string) => {
    setQuery({ token: newToken });
    window.location.reload();
  };

  const handleCreateNewTransaction = () => {
    handleRefreshTransaction({
      token,
      coin: transaction?.coin,
      setRefreshTransactionLoading,
      setPaymentDetails,
      setPaymentError,
      refreshToken,
    });
  };

  const handleRefetchTransaction = () => {
    handleFetchTransaction({
      initial: false,
      token,
      redirectToSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setTransaction,
      setIsLoading,
      setCanRetry,
    });
  };

  useEffect(() => {
    handleFetchTransaction({
      initial: true,
      token,
      redirectToSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setTransaction,
      setIsLoading,
      setCanRetry,
    });
  }, [token]);

  return (
    <PageWrapper
      isMobile={isMobile}
      page={'crypto-payment-checkout'}
    >
      <CryptoPaymentCheckoutComponent
        needToRefetchTransaction={!paymentError}
        isRefreshLoading={refreshTransactionLoading}
        paymentDetails={paymentDetails}
        paymentError={paymentError}
        transaction={transaction}
        isLoading={isLoading}
        canRetry={canRetry}
        onPaymentMethodChange={redirectToAdditionalPaymentPage}
        setPaymentDetails={setPaymentDetails}
        fetchTransaction={handleRefetchTransaction}
        setPaymentError={setPaymentError}
        setCanRetry={setCanRetry}
        onRefresh={handleCreateNewTransaction}
      />
    </PageWrapper>
  );
};

export default CryptoPaymentCheckout;
