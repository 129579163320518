import { useState } from 'react';
import { Checkbox } from '@heathmont/moon-core-tw';
import { IFlightBookingOverview } from './types';
import FlightBookingTermsAndConditionsDialog from '../../components/FlightBookingTermsAndConditionsDialog';
import PageWrapper from '../../components/PageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import FlightBookingOffer from '../../components/FlightBookingOffer';
import { useFlightOffersPriceConfirmation } from '../../hooks/useFlightOffersPriceConfirmation';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import FlightBookingStepsWrapper from '../../components/FlightBookingStepsWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { checkboxLabelCN } from '../../styles/texts';
import TermsAndConditionsLink from '../../components/TermsAndConditionsLink';

const FlightBookingOverview: React.FC<IFlightBookingOverview> = ({
  isMoreFareRulesModalOpen,
  isTermsAndConditionsOpen,
  fareRulesOfferId,
  selectedOffers = [],
  tripSettings,
  amaClientRef,
  travelers,
  setIsTermsAndConditionsOpen,
  setIsMoreFareRulesModalOpen,
}) => {
  const isMobile = useIsMobile();
  const {
    includedDetailedFareRules,
    isPriceConfirmationError,
    isSignificantPriceChange,
    isLoading,
    price,
  } = useFlightOffersPriceConfirmation({
    includeDetailedFareRules: true,
    tripPurposeId: tripSettings.tripPurposeId,
    amaClientRef,
    offers: selectedOffers,
  });
  const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState<boolean>(false);

  const handleAgreeTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(false);
    setAgreeWithTermsAndConditions(true);
  };

  const handleOpenTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(true)
  };

  return (
    <PageWrapper isMobile={isMobile} page={'flight-booking-overview'}>
      <FlightBookingStepsWrapper
        isPriceConfirmationError={isPriceConfirmationError}
        isSignificantPriceChange={isSignificantPriceChange}
        paymentRequired={tripSettings.paymentRequired}
        currentStep={FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION}
        isLoading={isLoading}
        renderContinueAndBackButton={() => (
          <FlightBookingBackAndNext
            isLoading={isLoading}
            disabled={isPriceConfirmationError || !agreeWithTermsAndConditions}
            price={price}
            step={FLIGHT_BOOKING_STEP.PRICE_CONFIRMATION}
          />
        )}
      >
        <div className='w-full flex flex-col gap-[18px]'>
          {selectedOffers.map((offer) => (
            <FlightBookingOffer
              includedDetailedFareRules={includedDetailedFareRules}
              isMoreFareRulesModalOpen={isMoreFareRulesModalOpen}
              withAdditionalInfo
              fareRulesOfferId={fareRulesOfferId}
              travelers={travelers}
              isLoading={isLoading}
              offer={offer}
              key={offer.id}
              setIsMoreFareRulesModalOpen={setIsMoreFareRulesModalOpen}
            />
          ))}

          <div className='w-full flex flex-col'>
            <Checkbox
              disabled={isLoading}
              checked={agreeWithTermsAndConditions}
              label={(
                <span className={checkboxLabelCN}>
                  I understand and agree with <TermsAndConditionsLink openTermsAndConditions={handleOpenTermsAndConditions} />
                </span>
              )}
              onChange={() => setAgreeWithTermsAndConditions(prevValue => !prevValue)}
            />
          </div>
        </div>
      </FlightBookingStepsWrapper>

      {isTermsAndConditionsOpen && (
        <FlightBookingTermsAndConditionsDialog
          onAgree={handleAgreeTermsAndConditions}
          onClose={() => setIsTermsAndConditionsOpen(false)}
        />
      )}
    </PageWrapper>
  );
};

export default FlightBookingOverview;
