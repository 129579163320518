import { FormEvent, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InsetInput, Hint } from '@heathmont/moon-core-tw';
import AuthForm from '../AuthForm';
import request from '../../utils/request';
import { getInitialValues, forgotPasswordSchema } from './schema';
import AuthFormLinks from '../AuthFormLinks';
import { extractErrorFromResponse } from '../../utils/errors';

const ForgotPasswordForm = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: forgotPasswordSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      await request.post('white-label/auth/forgot-password', values);
      setSuccessMessage('Check your inbox for instructions to reset your password');
    } catch (err) {
      setErrorMessage(extractErrorFromResponse(err));
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleTryAgain = () => {
    setErrorMessage(null);
  };

  return (
    <AuthForm
      submitButtonTitle='Submit'
      isLoading={isSubmitLoading}
      success={successMessage}
      error={errorMessage}
      title='Reset password'
      value={formik}
      handleTryAgain={handleTryAgain}
      renderAdditionalText={() => <AuthFormLinks />}
      renderSubTitle={() => (
        <>
          Please enter the email address you used when creating the account. 
        </>
      )}
      renderFields={() => (
        <>
          <div className='w-full flex flex-col'>
            <InsetInput
              placeholder=' '
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              error={!!(errors?.email && touched?.email)}
              value={values.email}
              name='email' 
              type='email'
              id='email'
              onChange={({ target: { value } }) => setFieldValue('email', value)}
              onBlur={() => setFieldTouched('email')}
            >
              <InsetInput.Label>Email</InsetInput.Label>
            </InsetInput>

            {(!!errors?.email && touched?.email) && (
              <Hint error>{errors?.email}</Hint>
            )}
          </div>
        </>
      )}
      onSubmit={onSubmit}
    />
  );
};

export default ForgotPasswordForm;

