import { Button } from '@heathmont/moon-core-tw';
import { IAuthFormContent } from './types';

const AuthFormContent: React.FC<IAuthFormContent> = ({
  buttonTitle,
  title,
  text,
  onClick,
}) => {
  return (
    <div className='w-full'>
      <h1 className='text-moon-32 tablet:text-moon-40 font-medium'>
        {title}
      </h1>
      <p className='text-moon-16 mt-0.5'>
        {text}
      </p>
      {onClick && (
        <Button className='w-full mt-4' size='xl' onClick={onClick}>
          {buttonTitle}
        </Button>
      )}
    </div>
  );
};

export default AuthFormContent;
