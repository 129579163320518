import {
  SET_IS_TRIP_PURPOSE_SELECTION_OPEN,
  SET_IS_TERMS_AND_CONDITIONS_OPEN,
  SET_IS_MORE_FARE_RULES_OPEN,
  SET_IS_FEEDBACK_FORM_OPEN,
  SET_IS_PRICE_CHANGE_OPEN,
  SET_IS_CONTACT_FORM_OPEN,
} from './actionTypes';

export interface IDialogsReducer {
  isTripPurposeSelectionOpen: boolean;
  isTermsAndConditionsOpen: boolean;
  isMoreFareRulesModalOpen: boolean;
  isPriceChangedModalOpen: boolean;
  isFeedbackModalOpen: boolean;
  isContactFormOpen: boolean;
  fareRulesOfferId?: string | number | null;
}

const initState:IDialogsReducer = {
  isTripPurposeSelectionOpen: false,
  isTermsAndConditionsOpen: false,
  isMoreFareRulesModalOpen: false,
  isPriceChangedModalOpen: false,
  isFeedbackModalOpen: false,
  isContactFormOpen: false,
  fareRulesOfferId: null,
};

const dialogsReducer = (state = initState, action): IDialogsReducer => {
  switch (action.type) {
    case SET_IS_TRIP_PURPOSE_SELECTION_OPEN: {
      return {
        ...state,
        isTripPurposeSelectionOpen: action.payload.isOpen,
      };
    }
    
    case SET_IS_TERMS_AND_CONDITIONS_OPEN: {
      return {
        ...state,
        isTermsAndConditionsOpen: action.payload.isOpen,
      };
    }

    case SET_IS_MORE_FARE_RULES_OPEN: {
      return {
        ...state,
        isMoreFareRulesModalOpen: action.payload.isOpen,
        fareRulesOfferId: action.payload.offerId,
      };
    }

    case SET_IS_PRICE_CHANGE_OPEN: {
      return {
        ...state,
        isPriceChangedModalOpen: action.payload.isOpen,
      };
    }

    case SET_IS_CONTACT_FORM_OPEN: {
      return {
        ...state,
        isContactFormOpen: action.payload.isOpen,
      };
    }

    case SET_IS_FEEDBACK_FORM_OPEN: {
      return {
        ...state,
        isFeedbackModalOpen: action.payload.isOpen,
      };
    }

    default:
      return state;
  }
};

export default dialogsReducer;
