import request from '../../utils/request';

const formatAirlines = (airlines = []): { value: number, label: string }[] => {
  return airlines.map(airline => ({
    value: airline.value,
    label: airline.title,
  }));
};

export const fetchOptions = async ({
  setIsOptionsLoading,
  setAirlines,
  setUsers,
}: {
  setIsOptionsLoading: (isLoading: boolean) => void;
  setAirlines: (airlines: any[]) => void;
  setUsers: (users: any[]) => void;
}) => {
  try {
    setIsOptionsLoading(true);

    const [ usersResponse, airlinesResponse ] = await Promise.all([
      request.get('white-label/user/employees'),
      request.get('white-label/airlines'),
    ]);

    if (usersResponse?.data?.users) {
      setUsers(usersResponse?.data?.users)
    }

    if (airlinesResponse?.data?.airlines) {
      setAirlines(formatAirlines(airlinesResponse?.data?.airlines));
    }

    setIsOptionsLoading(false);
  } catch (err) {
    setIsOptionsLoading(false);
  }
};
