import { Tabs as MoonTabs } from '@heathmont/moon-core-tw';
import { ITabs } from './types';
import { useMemo } from 'react';

const Tabs: React.FC<ITabs> = ({
  options,
  value,
  onChange,
}) => {
  const selectedIndex = useMemo(() => {
    return options.findIndex(option => option.value === value);
  }, [options, value]);

  const handleCHange = (index) => {
    onChange(options[index].value);
  };

  return (
    <MoonTabs
      selectedIndex={selectedIndex}
      onChange={handleCHange}
    >
      <MoonTabs.Segment className='w-full'>
        {options.map(option => (
          <MoonTabs.Pill key={option.value}>
            <span className='tablet:hidden'>
              {option.icon || option.label}
            </span>
            <span className='max-tablet:hidden'>
              {option.label}
            </span>
          </MoonTabs.Pill>
        ))}
      </MoonTabs.Segment>
    </MoonTabs>
  );
};

export default Tabs;
