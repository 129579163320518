import { Button } from '@heathmont/moon-core-tw';
import { ReactComponent as GoogleSvg } from '../../static/icons/google.svg';

const GoogleAuthButton = () => {
  const onClick = () => {
    const host = process.env.REACT_APP_API_URL;
    const apiVersion = process.env.REACT_APP_API_VERSION;
    window.location.href = `${host}/api/v${apiVersion}/auth/google`;
  };

  return (
    <Button
      className='bg-goku ring-beerus hover-supported:hover:ring-beerus'
      variant="outline"
      size='xl'
      onClick={onClick}
    >
      <span className='absolute left-4 top-2/4 -translate-y-1/2 z-10'>
        <GoogleSvg />
      </span>
      Continue with Google
    </Button>
  );
};

export default GoogleAuthButton;
