import moment from 'moment';
import { FlightSearchParams } from '../../enums/FlightSearchParams';

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatValueToSearchParams = (values: any): FlightSearchParams => {
  return {    
    departureDate: values.departureDate
      ? formatDate(values.departureDate)
      : null,
    destination: values?.destination?.code,
    returnDate: values.returnDate
      ? formatDate(values.returnDate)
      : null,
    passengers: values.passengers,
    origin: values?.origin?.code,
    cabin: values.cabin,
  };
};
