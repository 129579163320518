export const formatDocumentTypes = (documentTypes = []): { value: number, label: string }[] => {
  return documentTypes.map(documentType => ({
    value: documentType.id,
    label: documentType.name,
  }));
};

export const formatCountries = (countries = []): { value: number, label: string, flag: string }[] => {
  return countries.map(country => ({
    value: country.id,
    label: country.name,
    flag: country.flag,
  }));
};