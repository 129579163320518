import { CurrentUserProfile } from '../../enums/CurrentUserProfile';
import { CurrentUser } from '../../enums/CurrentUser';
import {
  UPDATE_CURRENT_USER_DOCUMENT,
  UPDATE_CURRENT_USER_PROFILE,
  SET_ACCESS_TOKEN,
  SET_CURRENT_USER,
} from './actionTypes';
import { PassengerDocument } from '../../enums/PassengerDocument';

export const setCurrentUser = (user: CurrentUser) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

export const updateCurrentUserProfile = (profile: CurrentUserProfile) => {
  return {
    type: UPDATE_CURRENT_USER_PROFILE,
    payload: profile,
  };
};

export const updateCurrentUserDocument = (document: PassengerDocument) => {
  return {
    type: UPDATE_CURRENT_USER_DOCUMENT,
    payload: document,
  };
};

export const setAccessToken = (token: string) => {
  localStorage.setItem('token', token);
  return {
    type: SET_ACCESS_TOKEN,
    payload: token,
  };
};
