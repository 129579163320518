import { mergeClassnames } from "@heathmont/moon-core-tw";

export const avatarCN = (light: boolean) => mergeClassnames([
  'w-10',
  'h-10',
  'flex',
  'justify-center',
  'items-center',
  'rounded-full',
  'border',
  'text-moon-14',
  'uppercase',
  light ? 'text-goku' : 'text-piccolo',
  light ? 'bg-piccolo/0' : 'bg-piccolo/10',
  light ? 'border-goku' : 'border-beerus',
]);

export const linkCN = (light: boolean) => mergeClassnames([
  'font-normal',
  'text-moon-14',
  'cursor-pointer',
  'underline',
  'hover:underline',
  light ? 'text-goku/80' : 'text-trunks',
  light ? 'hover:text-goku' : 'hover:text-[#5b5b5b]',
]);
