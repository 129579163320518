import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { IFlightBookingPassengers } from './types';
import PageWrapper from '../../components/PageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import { useFlightOffersPriceConfirmation } from '../../hooks/useFlightOffersPriceConfirmation';
import FlightBookingStepsWrapper from '../../components/FlightBookingStepsWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import { IncludedBagOption, IncludedBagOptionValue } from '../../enums/IncludedBag';
import { calculatePriceWithBags, extractBagsOptions } from '../../utils/baggage';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import useDestinations from '../../hooks/useDestinations';
import FlightBookingPassengersForm from '../../components/FlightBookingPassengersForm';
import FlightBookingTermsAndConditionsDialog from '../../components/FlightBookingTermsAndConditionsDialog';
import BookingFlowConfirmationSettings from '../../components/BookingFlowConfirmationSettings';
import { handleBookFlight, isBookingHasLowCostOffers } from '../../utils/flights';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import FlightBookingInitialLoader from '../../components/FlightBookingInitialLoader';
import { BOOK_FLIGHT_LOADING_TEXTS } from '../../constants';
import { FormikProps, useFormik } from 'formik';
import { getInitialValues, passengersSchema } from './schema';
import { validateBaggages } from './utils';
import { USER_STATUS } from '../../enums/UserStatus';
import InternalPassengersForm from '../../components/InternalPassengersForm';
import { InternalPassenger } from '../../enums/InternalPassenger';
import { Passenger } from '../../enums/Passenger';

const FlightBookingPassengers: React.FC<IFlightBookingPassengers> = ({
  isTermsAndConditionsOpen,
  transferRequired,
  additionalBags,
  selectedOffers = [],
  tripSettings,
  searchParams,
  amaClientRef,
  currentUser,
  passengers = [],
  setIsTermsAndConditionsOpen,
  toggleTransferRequired,
  updateAdditionalBags,
  updatePassengers,
}) => {
  const componentWillUnmount = useRef(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { origin, destination, isLoading: destinationLoading } = useDestinations({ searchParams });
  const {
    isPriceConfirmationError,
    isSignificantPriceChange,
    documentRequired,
    confirmedOffers,
    includedBags,
    isLoading,
    price,
  } = useFlightOffersPriceConfirmation({
    tripPurposeId: tripSettings.tripPurposeId,
    amaClientRef,
    offers: selectedOffers,
  });
  const [additionalBagPerPassenger, setAdditionalBagPerPassenger] = useState<IncludedBagOptionValue[]>(additionalBags);
  const additionalBagErrors = useMemo(() => validateBaggages(additionalBagPerPassenger, passengers), [additionalBagPerPassenger, passengers]);
  const [bagsOptions, setBagsOptions] = useState<IncludedBagOption[]>([]);
  const totalPrice = useMemo(() => calculatePriceWithBags(price, additionalBagPerPassenger), [price, additionalBagPerPassenger]);
  const isPageLoading = useMemo(() => isLoading || destinationLoading, [isLoading, destinationLoading]);
  const [agreeToStorePersonalData, setAgreeToStorePersonalData] = useState<boolean>(false);
  const isLowCostBooking = useMemo(() => isBookingHasLowCostOffers(selectedOffers), [selectedOffers]);
  const [bookingLoading, setBookingLoading] = useState<boolean>(false);
  const [bookingError, setBookingError] = useState<string | null>(null);
  const paymentRequired = useMemo(() => !!tripSettings.paymentRequired, [tripSettings.paymentRequired]);
  const isExternalUser = useMemo(() => currentUser.status === USER_STATUS.EXTERNAL, [currentUser.status]);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: passengersSchema({
      paymentRequired,
      selectedOffers,
      isExternalUser,
    }),
    validateOnBlur: true,
    initialValues: {
      passengers: getInitialValues({
        paymentRequired,
        isExternalUser,
        currentUser,
        passengers
      }),
    },
    onSubmit: () => {},
  });
  const { values, errors } = formik;

  const handleAgreeTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(false);
    setAgreeToStorePersonalData(true);
  };

  const handleResetError = () => {
    setBookingLoading(false);
    setBookingError(null);
  };

  const handleBookFlightProceed = async () => {
    return await handleBookFlight({
      isLowCostBooking,
      transferRequired,
      additionalBags,
      tripSettings,
      includedBags,
      amaClientRef,
      currentUser,
      passengers: values.passengers,
      payment: null,
      offers: selectedOffers,
      setBookingLoading,
      setBookingError,
      navigate,
    });
  };

  useEffect(() => {
    if (confirmedOffers && includedBags && origin && destination) {
      setBagsOptions(extractBagsOptions({
        destination: destination?.city,
        origin: origin?.city,
        offers: confirmedOffers,
        bags: includedBags,
      }));
    }
  }, [confirmedOffers, includedBags, origin?.city, destination?.city]);

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current && (paymentRequired || isExternalUser)) {
        updatePassengers(values.passengers as Passenger[]);
      }
    };
  }, [values.passengers, isExternalUser, paymentRequired]);

  const handleSelectBaggage = (bagValue: string, passengerId: number) => {
    const bagOption = bagsOptions.find(bag => bag.value === bagValue);

    if (!bagOption) {
      return;
    }

    const updatedAdditionalBags = additionalBagPerPassenger.some(bag => bag.value === bagValue && bag.passengerId === passengerId)
      ? additionalBagPerPassenger.filter(bag => !(bag.value === bagValue && bag.passengerId === passengerId))
      : [...additionalBagPerPassenger, { ...bagOption, passengerId }];

    setAdditionalBagPerPassenger(updatedAdditionalBags);
    updateAdditionalBags(updatedAdditionalBags);
  };

  return (
    <PageWrapper isMobile={isMobile} page={'flight-booking-passengers'}>
      <FlightBookingStepsWrapper
        isPriceConfirmationError={isPriceConfirmationError}
        isSignificantPriceChange={isSignificantPriceChange}
        paymentRequired={paymentRequired}
        bookingLoading={bookingLoading}
        isBookingError={!!bookingError}
        currentStep={FLIGHT_BOOKING_STEP.PASSENGERS}
        isLoading={isPageLoading}
        renderContinueAndBackButton={() => !bookingError && !bookingLoading && (
          <FlightBookingBackAndNext
            isLoading={isPageLoading}
            disabled={isPriceConfirmationError || !isEmpty(errors) || !isEmpty(additionalBagErrors) || (!paymentRequired && isExternalUser && !agreeToStorePersonalData)}
            price={totalPrice} 
            step={FLIGHT_BOOKING_STEP.PASSENGERS}
            proceedContinue={!paymentRequired && handleBookFlightProceed}
          />
        )}
      >
        {bookingError ? (
          <FlightBookingInfoView
            submitButtonLabel={'Back'}
            subTitle={bookingError}
            title={'The booking was unsuccessful'}
            onClick={handleResetError}
          />
        ) : (
           <>
            <FlightBookingSummary
              departureDate={searchParams.departureDate}
              destination={destination?.city}
              returnDate={searchParams.returnDate}
              isLoading={isPageLoading}
              origin={origin?.city}
            />
            {bookingLoading ? (
              <FlightBookingInitialLoader small texts={BOOK_FLIGHT_LOADING_TEXTS} />
            ) : (
              <>
                {!isExternalUser && !paymentRequired ? (
                  <InternalPassengersForm
                    additionalBagPerPassenger={additionalBagPerPassenger}
                    additionalBagErrors={additionalBagErrors}
                    isPageLoading={isPageLoading}
                    bagsOptions={bagsOptions}
                    formik={formik as FormikProps<{ passengers: InternalPassenger[] }>}
                    handleSelectBaggage={handleSelectBaggage}
                  />
                ) : (
                  <FlightBookingPassengersForm
                    additionalBagPerPassenger={additionalBagPerPassenger}
                    additionalBagErrors={additionalBagErrors}
                    documentRequired={documentRequired}
                    isPageLoading={isPageLoading}
                    bagsOptions={bagsOptions}
                    formik={formik as FormikProps<{ passengers: Passenger[] }>}
                    handleSelectBaggage={handleSelectBaggage}
                  />
                )}
                {!paymentRequired && isExternalUser && (
                  <BookingFlowConfirmationSettings
                    agreeToStorePersonalData={agreeToStorePersonalData}
                    transferRequired={transferRequired}
                    disabled={isLoading || destinationLoading}
                    setAgreeToStorePersonalData={setAgreeToStorePersonalData}
                    setIsTermsAndConditionsOpen={setIsTermsAndConditionsOpen}
                    toggleTransferRequired={toggleTransferRequired}
                  />
                )}
              </>
            )}
          </>
        )}
      </FlightBookingStepsWrapper>

      {isTermsAndConditionsOpen && (
        <FlightBookingTermsAndConditionsDialog
          onAgree={handleAgreeTermsAndConditions}
          onClose={() => setIsTermsAndConditionsOpen(false)}
        />
      )}
    </PageWrapper>
  );
};

export default FlightBookingPassengers;
