import Yup from '../../utils/validation';

const validateMessages = {
  email: {
    disallowedSymbol: 'Email cannot contain "+"',
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
};

export const forgotPasswordSchema = Yup
  .object()
  .shape({
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
      .required(validateMessages.email.required),
  });

export const getInitialValues = (): {
  email: string;
} => {
  return {
    email: '',
  };
};
