import {
  highlightedTextCN,
  sectionTitleCN,
  paragraphCN,
  linkCN,
} from './styles';

const TermsAndConditionsContent: React.FC = () => {
  return (
    <div>
      <p className='text-moon-16 text-bulma font-normal mb-[30px]'>
        These General Terms & Conditions are an integral part of any agreements between Heathmont Travel and the Customer and are available on the Heathmont Travel web app. The General Terms and Conditions apply to all transaction relationships.
      </p>

      <h3 className={sectionTitleCN}>
        1. TERMS
      </h3>
      <p className={paragraphCN}>
        The capitalized terms used in this Agreement shall have the following meanings:
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Invoice“</span> an invoice prepared based on the Price Quote, reflecting the cost of the ordered Travel Services;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Reservation“</span> the booking of Travel Services as presented in the Price Quote by Heathmont Travel;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Reservation Confirmation”</span> the confirmation sent by Heathmont Travel to the Customer regarding the Reservation and its contents;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Price Quote“</span> the price quote sent by Heathmont Travel to the Customer based on the Order for the specified trip, and upon the Customer's confirmation of the Price Quote, Heathmont Travel will make a Reservation for the Travel Services mentioned in the Price Quote
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Agreement“</span> the service agreement concluded between the Parties regarding the ordering of Travel Services and Package Tours by the Customer;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Package Tour“</span> any package tour offered by Heathmont Travel, including package tours designed for business trips;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Travel Services“</span> the passenger transport, accommodation, and conference services, travel organization services, and other travel services provided by Heathmont Travel, including the creation of Package Tours and services related to Package Tours;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Travel Service Agreement“</span> agreements entered into by the Customer and Heathmont Travel for the provision of ordered Travel Services based on this Agreement, with the subject being the provision of the ordered Travel Services;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Heathmont Travel“</span> the travel company that provides Travel Services, including Package Tours, in its economic and professional activities;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Order“</span> the expression of will by the Customer transmitted to Heathmont Travel concerning the Price Quote;
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„Customer“</span> a physical or legal person who has entered into an Agreement with Heathmont Travel and who wishes to order Travel Services provided by Heathmont Travel for use in their economic or professional activities; 
      </p>
      <p className={paragraphCN}>
        <span className={highlightedTextCN}>„General Terms“</span> these general terms and conditions applicable to the Agreement between Heathmont Travel and the Customer.
      </p>

      <h3 className={sectionTitleCN}>
        2. GENERAL PROVISIONS 
      </h3>
      <p className={paragraphCN}>
        2.1 The online store and sales environment (hereinafter the webapp) of the travel services provides such as services as passenger carriage, accommodation, insurance, rental and other travel services (hereinafter travel services) that have been made available at xxx by Heathmont Travel OÜ (registry code 14790371, VAT no. EE102203857, address: Suur-Patarei 13, Tallinn 10415, Estonia).
      </p>
      <p className={paragraphCN}>
        2.2 By accepting the terms of use of the webapp and purchases of a travel services, the Customer confirms that they consent to the terms of use of the webapp as well as the terms of provision of the travel service by airlines, accommodation providers, insurers and other travel service providers (hereinafter also service providers) as well as to other information made available to the Customer.
      </p>
      <p className={paragraphCN}>
        2.3 By entering data in the webapp, the Customer consents to sending their own personal data and other data required for the provision of the service, as well as any other persons whom they provide travel services to,  to service providers and third parties that are necessary for the provision of travel services.
      </p>
      <p className={paragraphCN}>
        2.4 Travel insurance is not offered as part of the order. The customers are responsible for obtaining adequate travel insurance including injury, loss and damage. Travel insurance is strongly recommended.
      </p>
      <p className={paragraphCN}>
        2.5 The customers are responsible for ensuring they have valid passports and any other necessary document (e.g. visas) organized duly by the time of the trip.
      </p>

      <h3 className={sectionTitleCN}>
        3. PURPOSE OF THE AGREEMENT
      </h3>
      <p className={paragraphCN}>
        3.1 Under this Agreement, the Customer has the right to submit Orders to Heathmont Travel under the conditions and procedures set forth herein. The Parties agree to determine the exact content, prices, and conditions of the Travel Services and/or Package Tours provided to the Customer in the Orders, Price Quotes, and Reservation Confirmations submitted by the Customer. 
      </p>
      <p className={paragraphCN}>
        3.2 The Customer undertakes to ensure that all Travel Services ordered under the Agreement are used solely for the Customer's economic and professional activities and that no Travel Service is used for personal purposes.
      </p>
      <p className={paragraphCN}>
        3.3 The Customer undertakes not to resell Travel Services to third parties. In case of a breach of this obligation, Heathmont Travel has the right to terminate the Agreement immediately. Heathmont Travel's services can be used as part of a package tour prepared by the Customer only if all such Heathmont Travel services are purchased as separate Travel Services under the Agreement.
      </p>

      <h3 className={sectionTitleCN}>
        4. ORDER SUBMISSION
      </h3>
      <p className={paragraphCN}>
        4.1 The Customer's Order is submitted through the designated website <a className={linkCN} href={`https://yolotravel.io/`} target="_blank">yolotravel.io</a> owned by Heathmont Travel or via email: <a className={linkCN} href={`mailto:travel@yolo.com`} target="_blank">travel@yolo.com</a>, additional contact number <a className={linkCN} href={`tel:+3725273005`} target="_blank">+372 527 3005</a>.
      </p>
      <p className={paragraphCN}>
        4.2 By submitting the Order, the Customer's contact person confirms that they have read the relevant Heathmont Travel travel conditions presented on the Heathmont Travel website and accepts them. 
      </p>
      <p className={paragraphCN}>
        In addition, the Customer's contact person confirms by submitting the Order that they have disclosed to Heathmont Travel all relevant information regarding the use of Travel Services or Package Tours, which are important for the provision of Travel Services. 
      </p>
      <p className={paragraphCN}>
        4.3 Ordering Travel Services may be restricted in terms of the time, location, availability, order, price, user identity, amenities, as well as reservations, changes, and cancellations. Submitting the Order does not guarantee the availability of Travel Services or create any obligation for Heathmont Travel to provide Travel Services.
      </p>
      <p className={paragraphCN}>
        4.4 Upon sending the Reservation Confirmation to the Customer, Heathmont Travel and the Customer are considered to have concluded a contract for the provision of Travel Services. If it has been agreed in the Customer Agreement that the Customer must pay in advance for Travel Services, the contract for the provision of Travel Services is considered concluded upon sending the Reservation Confirmation to the Customer after the proper payment of the Invoice.
      </p>
      <p className={paragraphCN}>
        4.5 After submitting the Order, the Customer can choose to pay via bank card or credit card, crypto payment via Swapin or if requested accordingly, Heathmont Travel will send an Invoice to the Customer for the provision of the Travel Services specified in the Order, which will be paid separately by the Customer. 
      </p>
      <p className={paragraphCN}>
        4.6 If the availability of Travel Services changes during the booking and sales process, and the desired Travel Service cannot be initially booked under the specified conditions, Heathmont Travel has the right to unilaterally cancel or modify the Price Quote by sending the Customer the corresponding notice along with a new Price Quote. In this case, Heathmont Travel will contact the Customer to enable them to place a new Order.
      </p>

      <h3 className={sectionTitleCN}>
        5. PAYMENT FOR THE ORDER
      </h3>
      <p className={paragraphCN}>
        5.1 Along with the Reservation Confirmation, Heathmont Travel may issue an Invoice for each Reservation, the amount of which must be paid by the Customer by the due date specified on the Invoice, taking into account the payment deadlines agreed in the Customer Agreement. If the Customer Agreement stipulates payment in advance, the Customer must pay the amount specified on the Invoice by the deadline indicated on the Invoice before the start of the provision of Travel Services. 
      </p>

      <h3 className={sectionTitleCN}>
        6. REFUNDS
      </h3>
      <p className={paragraphCN}>
        6.1 Refunds are paid out based on airfare rules or specific provider conditions. 
      </p>
      <p className={paragraphCN}>
        6.2 Refunds are processed under the request of the Customer within 30 working days. Refunds can be paid out in EUR, USDT or in the form of a credit invoice based on the payment details provided by the Customer. 
      </p>

      <h3 className={sectionTitleCN}>
        7. TERMINATION OF THE AGREEMENT
      </h3>
      <p className={paragraphCN}>
        7.1 Either Party may terminate the Agreement at any time without giving a reason, including if the Agreement has been concluded for an indefinite period in accordance with the Customer Agreement. In the case of regular termination, the Agreement will terminate 30 calendar days after the notice of termination is received by the other Party, with the obligations related to issued Reservations at the time of termination remaining in effect.
      </p>
      <p className={paragraphCN}>
        7.2 The Parties have the right to terminate this Agreement immediately for compelling reasons, by giving five calendar days' notice to the other Party. Compelling reasons include, among other things (but not limited to), the following situations:
      </p>
      <p className={paragraphCN}>
        7.2.1 Circumstances justifying the right to terminate the Agreement as provided in the Agreement;
      </p>
      <p className={paragraphCN}>
        7.2.2 Bankruptcy proceedings have been initiated against one of the Parties;
      </p>
      <p className={paragraphCN}>
        7.2.3 The Customer materially breaches or repeatedly (at least for the second time) violates this Agreement.
      </p>
      <p className={paragraphCN}>
        7.3 The termination of the Agreement does not release the Customer from financial obligations that have arisen under the Agreement before its termination.
      </p>

      <h3 className={sectionTitleCN}>
        8. CONFIDENTIALITY 
      </h3>
      <p className={paragraphCN}>
        8.1 The Parties undertake to keep confidential the terms of the Agreement and all information obtained during the negotiation of the Agreement or under the Agreement relating to the other Party, its business activities, and financial condition, including information provided in Orders and Booking Confirmations, prices, and price discounts.  Also considered confidential is any information that became known during the preparation and execution of the Agreement, which would not have been known to the Party without the conclusion of the Agreement. In case of ambiguity or dispute, it is presumed that the data and materials contain confidential information.
      </p>
      <p className={paragraphCN}>
        8.2 The Parties may use the aforementioned confidential information for the purpose of fulfilling the Agreement, including disclosure to their employees and contracting parties who need the information for the performance of the Agreement or as required by law. 
      </p>
      <p className={paragraphCN}>
        8.3 Confidential Information shall not be disclosed to third parties without the consent of the other Party, except when such disclosure is mandatory under applicable laws or when disclosing information to banks and professional consultants who are subject to confidentiality obligations.
      </p>
      <p className={paragraphCN}>
        8.4 The Customer’s Contact Person is not authorized to disclose any data of the Customer to any third party without the permission of Heathmont Travel.
      </p>

      <h3 className={sectionTitleCN}>
        9. PROCESSING OF PERSONAL DATA
      </h3>
      <p className={paragraphCN}>
        9.1 The Parties are obliged to process personal data only in accordance with the requirements set out in the law, including ensuring that personal data is processed in accordance with the General Data Protection Regulation (GDPR).
      </p>
      <p className={paragraphCN}>
        9.2 In the course of fulfilling the Agreement, Heathmont Travel processes personal data provided by the Customer. Heathmont Travel is the authorized processor of the Customer’s personal data in the performance of the Agreement, and the Customer is the responsible controller.
      </p>
      <p className={paragraphCN}>
        9.3 Heathmont Travel is entitled to process the Customer’s personal data only in accordance with the Agreement and the Customer’s written instructions.
      </p>
      <p className={paragraphCN}>
        9.4 The Parties ensure that they use means and processes for the processing of personal data in the performance of the Agreement that comply with common standards and provide modern and appropriate protection of personal data.
      </p>
      <p className={paragraphCN}>
        9.5 The Parties ensure that their employees and subcontractors are obligated to maintain the confidentiality of personal data processed during the performance of the Agreement.
      </p>
      <p className={paragraphCN}>
        9.6 If a Party uses subcontractors to fulfill the Agreement, the Party ensures that subcontractors comply with the terms of this Agreement on the protection of personal data. The Party is responsible for the actions of its subcontractors as if they were its own.
      </p>
      <p className={paragraphCN}>
        9.7 Upon receipt of inquiries related to the Customer's personal data, Heathmont Travel is obliged to forward them to the Customer. Upon the Customer's request, Heathmont Travel is obliged to assist the Customer in fulfilling inquiries related to the Customer's personal data to the extent that Heathmont Travel is the authorized processor of the Customer's personal data.
      </p>
      <p className={paragraphCN}>
        9.8 The Parties investigate all reasons for the breach of personal data processing and take appropriate measures to stop the breach, mitigate its consequences, and prevent similar breaches in the future.
      </p>
      <p className={paragraphCN}>
        9.9 The Customer is obliged to obtain the personal consent of the respective Travel Service user for the processing and transmission of their personal data to Heathmont Travel and Travel Service providers before submitting any Order. Consents must cover the entire scope of the ordered Travel Services. By placing an Order, Heathmont Travel assumes that the consents of the Travel Service users are in place.
      </p>
      <p className={paragraphCN}>
        9.10 Heathmont Travel Privacy Policy is available: <a className={linkCN} href={'/privacy-and-policy'} target="_blank">privacy and policy</a>
      </p>

      <h3 className={sectionTitleCN}>
        10. FINAL PROVISIONS
      </h3>
      <p className={paragraphCN}>
        10.1 The terms and conditions for Travel Services and Package Trips and the terms and conditions for the processing of personal data are available on the Heathmont Travel web app at <a className={linkCN} href={'/'} target="_blank">https://yolotravel.io</a>. In case of contradictions between the terms and conditions published on the website and the terms of the Agreement, the Parties shall be guided by the terms of the Agreement. 
      </p>
      <p className={paragraphCN}>
        10.2 Heathmont Travel is not liable for any damages arising from the failure to make a Booking based on a confirmed Price Quote, except when Heathmont Travel has issued a Booking Confirmation to the Customer.
      </p>
      <p className={paragraphCN}>
        10.3 Heathmont Travel has the right to unilaterally amend this Agreement, with 30 days' notice. If the Customer does not agree to the changes made by Heathmont Travel to the agreement, the Customer has the right to terminate the Agreement with immediate effect. Heathmont Travel has the right to unilaterally amend the terms and conditions for Travel Services and Package Trips published on the website without prior notice and in accordance with the procedure specified in the standard terms. The conditions valid at the time of booking shall apply to the Booking together with the terms of the Agreement.
      </p>
      <p className={paragraphCN}>
        10.4 The Customer agrees to submit claims that it is entitled to make under the Agreement or Booking to Heathmont Travel's representative in writing or in a form that allows for written reproduction to Heathmont Travel's representative's email address no later than 10 calendar days from the day the Customer became aware or should have become aware of the circumstances giving rise to the claim. A claim submitted after the deadline has expired and does not create legal consequences.
      </p>
      <p className={paragraphCN}>
        10.5 Delay in enforcing a right arising from or fulfilling an obligation under the agreement does not constitute a waiver of that right or obligation; the separate or partial enforcement of any right does not preclude its enforcement in other respects or the enforcement of any other right and/or obligation.
      </p>
      <p className={paragraphCN}>
        10.6 The invalidity of a particular provision of the Agreement does not release the Parties from the obligation to fulfill the other provisions of the Agreement and does not result in the invalidity of the entire agreement or the other provisions of the Agreement. In the event of the invalidity of a provision, the Parties agree to replace the provision in such a way that it fulfills the purpose of the invalid provision as much as possible.
      </p>
      <p className={paragraphCN}>
        10.7 Agreements to amend the Agreement are valid only if they are made in writing.
      </p>
      <p className={paragraphCN}>
        10.8 Neither Party may assign its rights and obligations under the Agreement to a third party without the prior written consent of the other Party.
      </p>
      <p className={paragraphCN}>
        10.9 The Agreement is governed by Estonian law.
      </p>
      <p className={paragraphCN}>
        10.10 Disputes arising from the Agreement shall be resolved through negotiations between the Parties. In the event of a failure to reach an agreement, the dispute shall be settled in Harju County Court.
      </p>
      <p className={paragraphCN}>
        10.11 By signing the Agreement, the Customer accepts the terms and conditions of Heathmont Travel for changing, canceling, and pricing Travel Services and Package Trips, both those currently in effect and those that may be introduced in the future.
      </p>
      <p className={paragraphCN}>
        10.12 According to the Regulations (EU) No 524/2013 of the European Parliament and of the Council all cross-border online transaction disputes should be resolved online at <a className={linkCN} href={'http://ec.europa.eu/consumers/odr/'} target="_blank">http://ec.europa.eu/consumers/odr/</a>.
      </p>

      <p className={paragraphCN}>
        Heathmont Travel OÜ -  Suur-Patarei 13, Tallinn 10415 Estonia, email: <a className={linkCN} href={`mailto:travel@yolo.com`} target="_blank">travel@yolo.com</a>
      </p>
    </div>
  );
};

export default TermsAndConditionsContent;
