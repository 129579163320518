import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFlightBookingPayment } from './types';
import FlightBookingTermsAndConditionsDialog from '../../components/FlightBookingTermsAndConditionsDialog';
import PageWrapper from '../../components/PageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import { useFlightOffersPriceConfirmation } from '../../hooks/useFlightOffersPriceConfirmation';
import FlightBookingStepsWrapper from '../../components/FlightBookingStepsWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import FlightBookingInfoView from '../../components/FlightBookingInfoView';
import FlightBookingInitialLoader from '../../components/FlightBookingInitialLoader';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import useDestinations from '../../hooks/useDestinations';
import PaymentFormWrapper from '../../components/PaymentFormWrapper';
import { BOOK_FLIGHT_LOADING_TEXTS } from '../../constants';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import BookingFlowConfirmationSettings from '../../components/BookingFlowConfirmationSettings';
import { handleBookFlight, isBookingHasLowCostOffers } from '../../utils/flights';
import { initPayment } from '../../store/flightBookings/utils';
import { Payment } from '../../enums/Payment';
import {
  isPaymentTypeSupportLowCostBookings,
  fetchUserPrevPaymentData,
  definePaymentType,
  isPaymentValid,
} from './utils';

const FlightBookingPayment: React.FC<IFlightBookingPayment> = ({
  isTermsAndConditionsOpen,
  transferRequired,
  selectedOffers = [],
  additionalBags,
  tripSettings,
  searchParams,
  amaClientRef,
  currentUser,
  passengers,
  payment,
  booking,
  setIsTermsAndConditionsOpen,
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  toggleTransferRequired,
  setPaymentPayerType,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { origin, destination, isLoading: destinationLoading } = useDestinations({ searchParams });
  const {
    hasAdditionalServicesUnpriceableWarning,
    isPriceConfirmationError,
    isSignificantPriceChange,
    includedBags,
    isLoading,
    price,
  } = useFlightOffersPriceConfirmation({
    additionalBags,
    tripPurposeId: tripSettings.tripPurposeId,
    amaClientRef,
    includeBags: true,
    booking,
    offers: selectedOffers,
  });
  const [bookingLoading, setBookingLoading] = useState<boolean>(false);
  const [bookingError, setBookingError] = useState<string | null>(null);
  const [isPaymentFormValid, setIsPaymentFormValid] = useState<boolean>(false);
  const [isPaymentFormLoading, setIsPaymentFormLoading] = useState<boolean>(false);
  const [agreeToStorePersonalData, setAgreeToStorePersonalData] = useState<boolean>(false);
  const isLowCostBooking = useMemo(() => isBookingHasLowCostOffers(selectedOffers), [selectedOffers]);
  const [isCryptoUnavailable, setIsCryptoUnavailable] = useState<boolean>(false);
  const [isUserPrevPaymentsLoading, setIsUserPrevPaymentsLoading] = useState<boolean>(true);

  const isOnlyOnePaymentAvailable = useMemo(() => {
    return tripSettings.paymentTypes.length === 1 && tripSettings.paymentTypes.includes(payment.paymentType);
  }, [tripSettings.paymentTypes, payment.paymentType]);

  const isPaymentAvailable = useMemo(() => {
    return tripSettings.paymentTypes.includes(payment.paymentType);
  }, [tripSettings.paymentTypes, payment.paymentType]);

  const handleAgreeTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(false);
    setAgreeToStorePersonalData(true);
  };

  const handleResetError = () => {
    setBookingLoading(false);
    setBookingError(null);
  };

  const handleBookFlightProceed = async () => {
    return await handleBookFlight({
      isLowCostBooking,
      transferRequired,
      additionalBags,
      tripSettings,
      includedBags,
      amaClientRef,
      currentUser,
      passengers,
      payment,
      offers: selectedOffers,
      setBookingLoading,
      setBookingError,
      navigate,
    });
  };

  const handleFetchUserPreviousPaymentData = async () => {
    try {
      setIsUserPrevPaymentsLoading(true);
    
      const isValid = await isPaymentValid(payment);

      if (!isValid) {
        const prevPayment: Payment | null = await fetchUserPrevPaymentData();

        if (prevPayment) {
          setPaymentPayerDetails(initPayment({
            currentUser,
            payment: {
              ...prevPayment,
              paymentType: definePaymentType(prevPayment, tripSettings.paymentTypes),
            },
          }));
        }
      }

      setIsUserPrevPaymentsLoading(false);
    } catch (err) {
      setIsUserPrevPaymentsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleFetchUserPreviousPaymentData();
    }
  }, [currentUser]);

  return (
    <PageWrapper isMobile={isMobile} page={'flight-booking-payment'}>
      <FlightBookingStepsWrapper
        hasAdditionalServicesUnpriceableWarning={hasAdditionalServicesUnpriceableWarning}
        isPriceConfirmationError={isPriceConfirmationError}
        isSignificantPriceChange={isSignificantPriceChange}
        paymentRequired={tripSettings.paymentRequired}
        bookingLoading={bookingLoading}
        isBookingError={!!bookingError}
        currentStep={FLIGHT_BOOKING_STEP.PAYMENT}
        isLoading={isLoading || destinationLoading}
        renderContinueAndBackButton={() => !bookingError && !bookingLoading && (
          <FlightBookingBackAndNext
            submitLabel='Confirm'
            withSearch={false}
            isLoading={isLoading || destinationLoading || bookingLoading || isPaymentFormLoading}
            disabled={(
              isPriceConfirmationError
              || !isPaymentFormValid
              || !agreeToStorePersonalData
              || !isPaymentAvailable
              || (isLowCostBooking && !isPaymentTypeSupportLowCostBookings(payment?.paymentType) && !isOnlyOnePaymentAvailable)
              || (payment?.paymentType === PAYMENT_TYPE.CRYPTO && isCryptoUnavailable)
            )}
            price={price} 
            step={FLIGHT_BOOKING_STEP.PAYMENT}
            proceedContinue={handleBookFlightProceed}
          />
        )}
      >
        {bookingError ? (
          <FlightBookingInfoView
            submitButtonLabel={'Back'}
            subTitle={bookingError}
            title={'The booking was unsuccessful'}
            onClick={handleResetError}
          />
        ) : (
          <>
            <FlightBookingSummary
              departureDate={searchParams.departureDate}
              destination={destination?.city}
              returnDate={searchParams.returnDate}
              isLoading={isLoading || destinationLoading}
              origin={origin?.city}
            />
            {bookingLoading ? (
              <FlightBookingInitialLoader small texts={BOOK_FLIGHT_LOADING_TEXTS} />
            ) : (
              <>
                <PaymentFormWrapper
                  availablePaymentTypes={tripSettings.paymentTypes}
                  isCryptoUnavailable={isCryptoUnavailable}
                  isLowCostBooking={isLowCostBooking}
                  currentUser={currentUser}
                  passengers={passengers}
                  isLoading={isLoading || destinationLoading || isUserPrevPaymentsLoading}
                  payment={payment}
                  handleSelectPaymentType={handleSelectPaymentType}
                  handleSelectPaymentCoin={handleSelectPaymentCoin}
                  setIsPaymentFormLoading={setIsPaymentFormLoading}
                  setIsCryptoUnavailable={setIsCryptoUnavailable}
                  setPaymentPayerDetails={setPaymentPayerDetails}
                  setIsPaymentFormValid={setIsPaymentFormValid}
                  setPaymentPayerType={setPaymentPayerType}
                />
                <BookingFlowConfirmationSettings
                  agreeToStorePersonalData={agreeToStorePersonalData}
                  transferRequired={transferRequired}
                  disabled={isLoading || destinationLoading || isUserPrevPaymentsLoading}
                  setAgreeToStorePersonalData={setAgreeToStorePersonalData}
                  setIsTermsAndConditionsOpen={setIsTermsAndConditionsOpen}
                  toggleTransferRequired={toggleTransferRequired}
                />
              </>
            )}
          </>
        )}
      </FlightBookingStepsWrapper>

      {isTermsAndConditionsOpen && (
        <FlightBookingTermsAndConditionsDialog
          onAgree={handleAgreeTermsAndConditions}
          onClose={() => setIsTermsAndConditionsOpen(false)}
        />
      )}
    </PageWrapper>
  );
};

export default FlightBookingPayment;
