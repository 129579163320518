import { connect } from 'react-redux';
import FlightBookingSuccessfulPayment from './FlightBookingSuccessfulPayment.ui';
import { resetToInitial } from '../../store/flightBookings/actions';
import {
  setIsMoreFareRulesModalOpen,
  setIsFeedbackFormOpen,
} from '../../store/dialogs/actions';

const mapDispatchToProps = {
  setIsMoreFareRulesModalOpen,
  setIsFeedbackFormOpen,
  resetToInitial,
};

export default connect(null, mapDispatchToProps)(FlightBookingSuccessfulPayment);
