import { Checkbox } from '@heathmont/moon-core-tw';
import { IBookingFlowConfirmationSettings } from './types';
import { checkboxLabelCN } from '../../styles/texts';
import TermsAndConditionsLink from '../TermsAndConditionsLink';

const BookingFlowConfirmationSettings: React.FC<IBookingFlowConfirmationSettings> = ({
  agreeToStorePersonalData,
  transferRequired,
  disabled,
  setAgreeToStorePersonalData,
  setIsTermsAndConditionsOpen,
  toggleTransferRequired,
}) => {
  const handleOpenTermsAndConditions = () => {
    setIsTermsAndConditionsOpen(true);
  };

  return (
    <div className='w-full flex flex-col mt-[18px] [&_input]:flex-shrink-0'>
      <Checkbox
        disabled={disabled}
        checked={transferRequired}
        label={(
          <span className={checkboxLabelCN}>
            Transfer from an airport is required. We will be in touch with you soon for more details
          </span>
        )}
        onChange={toggleTransferRequired}
      />
      <Checkbox
        disabled={disabled}
        checked={agreeToStorePersonalData}
        label={(
          <span className={checkboxLabelCN}>
            I give my consent to the use of my personal data and agree with <TermsAndConditionsLink openTermsAndConditions={handleOpenTermsAndConditions} />
          </span>
        )}
        onChange={() => setAgreeToStorePersonalData(!agreeToStorePersonalData)}
      />
    </div>
  );
};

export default BookingFlowConfirmationSettings;
