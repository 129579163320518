import { Navigate, Outlet } from 'react-router-dom';
import { IPublicRoute } from './types';

const PublicRoute: React.FC<IPublicRoute> = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
