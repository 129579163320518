import { connect } from 'react-redux';
import ChangeProfileForm from './ChangeProfileForm.ui';
import { updateCurrentUserProfile } from '../../store/auth/actions';

const mapStateToProps = ({
  authReducer,
}) => {
  return {
    currentUser: authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  updateCurrentUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfileForm);
