export enum ACCOUNT_TAB {
  PROFILE = '/account',
  DOCUMENT = '/account/document',
  SECURITY = '/account/security',
  BOOKINGS = '/account/bookings',
};

export enum ACCOUNT_TAB_LABEL {
  PROFILE = 'Profile',
  DOCUMENT = 'Document',
  SECURITY = 'Security',
  BOOKINGS = 'My bookings',
};

export const accountTabsOptions = [
  {
    value: ACCOUNT_TAB.PROFILE,
    label: ACCOUNT_TAB_LABEL.PROFILE,
  },
  {
    value: ACCOUNT_TAB.DOCUMENT,
    label: ACCOUNT_TAB_LABEL.DOCUMENT,
  },
  {
    value: ACCOUNT_TAB.SECURITY,
    label: ACCOUNT_TAB_LABEL.SECURITY,
  },
  {
    value: ACCOUNT_TAB.BOOKINGS,
    label: ACCOUNT_TAB_LABEL.BOOKINGS,
  },
];
