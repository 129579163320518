import pick from 'lodash/pick';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { PAYER_TYPE } from '../../enums/PayerTypes';
import { COIN } from '../../enums/Coins';
import { PAYER_DOCUMENT_TYPES } from '../../enums/PayerDocumentTypes';
import { PASSENGER_DOCUMENT_TYPES, PassengerDocumentType } from '../../enums/PassengerDocumentTypes';
import { CurrentUser } from '../../enums/CurrentUser';
import { PassengerDocument } from '../../enums/PassengerDocument';
import { extractCountryCodeAndNumber } from '../../utils/phone';

export const findOfferByItineraries = (group, itineraries) => {
  return group.flights.find(flight => {
    return flight.itineraries.every((itinerary, index) => {
      itinerary.id = itinerary.segments.map(segment => segment.id).join('_');
      return itinerary.id === itineraries[index].id
    });
  });
};

export const defineSelectedOffer = (group, selectedOrigin = null, selectedReturn = null) => {
  const selectedOriginItinerary = group?.originItineraries?.length
    ? (selectedOrigin || group?.originItineraries[0])
    : null;
  const selectedReturnItinerary = group?.returnItineraries?.length
    ? (selectedReturn || group?.returnItineraries[0])
    : null;
  const optimalItineraries = [selectedOriginItinerary, selectedReturnItinerary];
  const selectedOffer = findOfferByItineraries(group, optimalItineraries);

  return { selectedOriginItinerary, selectedReturnItinerary, selectedOffer };
};

export const setSelectedItineraries = (groups, params) => {
  const { selectedOriginItinerary, selectedReturnItinerary, groupIndex } = params;

  return groups.map((group, index) => {
    if (index !== groupIndex) {
      return group;
    }

    const selectedOrigin = group?.originItineraries?.length
        ? group.originItineraries.find(({ id }) => id === selectedOriginItinerary) 
        : null;
    const selectedReturn = group?.returnItineraries?.length
        ? group.returnItineraries.find(({ id }) => id === selectedReturnItinerary)
        : null;

    return {
      ...group,
      ...defineSelectedOffer(group, selectedOrigin, selectedReturn),
    };
  });
};

export const defineCheckedAirlines = (prevAirlines, newAirlines) => {
  const isAllChecked = prevAirlines.every(airline => airline.checked);
  const isAllUnchecked = prevAirlines.every(airline => !airline.checked);

  if (isAllChecked || isAllUnchecked) {
    return newAirlines.map(airline => ({ ...airline, checked: true }));
  }

  const checkedAirlines = prevAirlines.filter(airline => airline.checked)
  const isSomeCheckedExist = checkedAirlines.some(airline => {
    return newAirlines.some(newAirline => airline.code === newAirline.code);
  });

  if (isSomeCheckedExist) {
    return newAirlines.map(newAirline => ({
      ...newAirline,
      checked: checkedAirlines.some(checkedAirline => newAirline.code === checkedAirline.code),
    }));
  }

  return newAirlines.map(airline => ({ ...airline, checked: true })); 
};

export const definePayerEmail = ({
  shouldResetPayerData,
  currentUser,
  payment,
}: {
  shouldResetPayerData?: boolean,
  currentUser: CurrentUser,
  payment?: Payment,
}) => {
  if (payment?.payerEmail && !shouldResetPayerData) {
    return payment?.payerEmail;
  }

  if (payment.payerType === PAYER_TYPE.INDIVIDUAL && currentUser) {
    return currentUser?.email
  }

  return '';
};

export const definePayerName = ({
  shouldResetPayerData,
  currentUser,
  payment,
}: {
  shouldResetPayerData?: boolean,
  currentUser: CurrentUser,
  payment?: Payment,
}) => {
  if (payment?.payerName && !shouldResetPayerData) {
    return payment?.payerName;
  }

  if (payment.payerType === PAYER_TYPE.INDIVIDUAL && currentUser) {
    return `${currentUser.firstName} ${currentUser.lastName}`;
  }

  return '';
};

export const defineCoin = (payment?: Payment) => {
  return payment.coin || COIN.BTC;
};

export const definePayerCountry = ({
  currentUser,
  payment,
}: {
  currentUser: CurrentUser;
  payment?: Payment;
}) => {
  if (payment?.payerCountry) {
    const payerCountry = payment?.payerCountry as any;
    return typeof payerCountry === 'object'
      ? (payerCountry?.id || undefined)
      : (payerCountry || undefined);
  }

  if (currentUser?.document?.countryId) {
    return currentUser?.document?.countryId;
  }

  return undefined;
};

export const definePayerBirthCountry = ({
  currentUser,
  payment,
}: {
  currentUser: CurrentUser;
  payment?: Payment,
}) => {
  if (payment?.payerBirthCountry) {
    const payerBirthCountry = payment?.payerBirthCountry as any;
    return typeof payerBirthCountry === 'object'
      ? (payerBirthCountry?.id || undefined)
      : (payerBirthCountry || undefined);
  }

  if (currentUser?.document?.countryId) {
    return currentUser?.document?.countryId;
  }

  return undefined;
};

export const definePayerDocumentType = ({
  travelDocumentsTypes,
  currentUser,
  payment,
}: {
  travelDocumentsTypes: PassengerDocumentType[];
  currentUser: CurrentUser;
  payment?: Payment;
}) => {
  if (payment?.payerDocumentType) {
    return payment?.payerDocumentType;
  }

  const travelDocumentType = travelDocumentsTypes.find(type => (
    +type.value === +currentUser?.document?.typeId
  ));

  if (travelDocumentType?.keyword === PASSENGER_DOCUMENT_TYPES.IDENTITY_CARD) {
    return PAYER_DOCUMENT_TYPES.ID_CARD;
  }
  
  if (travelDocumentType?.keyword === PASSENGER_DOCUMENT_TYPES.PASSPORT) {
    return PAYER_DOCUMENT_TYPES.PASSPORT;
  } 

  return undefined;
};

export const initPayment = ({
  shouldResetPayerData = false,
  travelDocumentsTypes = [],
  currentUser,
  payment,
}: {
  shouldResetPayerData?: boolean;
  travelDocumentsTypes?: PassengerDocumentType[];
  currentUser: CurrentUser;
  payment?: Payment;
}): Payment => {
  const paymentDetails: Payment = {
    paymentType: payment.paymentType,
    coin: defineCoin(payment),
    payerType: payment.payerType,
    payerName: definePayerName({
      shouldResetPayerData,
      currentUser,
      payment,
    }),
    payerEmail: definePayerEmail({
      shouldResetPayerData,
      currentUser,
      payment,
    }),
    payerRegistrationNumber: payment?.payerRegistrationNumber || '',
    payerVatNumber: payment?.payerVatNumber || '',
    payerPostcode: payment?.payerPostcode || '',
    payerAddress: payment?.payerAddress || '',
    payerStateOrCounty: payment?.payerStateOrCounty || '',
    payerCountry: definePayerCountry({
      currentUser,
      payment,
    }),
    payerCity: payment?.payerCity || '',
    payerBirthCountry: definePayerBirthCountry({
      currentUser,
      payment,
    }),
    payerDocumentNumber: payment?.payerDocumentNumber  || currentUser?.document?.number || '',
    payerDocumentType: definePayerDocumentType({
      travelDocumentsTypes,
      currentUser,
      payment,
    }),
    payerBirthDate: payment?.payerBirthDate || currentUser?.birthday || '',
  };
  const requiredFields = [
    'paymentType',
    'payerType',
    'payerName',
    'payerEmail',
    'payerCountry',
    'payerCity',
    'payerPostcode',
    'payerAddress',
    'payerStateOrCounty',
  ];

  if (payment.paymentType === PAYMENT_TYPE.CRYPTO) {
    requiredFields.push('coin', 'payerBirthCountry', 'payerDocumentNumber', 'payerDocumentType', 'payerBirthDate');
  }

  if (payment.payerType === PAYER_TYPE.BUSINESS) {
    requiredFields.push('payerRegistrationNumber', 'payerVatNumber');
  }

  return pick(paymentDetails, requiredFields) as Payment;
};

export const getInitialPassengerDocument = (passengerDocument?: PassengerDocument, userDocument?: PassengerDocument): PassengerDocument => {
  return {
    typeId: passengerDocument?.typeId || userDocument?.typeId || undefined,
    number: passengerDocument?.number || userDocument?.number || '',
    validTill: passengerDocument?.validTill || userDocument?.validTill || '',
    countryId: passengerDocument?.countryId || userDocument?.countryId || undefined,
  };
};

export const getInitialPassengerEmail = ({
  passengerData,
  privateEmail,
  user,
}: {
  passengerData: Passenger;
  privateEmail: string | null;
  user: CurrentUser | null;
}) => {
  if (passengerData?.email) {
    return passengerData.email;
  }

  if (user && privateEmail) {
    return privateEmail;
  }

  if (user?.email) {
    return user.email;
  }

  return '';
};

export const initPassengers = ({
  numberOfTravelers,
  privateEmail, 
  currentUser,
  passengers = [],
}: {
  numberOfTravelers: number;
  privateEmail?: string;
  currentUser?: CurrentUser;
  passengers?: Passenger[];
}): Passenger[] => {
  return Array.from({ length: numberOfTravelers }).map((traveler, i) => {
    const passengerData = passengers?.length && passengers[i];
    const user = i === 0 ? currentUser : null;
    const phone = passengerData?.phone || user?.phone || '';
    const { phoneCountryCode, phoneNumber } = extractCountryCodeAndNumber(phone); 
    return {
      id: i + 1,
      phoneCountryCode,
      loyaltyNumber: {
        airlineId: passengerData?.loyaltyNumber?.airlineId || undefined,
        number: passengerData?.loyaltyNumber?.number || '',
      },
      phoneNumber,
      firstName: passengerData?.firstName || user?.firstName || '',
      lastName: passengerData?.lastName || user?.lastName || '',
      document: getInitialPassengerDocument(passengerData?.document, user?.document),
      birthday: passengerData?.birthday || user?.birthday || '',
      gender: passengerData?.gender || user?.gender || undefined,
      email: getInitialPassengerEmail({
        passengerData,
        privateEmail,
        user,
      }),
      phone,
    };
  });
};

export const definePriceRange = (prices = [], maxPrice = null) => {
  if (prices.length && prices[0] && prices[1]) {
    return prices;
  }

  return maxPrice ? [maxPrice, maxPrice] : [0, 0];
};

export const defineDefaultPaymentType = (availablePaymentTypes: PAYMENT_TYPE[]) => {
  const isCryptoAvailable = availablePaymentTypes.some(availablePaymentType => (
    availablePaymentType === PAYMENT_TYPE.CRYPTO
  ));

  if (isCryptoAvailable) {
    return PAYMENT_TYPE.CRYPTO;
  }

  const isCreditCardAvailable = availablePaymentTypes.some(availablePaymentType => (
    availablePaymentType === PAYMENT_TYPE.CARD
  ));

  if (isCreditCardAvailable) {
    return PAYMENT_TYPE.CARD;
  }

  return PAYMENT_TYPE.INVOICE;
};
