import { connect } from 'react-redux';
import SignUp from './SignUp.ui';
import { setIsContactFormOpen } from '../../store/dialogs/actions';

const mapStateToProps = ({
  dialogsReducer,
}) => {
  return {
    isContactFormOpen: dialogsReducer.isContactFormOpen,
  };
};

const mapDispatchToProps = {
  setIsContactFormOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
