import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { IFlightBookingList } from './types';
import {
  generateFlightOffersQuery,
  validateSearchParams,
  handleFetchOptions,
  parseSearchQuery,
} from './utils';
import request from '../../utils/request';
import { showToast } from '../../utils/common';
import FlightBookingAppliedFilters from '../../components/FlightBookingAppliedFilters';
import FlightBookingTimeSelector from '../../components/FlightBookingTimeSelector';
import FlightBookingAllFilters from '../../components/FlightBookingAllFilters';
import PageWrapper from '../../components/PageWrapper';
import FlightBookingBackAndNext from '../../components/FlightBookingBackAndNext';
import FlightBookingSearchForm from '../../components/FlightBookingSearchForm';
import FlightBookingSummary from '../../components/FlightBookingSummary';
import FlightBookingGroup from '../../components/FlightBookingGroup';
import { formatValueToSearchParams } from '../FlightBookingSearch/utils';
import useIsMobile from '../../hooks/useIsMobile';
import FlightBookingInitialLoader from '../../components/FlightBookingInitialLoader';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import { DestinationOption } from '../../enums/Destination';
import { SEARCH_FLIGHTS_LOADING_TEXTS } from '../../constants';

const FlightBookingList: React.FC<IFlightBookingList> = ({
  isArrivalDepartureTimeChangeModalOpen,
  isAllFiltersModalOpen,
  initialLoading,
  tripSettings,
  searchParams,
  amaClientRef,
  airlines,
  loading,
  filters,
  prices,
  sortBy,
  offset,
  groups,
  limit,
  total,
  page,
  setIsArrivalDepartureTimeChangeModalOpen,
  setIsAllFiltersModalOpen,
  setSelectedItineraries,
  setGroupsSuccess,
  setGroupsLoading,
  setSearchParams,
  setGroupsError,
  resetAirlines,
  resetFilter,
  selectOffer,
  setFilters,
  setSortBy,
  resetData,
  setPage,
}) => {
  const isMounted = useRef(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [origin, setOrigin] = useState<any | null>(null);
  const [originOptions, setOriginOptions] = useState<DestinationOption[]>([]);
  const [originOptionsLoading, setOriginOptionsLading] = useState<boolean>(false);
  const [destination, setDestination] = useState<any | null>(null);
  const [destinationOptions, setDestinationOptions] = useState<DestinationOption[]>([]);
  const [destinationOptionsLoading, setDestinationOptionsLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const [groupToChangeArrivalDepartureTime, setGroupToChangeArrivalDepartureTime] = useState(null);
  const [groupIndexToChangeArrivalDepartureTime, setGroupIndexToChangeArrivalDepartureTime] = useState(null);

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;

    if (!isBottom || loading || initialLoading || groups.length >= total) {
      return;
    }

    setPage(page + 1);
  };

  useEffect(() => {
    if (searchParams.origin) {
      handleFetchOptions(searchParams.origin as string, setOrigin, setOriginOptions, setOriginOptionsLading);
    }
  }, [searchParams.origin]);

  useEffect(() => {
    if (searchParams.destination) {
      handleFetchOptions(searchParams.destination as string, setDestination, setDestinationOptions, setDestinationOptionsLoading);
    }
  }, [searchParams.destination]);

  const searchFlights = async () => {
    const isSearchParamsValid = await validateSearchParams(searchParams);
  
    if (!isSearchParamsValid) {
      return;
    }
  
    try {
      setGroupsLoading();
      const params = generateFlightOffersQuery({
        tripPurposeId: tripSettings.tripPurposeId,
        searchParams,
        amaClientRef,
        airlines,
        filters,
        offset,
        sortBy,
        limit,
        page,
      });
      navigate({ pathname: location.pathname, search: qs.stringify(searchParams) });
      const { data } = await request.post('white-label/flights', { ...params });
      setGroupsSuccess(data);
    } catch (err) {
      setGroupsError(err.message);
      showToast(err.message);
    }
  };

  useEffect(() => {
    if (search) {
      const searchParams = parseSearchQuery(search);
      setSearchParams(searchParams, tripSettings, {
        shouldResetAirlines: false,
        shouldResetFilters: false,
        shouldResetSortBy: false,
      });
    }
  
    return () => {
      resetData();
    };  
  }, []);

  useEffect(() => {
    if (isMounted.current || !search) {
      searchFlights();
    } 
    
    if (!isMounted.current)  {
      isMounted.current = true;
    }
  }, [searchParams, filters, sortBy, page]);

  const handleSearch = (values) => {
    const params = formatValueToSearchParams(values);
    setSearchParams(params, tripSettings, {
      shouldResetAirlines: true,
      shouldResetFilters: false,
      shouldResetSortBy: false,
    });
  };

  const handleOpenArrivalDepartureTimeChangeModal = (group, groupIndex) => {
    setIsArrivalDepartureTimeChangeModalOpen(true);
    setGroupToChangeArrivalDepartureTime(group);
    setGroupIndexToChangeArrivalDepartureTime(groupIndex);
  };

  const handleCloseArrivalDepartureTimeChangeModal = () => {
    setIsArrivalDepartureTimeChangeModalOpen(false);
    setGroupToChangeArrivalDepartureTime(null);
    setGroupIndexToChangeArrivalDepartureTime(null);
  };

  const handleSubmitGroupSelectedItinerariesChange = (selectedItineraryOrigin, selectedItineraryReturn) => {
    handleCloseArrivalDepartureTimeChangeModal();
    setSelectedItineraries(groupIndexToChangeArrivalDepartureTime, selectedItineraryOrigin?.value, selectedItineraryReturn?.value);
  };

  const handleOpenAllFiltersModal = () => {
    setIsAllFiltersModalOpen(true);
  };

  const handleCloseAllFiltersModal = () => {
    setIsAllFiltersModalOpen(false);
  };

  const handleApplyFilters = ({ filters, airlines }) => {
    setFilters({ filters, airlines });
    handleCloseAllFiltersModal();
  };

  const handleSelectOffer = (groupIndex) => {
    selectOffer(groupIndex);
    navigate(`/flight-booking-overview${search}`);
  };

  return (
    <>
      <PageWrapper
        isMobile={isMobile}
        page={'flight-booking-list'}
        onScroll={handleScroll}
      >
        {
          isMobile ? (
            <FlightBookingSummary
              departureDate={searchParams.departureDate}
              destination={destination?.city}
              returnDate={searchParams.returnDate}
              isLoading={originOptionsLoading || destinationOptionsLoading}
              origin={origin?.city}
            />
          ) : (
            <FlightBookingSearchForm
              withPopularDestinations={false}
              destinationOptions={destinationOptions}
              originOptions={originOptions}
              searchParams={{ ...searchParams, destination, origin }}
              disabled={(loading || initialLoading)}
              onSubmit={handleSearch}
            />
          )
        }
        
        <div className='w-full mt-[60px] mb-[60px] flex flex-wrap gap-[30px] max-laptop:mt-[30px]'>
          {initialLoading ? (
            <FlightBookingInitialLoader texts={SEARCH_FLIGHTS_LOADING_TEXTS} />
          ) : (
            <>
              {amaClientRef && (
                <>
                  <h1 className='m-0 p-0 block font-bold text-[37px] leading-[45.94px] w-full max-laptop:hidden'>
                    Best departing flights
                  </h1>
                  <FlightBookingAppliedFilters
                    destination={destination}
                    disabled={(loading || initialLoading)}
                    airlines={airlines}
                    isMobile={isMobile}
                    filters={filters}
                    isRound={!!(searchParams?.returnDate)}
                    sortBy={sortBy}
                    origin={origin}
                    openAllFilters={handleOpenAllFiltersModal}
                    resetAirlines={resetAirlines}
                    resetFilter={resetFilter}
                    setSortBy={setSortBy}
                  />
                  <div className='flex flex-wrap w-full gap-[20px]'>
                    {!!groups.length && groups.map((group, index)=> (
                      <div className='w-full' key={index}>
                        <FlightBookingGroup
                          isOneWay={!(searchParams?.returnDate)}
                          group={group}
                          handleOpenArrivalDepartureTimeChangeModal={(group) => (
                            handleOpenArrivalDepartureTimeChangeModal(group, index)
                          )}
                          handleSelectOffer={() => handleSelectOffer(index)}
                        />
                      </div>
                    ))}
                    {loading && (
                      <>
                        {Array.from(Array(limit).keys()).map((index) => (
                          <FlightBookingGroup
                            isLoading
                            isOneWay={!(searchParams?.returnDate)}
                            key={index}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  {!groups.length && !loading && !initialLoading && (
                    <div className='w-full flex flex-col items-center justify-center gap-[10px] bg-goku rounded-[12px] px-[48px] py-[32px] max-tablet:p-[24px]'>
                      <h2 className='p-0 m-0 font-bold text-moon-32 text-center max-laptop:text-moon-18'>
                        There are no appropriate offers to the filters
                      </h2>
                      <h3 className='p-0 m-0 font-medium text-moon-24 text-center max-laptop:text-moon-14'>
                        Try changing some of your search details to see more results.
                      </h3>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          
        </div>
      </PageWrapper>
      {isMobile && (
        <FlightBookingBackAndNext
          isLoading={loading || initialLoading}
          disabled={false}
          step={FLIGHT_BOOKING_STEP.FLIGHT_LIST}
        />
      )}
      {
        isArrivalDepartureTimeChangeModalOpen && groupToChangeArrivalDepartureTime && (
          <FlightBookingTimeSelector
            destination={destination}
            origin={origin}
            group={groupToChangeArrivalDepartureTime}
            onChange={handleSubmitGroupSelectedItinerariesChange}
            onCancel={handleCloseArrivalDepartureTimeChangeModal}
          />
        )
      }
      {
        isAllFiltersModalOpen && (
          <FlightBookingAllFilters
            destination={destination}
            airlines={airlines}
            filters={filters}
            origin={origin}
            prices={prices}
            isRound={!!(searchParams?.returnDate)}
            onCancel={handleCloseAllFiltersModal}
            onSubmit={handleApplyFilters}
          />
        )
      }
    </>
  );
};

export default FlightBookingList;
