import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { InsetInput, Hint } from '@heathmont/moon-core-tw';
import AuthForm from '../AuthForm';
import InsetPassword from '../InsetPassword';
import request from '../../utils/request';
import { getInitialValues, resetPasswordSchema } from './schema';
import { useQuery } from '../../hooks/useQuery';
import AuthFormLinks from '../AuthFormLinks';
import { extractErrorFromResponse } from '../../utils/errors';

const ResetPasswordForm = () => {
  const { getQuery } = useQuery();
  const { code } = useMemo(() => getQuery(), []) as { code: string };
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [canRetry, setCanRetry] = useState<boolean>(true);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: resetPasswordSchema,
    validateOnBlur: true,
    initialValues: getInitialValues(code),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    if (!code) {
      setErrorMessage('The URL is inactive or invalid.');
      setCanRetry(false);
    }
  }, [code]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      await request.post('white-label/auth/reset-password', values);
      setSuccessMessage('Your password has been updated.');
    } catch (err) {
      setErrorMessage(extractErrorFromResponse(err));
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleTryAgain = () => {
    setErrorMessage(null);
  };

  return (
    <AuthForm
      submitButtonTitle='Reset my password'
      isLoading={isSubmitLoading}
      canRetry={canRetry}
      success={successMessage}
      error={errorMessage}
      title='Reset password'
      value={formik}
      handleTryAgain={handleTryAgain}
      renderAdditionalText={() => <AuthFormLinks />}
      renderSubTitle={() => (
        <>
          Enter new password below to change your password.
        </>
      )}
      renderFields={() => (
        <>
          <div className='w-full flex flex-col'>
            <InsetPassword
              component={InsetInput}
              label={'New password'}
              error={!!(errors?.newPassword && touched?.newPassword)}
              value={values.newPassword}
              name={'newPassword'}
              onChange={({ target: { value } }) => setFieldValue('newPassword', value)}
              onBlur={() => setFieldTouched('newPassword')}
            />
            {!!(errors?.newPassword && touched?.newPassword) && (
              <Hint error>{errors?.newPassword}</Hint>
            )}
          </div>
          <div className='w-full flex flex-col'>
            <InsetPassword
              component={InsetInput}
              label={'Confirm password'}
              error={!!(!errors?.newPassword && errors?.confirmPassword && touched?.confirmPassword)}
              value={values.confirmPassword}
              name={'confirmPassword'}
              onChange={({ target: { value } }) => setFieldValue('confirmPassword', value)}
              onBlur={() => setFieldTouched('confirmPassword')}
            />
            {!!(!errors?.newPassword && errors?.confirmPassword && touched?.confirmPassword) && (
              <Hint error>{errors?.confirmPassword}</Hint>
            )}
          </div>
        </>
      )}
      onSubmit={onSubmit}
    />
  );
};

export default ResetPasswordForm;

