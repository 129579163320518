import moment from 'moment';
import pick from 'lodash/pick';
import request from '../utils/request';
import { extractErrorFromResponse } from './errors';
import { showToast } from './common';
import { FlightBookingParams } from '../enums/FlightBookingParams';
import { CarrierType } from '../enums/CarrierType';
import { setAdditionalService } from './baggage';
import { Payment } from '../enums/Payment';
import { Passenger } from '../enums/Passenger';
import { handleRedirectToCheckoutPage } from './payments';
import { IncludedBagOptionValue } from '../enums/IncludedBag';
import { PAYMENT_TYPE } from '../enums/PaymentTypes';
import { TripSettings } from '../enums/TripSettings';
import { InternalPassenger } from '../enums/InternalPassenger';
import { CurrentUser } from '../enums/CurrentUser';
import { USER_STATUS } from '../enums/UserStatus';

export const getFlightEndDate = (flightOrders = []) => {
  const finishDates = [];

  flightOrders.forEach(flightOrder => {
    const lastItinerary = flightOrder.itineraries[flightOrder.itineraries.length - 1];
    const lastSegment = lastItinerary.segments[lastItinerary.segments.length - 1];
    finishDates.push(moment(lastSegment.arrival.at));
  });
  
  return moment.max(finishDates).toDate();
};

export const handleAcceptNewPriceAndContinue = async ({
  amaClientRef,
  offers,
  setIsLoading,
}: {
  amaClientRef: string;
  offers: any[];
  setIsLoading: (isLoading) => void;
}) => {
  try {
    setIsLoading(true);
    await request.post(`white-label/flights/offers/refresh/${amaClientRef}`, { offers });
    setIsLoading(false);
    window.location.reload();
  } catch (err) {
    setIsLoading(false);
    showToast(extractErrorFromResponse(err));
  }
};

export const confirmOffersPrice = async ({
  additionalBags,
  tripPurposeId,
  amaClientRef,
  offers,
}) => {
  try {
    const { data } = await request.post('white-label/flights/pricing', {
      tripPurposeId,
      amaClientRef,
      includeBags: false,
      offers: setAdditionalService(offers, additionalBags),
    });

    if (data?.success && data?.data?.length) {
      return data?.data;
    }

    return [];
  } catch (err) {
    return [];
  }
};

export const isPaymentHasCheckoutStep = (payment: PAYMENT_TYPE) => {
  const paymentsWithCheckoutStep = [
    PAYMENT_TYPE.CRYPTO,
    PAYMENT_TYPE.CARD, 
  ];
  return paymentsWithCheckoutStep.includes(payment);
};

const isExternalUser = (currentUser: CurrentUser) => (
  currentUser.status === USER_STATUS.EXTERNAL
);

export const bookFlight = async ({
  isLowCostBooking,
  paymentRequired,
  bookingParams,
  currentUser,
}: {
  isLowCostBooking: boolean;
  paymentRequired: boolean;
  bookingParams: FlightBookingParams;
  currentUser: CurrentUser;
}) => {
  if (isLowCostBooking && paymentRequired && isPaymentHasCheckoutStep(bookingParams.payment?.paymentType)) {
    return await request.post('white-label/flights/pre-payment', bookingParams);
  }

  if (paymentRequired) {
    return await request.post('white-label/flights/book', bookingParams);
  }

  if (!paymentRequired && !isExternalUser(currentUser)) {
    return await request.post('white-label/flights/book-business-trip', bookingParams);
  }

  return await request.post('white-label/flights/book-without-payment', bookingParams);
};

export const handleBookFlight = async ({
  isLowCostBooking,
  transferRequired,
  additionalBags,
  includedBags,
  amaClientRef,
  tripSettings,
  currentUser,
  passengers,
  payment,
  offers,
  setBookingLoading,
  setBookingError,
  navigate,
}: {
  transferRequired: boolean;
  isLowCostBooking: boolean;
  additionalBags: IncludedBagOptionValue[];
  includedBags: IncludedBagOptionValue[];
  tripSettings: TripSettings;
  amaClientRef: string;
  currentUser: CurrentUser;
  passengers: Passenger[] | InternalPassenger[];
  payment: Payment;
  offers: any[];
  setBookingLoading: (isLoading: boolean) => void;
  setBookingError: (error: string) => void;
  navigate: (path: string) => void;
}) => {
  try {
    setBookingLoading(true);
    setBookingError(null);

    const offersWithConfirmedPrice = await confirmOffersPrice({
      additionalBags,
      tripPurposeId: tripSettings.tripPurposeId,
      amaClientRef,
      offers,
    });

    if (!offersWithConfirmedPrice?.length ) {
      setBookingLoading(false);
      setBookingError('Offers are no longer available.');
      return;
    }

    const bookingParams: FlightBookingParams = {
      tripPurposeDetails: tripSettings.tripPurposeDetails,
      transferRequired,
      tripPurposeId: tripSettings.tripPurposeId,
      contactEmail: !tripSettings.useCompanyEmail && tripSettings.email ? tripSettings.email : null,
      includedBags,
      amaClientRef,
      travelers: passengers,
      fareRules: offers.map(({ fareRules }) => fareRules || null),
      payment,
      offers: offersWithConfirmedPrice,
    };

    const { data } = await bookFlight({
      isLowCostBooking,
      paymentRequired: tripSettings.paymentRequired,
      bookingParams,
      currentUser,
    });

    setBookingLoading(false);

    if (data?.success) {
      handleRedirectToCheckoutPage({
        paymentRequired: tripSettings.paymentRequired,
        amaClientRef,
        paymentType: payment?.paymentType,
        navigate,
      });
    } else {
      setBookingError(extractErrorFromResponse(data?.error));
    }
  } catch (err) {
    setBookingError(extractErrorFromResponse(err));
    setBookingLoading(false);
  }
};

export const isBookingHasLowCostOffers = (offers): boolean => {
  return offers.some(offer => offer.source === CarrierType.LOW_COST);
};
