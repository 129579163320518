import isEmpty from 'lodash/isEmpty';
import { btnSecondaryCN } from '../../styles/buttons';
import { IEditAccountFormActions } from './types';
import { Button } from '@heathmont/moon-core-tw';

const EditAccountFormActions: React.FC<IEditAccountFormActions> = ({
  isSubmitLoading,
  errors,
  dirty,
  discardChanges,
}) => {
  return (
    <div className='w-full flex gap-4 mt-2'>
      <Button
        className={btnSecondaryCN}
        fullWidth
        disabled={isSubmitLoading || !dirty}
        size='xl'
        type='button'
        onClick={discardChanges}
      >
        Discard changes
      </Button>
      <Button
        animation={isSubmitLoading ? 'progress' : undefined}
        fullWidth
        disabled={isSubmitLoading || !isEmpty(errors) || !dirty}
        size='xl'
        type='submit'
      >
        Save
      </Button>
    </div>
  );
};

export default EditAccountFormActions;
