import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';
import { fetchOrder } from './utils';
import useIsMobile from '../../hooks/useIsMobile';
import { CreditCardOrder } from '../../enums/CreditCardOrder';
import CreditCardPaymentCheckout from '../../components/CreditCardPaymentCheckout';
import { useQuery } from '../../hooks/useQuery';

const CardPaymentCheckout = () => {
  const navigate = useNavigate();
  const { getQuery } = useQuery();
  const { token } = useMemo(() => getQuery(), []) as { token: string };
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<CreditCardOrder | null>(null);
  const [paymentDetails, setPaymentDetails] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const [canRetry, setCanRetry] = useState<boolean>(false);
  
  const redirectToSuccessfulPayment = (): void => {
    navigate(`/${FLIGHT_BOOKING_STEP.SUCCESSFUL_PAYMENT}/?token=${token}`);
  };

  const redirectToAdditionalPaymentPage = () => {
    navigate(`/${FLIGHT_BOOKING_STEP.ADDITIONAL_PAYMENT}/?token=${token}`);
  };

  const handleRefreshTransaction = () => {
    setPaymentDetails(null);
    setPaymentError(false);
  };

  const handleFetchOrder = () => {
    return fetchOrder({
      token,
      redirectToSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setIsLoading,
      setCanRetry,
      setOrder,
    });
  };

  useEffect(() => {
    handleFetchOrder();
  }, []);

  return (
    <PageWrapper
      isMobile={isMobile}
      page={'card-payment-checkout'}
    >
      <CreditCardPaymentCheckout
        isRefreshLoading={false}
        paymentDetails={paymentDetails}
        paymentError={paymentError}
        canRetry={canRetry}
        isLoading={isLoading}
        order={order}
        onPaymentMethodChange={redirectToAdditionalPaymentPage}
        setPaymentDetails={setPaymentDetails}
        setPaymentError={setPaymentError}
        setCanRetry={setCanRetry}
        onRefresh={handleRefreshTransaction}
        onSuccess={() => handleFetchOrder()}
      />
    </PageWrapper>
  );
};

export default CardPaymentCheckout;
