import * as Yup from 'yup';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { isValidPhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';

export const isStringFreeFromForbiddenChars = (value) => {
  const hasCyrillic = /.*[\u0400-\u04FF].*|.*[\u0500-\u052F].*/i.test(value);
  const hasPolish = /.*[ąćęłńóśźż].*/i.test(value);
  const hasArabic = /.*[\u0621-\u064A].*/i.test(value);
  const hasChineseKoreanJapanese = /.*[一-龠].*|.*[ぁ-ゔ].*|.*[ァ-ヴー].*|.*[々〆〤ヶ].*|.*[\uAC00-\uD7AF].*/u.test(value);

  return ![hasPolish, hasArabic, hasCyrillic, hasChineseKoreanJapanese]
    .some((charCheck) => charCheck);
};

export const isPassengerNameValid = (value = '') => {
  return /^[a-z -]*$/i.test(value);
}

export const isEmailContainsOnlyLatinCharacters = (value = '') => {
  return /^[a-z0-9!#@$%&'*\-+/=?^_`{|}~.]*$/i.test(value);
};

Yup.addMethod(Yup.string, 'disallowedSymbols', function (disallowedSymbols: string[], message?: string) {
  const msg = message || `String cannot contain: ${disallowedSymbols.map(symbol => `"${symbol}"`).join(', ')}`;
  return this.test('is-without-disallowed-symbols', msg, (value) => {
    if (!value) {
      return true;
    }
  
    return disallowedSymbols.every(symbol => !value.includes(symbol));
  });
});

Yup.addMethod(Yup.string, 'isInLatinCharacters', function (message?: string) {
  const msg = message || 'Only Latin characters are allowed';
  return this.test('is-in-latin-characters', msg, isStringFreeFromForbiddenChars);
});

Yup.addMethod(Yup.string, 'isEmailInLatinCharacters', function (message?: string) {
  const msg = message || 'Only Latin characters are allowed';
  return this.test('is-email-in-latin-characters', msg, isEmailContainsOnlyLatinCharacters);
});

Yup.addMethod(Yup.string, 'isValidPhoneNumber', function (message?: string) {
  const msg = message || 'Phone is invalid';
  return this.test('is-valid-phone-number', msg, (value = '') => isValidPhoneNumber(value));
});

Yup.addMethod(Yup.string, 'withoutSpaces', function (message?: string) {
  const msg = message || 'Field cannot start or end with an empty symbol';
  return this.test('has-spaces', msg, (value = '') => {
    return value[0] !== ' ' && value[value.length - 1] !== ' ';
  });
});

Yup.addMethod(Yup.string, 'isSameOrAfter', function (dateToCompare: Date, message?: string) {
  const msg = message || '';
  return this.test('is-same-or-after', msg, (value) => !moment(dateToCompare).isSameOrAfter(value));
});

Yup.addMethod(Yup.string, 'isSameOrBefore', function (dateToCompare: Date, message?: string) {
  const msg = message || '';
  return this.test('is-same-or-before', msg, (value) => !moment(dateToCompare).isSameOrBefore(value));
});

Yup.addMethod(Yup.string, 'isAfter', function (dateToCompare: Date, message?: string) {
  const msg = message || '';
  return this.test('is-after', msg, (value) => !moment(dateToCompare).isAfter(value));
});

Yup.addMethod(Yup.string, 'isBefore', function (dateToCompare: Date, message?: string) {
  const msg = message || '';
  return this.test('is-before', msg, (value) => !moment(dateToCompare).isBefore(value));
});

Yup.addMethod(Yup.array, 'uniqBy', function (fields, getMessage?: (field) => string) {
  const msg = 'Must by uniq';
  return this.test('has-no-duplicates-by-field', msg, (items = [], { createError }) => {
    const notUniqFields = [];

    fields.forEach(field => {
      const notEmptyItems = items.filter(item => !!_.get(item, field.name));
    
      if (uniqBy(notEmptyItems, field.name).length !== notEmptyItems.length) {
        notUniqFields.push(field.label);
      }
    });

    if (!notUniqFields.length) {
      return true;
    }

    return getMessage 
      ? createError({ message: getMessage(notUniqFields[0]) })
      : false;
  });
});

Yup.addMethod(Yup.array, 'someItemHaveToBeChecked', function (field: string, message: string) {
  return this.test('some-item-have-to-be-checked', message, (items = [], { createError }) => {  
    if (items.some(item => !!_.get(item, field))) {
      return true;
    }
    return message ? createError({ message }) : false;
  });
});

export const checkRequiredIsProvided = (dependOnFiled: string) => (value: string | number, context) => {
  if (!!_.get(context.parent, dependOnFiled, null)) {
    return !!value;
  }

  return true;
}; 

Yup.addMethod(Yup.string, 'requiredIfProvided', function (dependOnFiled: string, message: string) {
  return this.test('required-if-provided', message, checkRequiredIsProvided(dependOnFiled));
});

Yup.addMethod(Yup.string, 'validName', function (message: string) {
  return this.test('name-is-valid', message, (value = '') => isPassengerNameValid(value));
});

Yup.addMethod(Yup.number, 'requiredIfProvided', function (dependOnFiled: string, message: string) {
  return this.test('required-if-provided', message, checkRequiredIsProvided(dependOnFiled));
});

Yup.addMethod(Yup.string, 'includeUpperCase', function (message: string) {
  return this.test('include-upper-case', message, (value = '') => {
    return /[A-Z]/.test(value);
  });
});

Yup.addMethod(Yup.string, 'includeLowerCase', function (message: string) {
  return this.test('include-lower-case', message, (value = '') => {
    return /[a-z]/.test(value);
  });
});

Yup.addMethod(Yup.string, 'includeDigit', function (message: string) {
  return this.test('include-digit', message, (value = '') => {
    return /\d/.test(value);
  });
});

Yup.addMethod(Yup.string, 'includeSpecialSymbol', function (message: string) {
  return this.test('include-special-symbol', message, (value = '') => {
    return /(?=.*[!#$%&()*+\-\?\~@=])/.test(value);
  });
});

export default Yup;
