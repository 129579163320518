export const FLIGHT_SOURCE = {
  GDS: 'GDS',
  PYTON: 'PYTON',
};

export const STATUS_CODE = {
  USER_UNAUTHORIZED: 401,
};

export const SCREENS = {
  MOBILE: 500,
  TABLET: 768,
  LAPTOP: 1024,
};

export const MIN_NUMBER_PASSENGERS = 1;

export const MAX_NUMBER_PASSENGERS = 6;

export const SIGNIFICANT_PRICE_CHANGE = 50;

export const SIGNIFICANT_PRICE_CHANGE_PERCENT = 10;

export const PAYMENT_FEE_PERCENT = {
  SWAPIN: { MIN: 1, MAX: 2 },
};

export const SEARCH_FLIGHTS_LOADING_TEXTS = [
  'While we wait for your results to arrive',
  'Imagine yourself wandering through the vibrant city streets',
  'Gazing out over the sea',
  'Where the horizon meets endless possibilities',
  'Your search is coming your way',
];

export const BOOK_FLIGHT_LOADING_TEXTS = [
  'While your payment is processing',
  'We are making final touches to your booking',
  'Your transaction is receiving star treatment',
  'Ensuring a smooth and secure checkout process',
];

export const POPULAR_DESTINATIONS = [
  {
    code: 'DXB',
    city: 'Dubai',
    country: 'United Arab Emirates',
  },
  {
    code: 'LON',
    city: 'London',
    country: 'United Kingdom',
  },
  {
    code: 'TLL',
    city: 'Tallinn',
    event: 'Yolo Partners Event',
    country: 'Estonia',
  },
];
