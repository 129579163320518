import { CreditCardOrder } from '../../enums/CreditCardOrder';
import request from '../../utils/request';
import { isPaymentStuck } from '../../utils/payments';
import { extractPaymentError } from '../../utils/errors';

export const fetchOrder = async ({
  token,
  redirectToSuccessfulPayment,
  setPaymentDetails,
  setPaymentError,
  setIsLoading,
  setCanRetry,
  setOrder,
}: {
  token: string,
  redirectToSuccessfulPayment: () => void; 
  setPaymentDetails: (details: string) => void,
  setPaymentError: (error: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
  setCanRetry: (canRetry: boolean) => void; 
  setOrder: (order: CreditCardOrder) => void;
}) => {
  setIsLoading(true);

  try {
    const { data } = await request.get('white-label/payments/additional/status', {
      params: {
        token,
      }
    });

    if (data?.confirmed) {
      return redirectToSuccessfulPayment();
    }

    if (isPaymentStuck(data?.transaction)) {
      setCanRetry(true);
      setPaymentError(true);
      setPaymentDetails('Your payment is stuck while processing');
    } else {
      setPaymentDetails(data?.details);
      setPaymentError(data?.error);
      setCanRetry(data?.canRetry);
    }

    setOrder(data?.transaction);
    setIsLoading(false);
  } catch (err) {
    const { errorMessage, canRetry } = extractPaymentError(err);
    setPaymentDetails(errorMessage);
    setPaymentError(true);
    setCanRetry(canRetry);
    setIsLoading(false);
  }
};
