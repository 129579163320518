import { useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { InsetInput, Hint, Textarea, Checkbox, InsetNativeSelect } from '@heathmont/moon-core-tw';
import isEmpty from 'lodash/isEmpty';
import { IFlightBookingContactForm } from './types';
import Modal from '../Modal';
import {
  fieldWrapperCN,
  textCN,
} from './styles';
import { getInitialValues, initContactFormSchema } from './schema';
import SelectOptions from '../SelectOptions';
import { USER_STATUS } from '../../enums/UserStatus';
import { GenericAlarm } from '@heathmont/moon-icons-tw';
import { checkboxLabelCN } from '../../styles/texts';
import { TripPurpose } from '../../enums/TripPurpose';
import { handleFetchUserTripPurposes } from './utils';

const TripPurposeSelectionDialog: React.FC<IFlightBookingContactForm> = ({
  currentUser,
  onSubmit,
  onClose,
}) => { 
  const [tripPurposes, setTripPurposes] = useState<TripPurpose[]>([]);
  const [isTripPurposesLoading, setIsTripPurposesLoading] = useState<boolean>(true);
  const formik = useFormik({
    validationSchema: initContactFormSchema(currentUser),
    validateOnBlur: true,
    initialValues: getInitialValues(currentUser),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setValues, setFieldTouched, validateForm } = formik; 

  useEffect(() => {
    handleFetchUserTripPurposes({
      setIsTripPurposesLoading,
      setTripPurposes,
    });
    validateForm();
  }, []);

  useEffect(() => {
    const email = values.useCompanyEmail ? currentUser.email : '';
    setFieldValue('email', email);
  }, [values.useCompanyEmail]);

  useEffect(() => {
    if (values.tripPurposeId) {
      const tripPurpose = tripPurposes.find(tripPurpose => +tripPurpose.id === +values.tripPurposeId);
      if (tripPurpose) {
        setValues({
          ...values,
          useCompanyEmail: !tripPurpose.paymentRequired,
          paymentRequired: !!(tripPurpose.paymentRequired),
          paymentTypes: tripPurpose.paymentTypes,
        });
      }
    }
  }, [values.tripPurposeId]);

  return (
    <Modal
      submitButtonLabel="Search"
      cancelButtonLabel="Cancel"
      isLoading={isTripPurposesLoading || !isEmpty(errors)}
      title="Trip purpose"
      onSubmit={() => onSubmit(values)}
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <div className='w-full flex flex-col gap-[20px]'>
          <div className='w-full flex flex-col gap-[10px]'>
            <p className={textCN}>Please, choose the reason of your trip.</p>
          </div>

          <div className='w-full flex flex-col gap-[16px]'>          
            <div className={fieldWrapperCN}>
              <InsetNativeSelect
                className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
                autoFocus
                error={!!(errors?.tripPurposeId && touched?.tripPurposeId)}
                value={values?.tripPurposeId || ''}
                label={'Trip purpose'}
                name={'tripPurpose'}
                onChange={({ target: { value } }) => setFieldValue('tripPurposeId', value)}
                onBlur={() => setFieldTouched('tripPurposeId')}
              >
                <SelectOptions
                  options={tripPurposes.map(tripPurpose => ({ ...tripPurpose, value: tripPurpose.id }))}
                  label='trip purpose'
                />
              </InsetNativeSelect>

              {!!(errors?.tripPurposeId && touched?.tripPurposeId) && (
                <Hint error>{errors?.tripPurposeId}</Hint>
              )}
            </div>

            <div className={fieldWrapperCN}>
              <Textarea
                placeholder='Please, describe trip purpose details: Conference name, training details, etc.'
                className="rounded-moon-i-md [&_textarea]:rounded-moon-i-md resize-y"
                error={!!(errors?.tripPurposeDetails && touched?.tripPurposeDetails)}
                value={values.tripPurposeDetails}
                rows={3}
                onChange={({ target: { value } }) => setFieldValue('tripPurposeDetails', value)}
                onBlur={() => setFieldTouched('tripPurposeDetails')}
              />

              {!!(errors?.tripPurposeDetails && touched?.tripPurposeDetails) && (
                <Hint error>{errors?.tripPurposeDetails}</Hint>
              )}
            </div>

            {currentUser.status === USER_STATUS.INTERNAL && values?.paymentRequired && (
              <>
                <div className='flex items-center gap-2 text-trunks'>
                  <GenericAlarm className='w-5 h-5 flex-shrink-0' />
                  <p className='text-moon-14 font-normal max-mobile:text-moon-12'>
                    Private trips are paid by user. Please, provide your private email to receive messages related to this booking.
                  </p>
                </div>

                <div className={fieldWrapperCN}>
                  <InsetInput
                    placeholder={' '}
                    className={'rounded-moon-i-md [&_input]:rounded-moon-i-md'}
                    disabled={values.useCompanyEmail}
                    value={values.email}
                    error={!!(errors?.email && touched?.email)}
                    name='email'
                    type='text'
                    id='email'
                    onChange={({ target: { value } }) => setFieldValue('email', value)}
                    onBlur={() => setFieldTouched('email')}
                  >
                    <InsetInput.Label>Email</InsetInput.Label>
                  </InsetInput>

                  {!!(errors?.email && touched?.email) && (
                    <Hint error>{errors?.email}</Hint>
                  )}
                </div>
                <div className={fieldWrapperCN}>
                  <Checkbox
                    checked={values.useCompanyEmail}
                    label={<span className={checkboxLabelCN}>Use my company's email</span>}
                    onChange={(e) => setFieldValue('useCompanyEmail', e.target.checked)}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </FormikProvider>
    </Modal>
  );
};

export default TripPurposeSelectionDialog;
