import { useNavigate } from 'react-router-dom';
import { IHeader } from './types';
import { logoCN, logoWrapperCN } from './styles';
import YoloTravelLogo from '../YoloTravelLogo';
import HeaderAuth from '../HeaderAuth';

const Header: React.FC<IHeader> = ({
  isAuthenticated,
  currentUser,
  lightLogo,
  lightAuth,
  isMobile,
  authPage,
}) => {
  const navigate = useNavigate();

  return (
    <div className={logoWrapperCN}>
      <div className={logoCN} onClick={() => navigate('/')}>
        <YoloTravelLogo extended={!isMobile} light={lightLogo} />
      </div>
      {!authPage && (
        <HeaderAuth
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
          light={lightAuth}
        />
      )}
    </div>
  );
};

export default Header;
