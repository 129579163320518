import { connect } from 'react-redux';
import FlightBookingSearch from './FlightBookingSearch.ui';
import { setSearchParams } from '../../store/flightBookings/actions';
import {
  setIsTripPurposeSelectionOpen,
  setIsContactFormOpen,
} from '../../store/dialogs/actions';
import { setTripSettings } from '../../store/trip/actions';

const mapStateToProps = ({
  flightBookingsReducer,
  dialogsReducer,
  authReducer,
}) => {
  return {
    isTripPurposeSelectionOpen: dialogsReducer.isTripPurposeSelectionOpen,
    isContactFormOpen: dialogsReducer.isContactFormOpen,
    searchParams: flightBookingsReducer.search,
    currentUser: authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  setIsTripPurposeSelectionOpen,
  setIsContactFormOpen,
  setSearchParams,
  setTripSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingSearch);
