import { useMemo, Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TimeClock, GenericAlarm } from '@heathmont/moon-icons-tw';
import { IFlightBookingSummary } from './types';
import { formatDate } from './utils';
import {
  summaryDetailsCN,
  labelSeparatorCN,
  summaryWrapperCN,
  summaryTitleCN,
  headerCN,
  labelCN,
} from './styles';
import { ReactComponent as RoundFlightSeparatorSvg } from '../../static/icons/round-flight-separator.svg';
import { ReactComponent as OneWayFlightSeparatorSvg } from '../../static/icons/one-way-flight-separator.svg';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { textEllipsisCN } from '../../styles/texts';
import useIsMobile from '../../hooks/useIsMobile';

const FlightBookingSummary: React.FC<IFlightBookingSummary> = ({
  isBookingOverview = false,
  overnightLayover = false,
  departureDate,
  destination,
  withShadow = true,
  withBorder = false,
  returnDate,
  isLoading = false,
  duration,
  origin,
  stops,
}) => {
  const isMobile = useIsMobile();

  const labels = useMemo(() => {
    const items: any[] = [{ text: formatDate(departureDate, { shortVersion: isBookingOverview && isMobile }) }];

    if (returnDate) {
      items.push({ text: formatDate(returnDate, { shortVersion: isBookingOverview && isMobile }) }); 
    }

    if (duration) {
      items.push({
        text: duration,
        icon: <TimeClock fontSize={'1.2rem'}/>
      });
    }

    if (stops) {
      const stopsLabel = stops.length ? `${stops.length} stop${stops.length > 1 ? 's' : ''}` : 'Non-stop'
      items.push({ text: stopsLabel }); 
    }

    if (overnightLayover) {
      items.push({
        text: 'Overnight layover',
        icon: <GenericAlarm fontSize={'1.2rem'} style={{ marginRight: '2px', color: '#FF2E2E', }} />
      });
    }

    return items;
  }, [departureDate, returnDate, duration, stops, isBookingOverview, isMobile]);

  return (
    <div className={summaryWrapperCN({ withShadow, withBorder })}>
      <div className={headerCN}>
        {isLoading ? (
          <div className={summaryTitleCN}>
            <Skeleton style={{ height: '100%', width: '200px' }} inline />
          </div>
        ) : (
          <>
            <div className={summaryTitleCN}>
              {origin}
            </div>
            {returnDate ? (
              <RoundFlightSeparatorSvg />
            ) : (
              <OneWayFlightSeparatorSvg />
            )}
            <div className={summaryTitleCN}>
              {destination}
            </div>
          </>
        )}
      </div>
      <div className={summaryDetailsCN}>
        {labels.map((label, index) => {
          const isLast = labels.length === index + 1;
          return (
            <Fragment key={index}>
              <div className={isLast ? labelCN : mergeClassnames('flex-shrink-0', labelCN)}>
                {isLoading ? (
                  <Skeleton style={{ height: '100%', width: '80px' }} inline />
                ) : (
                  <>
                    {label.icon && label.icon}
                    <span className={isLast ? mergeClassnames('inline-block', textEllipsisCN) : ''}>
                      {label.text}
                    </span>
                  </>
                )}
              </div>
              {index !== labels.length - 1 && <div className={labelSeparatorCN}>·</div>}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default FlightBookingSummary;
