import countryCodes from 'country-codes-list';
import { AsYouType } from 'libphonenumber-js'
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';
import { ISelectOption } from '../components/SelectOptions/types';

export const formatPhone = (phoneCountryCode?: string | null, phoneNumber?: string | null): string => {
  let phone = '';
  
  if (phoneCountryCode) {
    phone += phoneCountryCode;
  }

  if (phoneNumber) {
    phone += phoneNumber;
  }

  return phone;
};

export const countyCodes = orderBy(
  countryCodes.customArray({
    label: '{countryNameEn} (+{countryCallingCode})',
    value: '+{countryCallingCode}',
  }),
  (countryCode) => countryCode.label
    .replace('Å', 'A')
    .replace('ô', 'o')
    .replace('ç', 'c')
    .replace('é', 'e'), 
  ['asc'],
) as unknown as ISelectOption[];

export const extractCountryCodeAndNumber = (phone: string): { phoneCountryCode: string, phoneNumber: string } => {
  const formatted = new AsYouType().input(phone);
  const parts = formatted.split( ' ' );
  const phoneCountryCode = parts.length > 1 ? parts.shift() : '';
  const phoneNumber = parts.join('');
  return {
    phoneCountryCode,
    phoneNumber,
  };
};

export const omitHelperPhoneFields = (values) => {
  return omit(values, [
    'phoneCountryCode',
    'phoneNumber',
  ]);
};
