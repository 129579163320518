import { IncludedBagOptionValue } from '../../enums/IncludedBag';
import { PassengerDocumentType } from '../../enums/PassengerDocumentTypes';
import { FlightSearchParams } from '../../enums/FlightSearchParams';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { TripSettings } from '../../enums/TripSettings';
import { PAYER_TYPE } from '../../enums/PayerTypes';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import {
  SET_IS_ARRIVAL_DEPARTURE_TIME_CHANGE_MODAL_OPEN,
  SET_IS_ALL_FILTERS_MODAL_OPEN,
  SET_PAYMENT_PAYER_DETAILS,
  SET_SELECTED_ITINERARIES,
  TOGGLE_TRANSFER_REQUIRED,
  SET_PAYMENT_PAYER_TYPE,
  UPDATE_ADDITIONAL_BAGS,
  SELECT_PAYMENT_COIN,
  SELECT_PAYMENT_TYPE,
  SET_GROUPS_LOADING,
  SET_GROUPS_SUCCESS,
  SET_SEARCH_PARAMS,
  UPDATE_PASSENGERS,
  SET_GROUPS_ERROR,
  RESET_TO_INITIAL,
  RESET_AIRLINES,
  RESET_FILTER,
  SELECT_OFFER,
  SET_SORT_BY,
  SET_FILTERS,
  RESET_DATA,
  SET_PAGE,
} from './actionTypes';
import { FlightFilterResetSettings } from '../../enums/FlightFilterResetSettings';

export const setPaymentPayerDetails = (payment: Payment) => ({
  payload: payment,
  type: SET_PAYMENT_PAYER_DETAILS,
});

export const setPaymentPayerType = (payerType: PAYER_TYPE, travelDocumentsTypes: PassengerDocumentType[]) => (dispatch, getState) => {
  const { authReducer } = getState();
  dispatch({
    payload: {
      travelDocumentsTypes,
      currentUser: authReducer.currentUser,
      payerType,
    },
    type: SET_PAYMENT_PAYER_TYPE,
  });
};

export const handleSelectPaymentCoin = (coin: string, travelDocumentsTypes: PassengerDocumentType[]) => (dispatch, getState) => {
  const { authReducer } = getState();
  dispatch({
    payload: {
      travelDocumentsTypes,
      currentUser: authReducer.currentUser,
      coin,
    },
    type: SELECT_PAYMENT_COIN,
  });
};

export const handleSelectPaymentType = (paymentType: PAYMENT_TYPE, travelDocumentsTypes: PassengerDocumentType[]) => (dispatch, getState) => {
  const { authReducer } = getState();
  dispatch({
    payload: {
      travelDocumentsTypes,
      paymentType,
      currentUser: authReducer.currentUser,
    },
    type: SELECT_PAYMENT_TYPE,
  });
};

export const resetToInitial = () => ({
  type: RESET_TO_INITIAL,
});

export const updateAdditionalBags = (additionalBags: IncludedBagOptionValue[]) => ({
  payload: {
    additionalBags,
  },
  type: UPDATE_ADDITIONAL_BAGS,
});

export const updatePassengers = (passengers: Passenger[]) => ({
  payload: {
    passengers,
  },
  type: UPDATE_PASSENGERS,
});

export const toggleTransferRequired = () => ({
  type: TOGGLE_TRANSFER_REQUIRED,
});

export const setIsArrivalDepartureTimeChangeModalOpen = (isOpen) => ({
  payload: isOpen,
  type: SET_IS_ARRIVAL_DEPARTURE_TIME_CHANGE_MODAL_OPEN,
});

export const setIsAllFiltersModalOpen = (isOpen) => ({
  payload: isOpen,
  type: SET_IS_ALL_FILTERS_MODAL_OPEN,
});

export const resetData = () => ({
  type: RESET_DATA,
});

export const setSortBy = (sortBy) => ({
  payload: sortBy,
  type: SET_SORT_BY,
});

export const selectOffer = (groupIndex) => ({
  payload: groupIndex,
  type: SELECT_OFFER,
});

export const setSelectedItineraries = (groupIndex, selectedOriginItinerary, selectedReturnItinerary) => ({
  payload: {
    selectedOriginItinerary,
    selectedReturnItinerary,
    groupIndex,
  },
  type: SET_SELECTED_ITINERARIES,
});

export const setGroupsLoading = () => ({
  type: SET_GROUPS_LOADING,
});

export const setGroupsSuccess = (data) => ({
  payload: data,
  type: SET_GROUPS_SUCCESS,
});

export const setGroupsError = (error) => ({
  payload: { error },
  type: SET_GROUPS_ERROR,
});

export const setPage = (page) => ({
  payload: { page },
  type: SET_PAGE,
});

export const resetFilter = (filter) => ({
  payload: { filter },
  type: RESET_FILTER,
});

export const resetAirlines = () => ({
  type: RESET_AIRLINES,
});

export const setFilters = ({ filters, airlines }) => ({
  payload: { filters, airlines },
  type: SET_FILTERS,
});

export const setSearchParams = (searchParams: FlightSearchParams, tripSettings: TripSettings, params: FlightFilterResetSettings) => (dispatch, getState) => {
  const { authReducer } = getState();
  dispatch({
    payload: {
      availablePaymentTypes: tripSettings.paymentTypes,
      shouldResetAirlines: params.shouldResetAirlines,
      shouldResetFilters: params.shouldResetFilters,
      shouldResetSortBy: params.shouldResetSortBy,
      privateEmail: tripSettings.email,
      searchParams,
      currentUser: authReducer.currentUser,
    },
    type: SET_SEARCH_PARAMS,
  });
};
