import { mergeClassnames } from '@heathmont/moon-core-tw';

export const clearButtonCN = mergeClassnames([
  'text-moon-12',
  'font-medium',
  'text-trunks',
  'cursor-pointer',
  'hover:text-[#5a5e64]',
]);

