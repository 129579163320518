import { connect } from 'react-redux';
import FlightBookingConfirmation from './FlightBookingConfirmation.ui';
import { resetToInitial } from '../../store/flightBookings/actions';
import {
  setIsFeedbackFormOpen,
} from '../../store/dialogs/actions';
import { IDialogsReducer } from '../../store/dialogs/reducer';
import { IAuthReducer } from '../../store/auth/reducer';

const mapStateToProps = ({
  dialogsReducer,
  authReducer,
}: {
  dialogsReducer: IDialogsReducer,
  authReducer: IAuthReducer,
}) => {
  return {
    isFeedbackModalOpen: dialogsReducer.isFeedbackModalOpen,
    currentUser: authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  setIsFeedbackFormOpen,
  resetToInitial,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingConfirmation);
