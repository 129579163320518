import { SET_TRIP_SETTINGS } from './actionTypes';
import { TripSettings } from '../../enums/TripSettings';

export const setTripSettings = (tripSettings: TripSettings) => {
  sessionStorage.setItem('tripSettings', JSON.stringify(tripSettings));
  return {
    type: SET_TRIP_SETTINGS,
    payload: tripSettings,
  };
};
