import { useState, useEffect } from 'react';
import request from '../utils/request';
import { calculateFlightTotalCost } from './useFlightOffersPriceConfirmation';
import { isBookingHasLowCostOffers } from '../utils/flights';

export const useConfirmOfferPriceAndAvailability = ({
  tripPurposeId,
  amaClientRef,
  needToCheck,
  offers = [],
}: {
  tripPurposeId: number;
  amaClientRef?: string;
  needToCheck: boolean;
  offers: any[];
}): {
  currentOffersState: any[];
  isOfferAvailable: boolean;
  currentPrice: number;
} => {
  const [isOfferAvailable, setIsOfferAvailable] = useState<boolean>(true);
  const [currentOffersState, setCurrentOffersState] = useState<any[]>(offers);
  const [currentPrice, setCurrentPrice] = useState<number>();

  const confirmPriceAndAvailability = async () => {  
    try {
      const { data } = await request.post('white-label/flights/pricing', {
        includeDetailedFareRules: false,
        tripPurposeId,
        amaClientRef,
        includeBags: false,
        offers,
      });

      if (data?.success && data?.data?.length) {
        setCurrentOffersState(data?.data);
        setIsOfferAvailable(true);
        setCurrentPrice(calculateFlightTotalCost(data?.data));
      } else {
        setIsOfferAvailable(false);
      }
    } catch (err) {
      setIsOfferAvailable(false);
    }
  };

  useEffect(() => {
    if (amaClientRef && offers?.length) {
      setCurrentPrice(calculateFlightTotalCost(offers));
    }
  }, [amaClientRef, offers?.length]);

  useEffect(() => {
    let interval;
  
    if (amaClientRef && offers?.length && isBookingHasLowCostOffers(offers)) {
      interval = setInterval(() => {
        if (isOfferAvailable && needToCheck) {
          confirmPriceAndAvailability();
        }
      }, 60 * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [amaClientRef, isOfferAvailable, needToCheck, offers?.length]);

  return {
    currentOffersState,
    isOfferAvailable,
    currentPrice,
  };
};
