import { useNavigate } from 'react-router-dom';
import AuthFormWrapper from '../../components/AuthFormWrapper';
import Footer from '../../components/Footer';
import PageWrapper from '../../components/PageWrapper';
import useIsMobile from '../../hooks/useIsMobile';
import { IAccountActivation } from './types';
import AuthFormContent from '../../components/AuthFormContent';
import { useQuery } from '../../hooks/useQuery';
import { useEffect, useMemo, useState } from 'react';
import request from '../../utils/request';
import { extractErrorFromResponse } from '../../utils/errors';

const AccountActivation: React.FC<IAccountActivation> = ({
  isContactFormOpen,
  setIsContactFormOpen,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { getQuery } = useQuery();
  const { token } = useMemo(() => getQuery(), []) as { token: string };
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [activationText, setActivationText] = useState<string>('');

  const handleNavigateToSignInPage = () => {
    navigate('/sign-in');
  };

  const handleActivateAccount = async () => {
    try {
      await request.post('white-label/auth/account-activation', {}, {
        params: {
          token,
        },
      });
      setIsSuccess(true);
      setActivationText('Your account has been activated successfully');
    } catch (err) {
      setIsSuccess(false);
      setActivationText(extractErrorFromResponse(err));
    }
  };

  useEffect(() => {
    if (token) {
      handleActivateAccount();
    } else {
      setActivationText('Something went wrong');
      setIsSuccess(false);
    }
  }, [token]);

  return (
    <PageWrapper isMobile={isMobile} authPage page={'account-activation'}>
      <AuthFormWrapper>
        <AuthFormContent
          buttonTitle='Sign in'
          title={isSuccess ? 'Success' : 'Unsuccess'}
          text={isSuccess ? activationText : `${activationText}. Please contact ${process.env.REACT_APP_CONTACT_EMAIL}`}
          onClick={handleNavigateToSignInPage}
        />
      </AuthFormWrapper>
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default AccountActivation;
