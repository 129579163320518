import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { IInternalPassengersForm } from './types';
import { fetchOptions } from './utils';
import PassengersFormWrapper from '../PassengersFormWrapper';
import InternalPassengerForm from '../InternalPassengerForm';
import { InternalPassenger } from '../../enums/InternalPassenger';

const InternalPassengersForm: React.FC<IInternalPassengersForm> = ({
  additionalBagPerPassenger,
  additionalBagErrors,
  isPageLoading,
  bagsOptions,
  formik,
  handleSelectBaggage,
}) => {
  const { values, errors, touched, setValues, setFieldValue, setFieldTouched, validateForm } = formik;
  const [users, setUsers] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchOptions({ setIsOptionsLoading, setAirlines, setUsers });
    validateForm();
  }, []);

  const extractBaggageError = (index: number) => {
    return get(additionalBagErrors, [index], null);
  };

  const extractAdditionalBaggagePerUser = (passengerId: number) => {
    return additionalBagPerPassenger
      .filter(additionalBag => additionalBag?.passengerId ===passengerId)
      .map(additionalBag => additionalBag.value);
  };

  const handleSetValues = (index: number, data: InternalPassenger) => {
    setValues({
      passengers: values.passengers.map((passenger, passengerIndex) => {
        if (index === passengerIndex) {
          return data;
        }

        return passenger;
      })
    });
  };

  return (
    <PassengersFormWrapper
      additionalBagErrors={additionalBagErrors}
      isLoading={isPageLoading || isOptionsLoading}
      formik={formik}
      renderPassengerForm={(passenger, index) => (
        <InternalPassengerForm
          additionalBagError={extractBaggageError(index)}
          additionalBags={extractAdditionalBaggagePerUser(passenger.id)}
          passenger={passenger as InternalPassenger}
          airlines={airlines}
          touched={get(touched, ['passengers', index], {})}
          errors={get(errors, ['passengers', index], {})}
          users={users}
          bags={bagsOptions}
          handleSelectBaggage={(selectedBag) => handleSelectBaggage(selectedBag, passenger.id)}
          setFieldTouched={(field) => setFieldTouched(`passengers.${index}.${field}`, true, false)}
          setFieldValue={(field, value) => setFieldValue(`passengers.${index}.${field}`, value)}
          setValues={(data) => handleSetValues(index, data)}
        />
      )}
    />
  );
};

export default InternalPassengersForm;
