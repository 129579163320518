import Skeleton from 'react-loading-skeleton';
import isEmpty from 'lodash/isEmpty';
import { Hint, mergeClassnames } from '@heathmont/moon-core-tw';
import get from 'lodash/get';
import { FormikProvider } from 'formik';
import { IPassengersFormWrapper } from './types';
import { getPassengerFullName } from './utils';
import Accordion from '../Accordion';
import { textEllipsisCN } from '../../styles/texts';

const PassengersFormWrapper: React.FC<IPassengersFormWrapper> = ({
  additionalBagErrors,
  isLoading,
  formik,
  renderPassengerForm,
}) => {
  const { errors, values } = formik;

  const renderPassengerTitle = (index) => {
    const passengerErrors = get(errors, ['passengers', index], {});
    const passengerAdditionalBaggageError = get(additionalBagErrors, [index], null)
    const passenger = get(values, ['passengers', index], {});
    const isError = !isEmpty(passengerErrors) || passengerAdditionalBaggageError;
    const fullName = getPassengerFullName(passenger);

    return (
      <div className='text-moon-14'>
        <div className='flex items-center gap-[10px]'>
          <div className={`w-[3px] h-[3px] rounded-[50%] ${isError ? 'bg-dodoria' : 'bg-roshi'}`} /> 
          <span className='text-bulma tracking-[1.5px]'>
            {isError ? 'NOT  COMPLETED' : 'COMPLETED'}
          </span>
        </div>
        <span className={mergeClassnames('text-moon-12 font-normal text-trunks mt-[5px]', textEllipsisCN)}>
          Passenger {index + 1}{fullName ? `: ${fullName}` : ''}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className='w-full flex flex-col gap-[10px]'>
        <FormikProvider value={formik}>
          {values.passengers.map((passenger, index) => (
            <Accordion
              openByDefault={!isLoading}
              disabled={isLoading}
              title={(
                <>
                  {isLoading ? (
                    <Skeleton style={{ height: '100%', width: '150px' }} inline />
                  ): (
                    renderPassengerTitle(index)
                  )}
                </>
              )}
              key={`${passenger.id}_${isLoading}`}
            >
              {renderPassengerForm(passenger, index)}
            </Accordion>
          ))}
        </FormikProvider>
      </div>

      {errors?.passengers && typeof errors?.passengers === 'string' && (
        <Hint className='mt-[16px]' error>{errors?.passengers}</Hint>
      )}
    </>
  );
};

export default PassengersFormWrapper;
