import { CurrentUser } from '../../enums/CurrentUser';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { TripSettings } from '../../enums/TripSettings';
import { USER_STATUS } from '../../enums/UserStatus';
import Yup from '../../utils/validation';

const EMAIL_MIN_VALUE = 2;
const EMAIL_MAX_VALUE = 255;
const TRIP_PURPOSE_DETAILS_MIN = 2;
const TRIP_PURPOSE_DETAILS_MAX = 1000;

const validateMessages = {
  tripPurposeId: {
    required: 'Trip purpose cannot be empty',
  },
  email: {
    required: 'Email cannot be empty',
    min: `Email cannot be less than ${EMAIL_MIN_VALUE} characters`,
    max: `Email cannot be longer than ${EMAIL_MAX_VALUE} characters`,
    valid: 'Email should be in format "user@example.com"',
    disallowedSymbol: 'Email cannot contain "+"',
  },
  tripPurposeDetails: {
    required: 'Trip purpose description cannot be empty',
    min: `Trip purpose description cannot be less than ${TRIP_PURPOSE_DETAILS_MIN} characters`,
    max: `Trip purpose description cannot be longer than ${TRIP_PURPOSE_DETAILS_MAX} characters`,
  },
};

export const initContactFormSchema = (currentUser: CurrentUser) => {
  if (currentUser.status === USER_STATUS.EXTERNAL) {
    return Yup.object().shape({
      tripPurposeId: Yup
        .number()
        .required(validateMessages.tripPurposeId.required),
      tripPurposeDetails: Yup
        .string()
        .min(TRIP_PURPOSE_DETAILS_MIN, validateMessages.tripPurposeDetails.min)
        .max(TRIP_PURPOSE_DETAILS_MAX, validateMessages.tripPurposeDetails.max)
        .isInLatinCharacters()
        .required(validateMessages.tripPurposeDetails.required),
    });
  }

  return Yup.object().shape({
    tripPurposeId: Yup
      .number()
      .required(validateMessages.tripPurposeId.required),
    tripPurposeDetails: Yup
      .string()
      .min(TRIP_PURPOSE_DETAILS_MIN, validateMessages.tripPurposeDetails.min)
      .max(TRIP_PURPOSE_DETAILS_MAX, validateMessages.tripPurposeDetails.max)
      .isInLatinCharacters()
      .required(validateMessages.tripPurposeDetails.required),
    useCompanyEmail: Yup
      .bool()
      .required(),
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .min(EMAIL_MIN_VALUE, validateMessages.email.min)
      .max(EMAIL_MAX_VALUE, validateMessages.email.max)
      .withoutSpaces()
      .isInLatinCharacters()
      .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
      .required(validateMessages.email.required),
  });
};

export const getInitialValues = (currentUser: CurrentUser): TripSettings => {
  const isExternalUser = currentUser?.status === USER_STATUS.EXTERNAL;
  return {
    tripPurposeDetails: '',
    paymentRequired: isExternalUser,
    useCompanyEmail: true,
    tripPurposeId: null,
    paymentTypes: isExternalUser ? [PAYMENT_TYPE.CRYPTO, PAYMENT_TYPE.CARD] : [],
    email: currentUser.email,
  };
};

export const getFieldBaseProps = (field) => {
  return {
    inputSize: 'xlarge',
    fieldName: field, 
    fieldId: field,
  };
};
