import { Hint } from '@heathmont/moon-core-tw';
import { IAdditionalBaggageSelector } from './types';
import CheckboxGroup from '../CheckboxGroup';
import { checkboxLabelCN } from '../../styles/texts';
import { fieldLabelWrapperCN, fieldWrapperCN, fieldLabelCN } from '../../styles/form-field';

const AdditionalBaggageSelector: React.FC<IAdditionalBaggageSelector> = ({
  selectedOptions,
  options,
  error,
  onSelect,
}) => {
  const renderLabel = (title: string) => (
    <span
      dangerouslySetInnerHTML={{ __html: title }}
      className={checkboxLabelCN}
    />
  );

  return options?.length > 0 && (
    <div className={fieldWrapperCN}>
      <div className={fieldLabelWrapperCN}>
        <h5 className={fieldLabelCN}>
          Additional baggage
        </h5>
      </div>    

      <CheckboxGroup
        selectedValues={selectedOptions}
        data={options}
        renderCheckboxLabel={renderLabel}
        onSelect={onSelect}
      />

      {error && (
        <Hint error>{error}</Hint>
      )}
    </div>
  );
};

export default AdditionalBaggageSelector;
