import Footer from '../../components/Footer';
import PageWrapper from '../../components/PageWrapper';
import SignUpForm from '../../components/SignUpForm';
import useIsMobile from '../../hooks/useIsMobile';
import { ISignUp } from './types';

const SignUp: React.FC<ISignUp> = ({
  setIsContactFormOpen,
  isContactFormOpen,
}) => {
  const isMobile = useIsMobile();
  return (
    <PageWrapper authPage isMobile={isMobile} page={'sign-up'}>
      <SignUpForm />
      <Footer
        isContactFormOpen={isContactFormOpen}
        setIsContactFormOpen={setIsContactFormOpen}
      />
    </PageWrapper>
  );
};

export default SignUp;
