import { IAuthFormWrapper } from './types';
import FlightWindowImg from '../../static/flight-window.png';

const AuthFormWrapper: React.FC<IAuthFormWrapper> = ({
  children,
}) => {
  return (
    <div className='w-full flex justify-between items-start max-laptop:justify-center'>
      <div className='w-full flex flex-shrink-0 flex-col gap-4 tablet:gap-6 tablet:w-[580px] tablet:bg-goku tablet:p-8 tablet:rounded-xl tablet:shadow-moon-xs'>
        {children}
      </div>
      <div className='hidden laptop:flex laptop:justify-end'>
        <img className='max-w-[75%]' src={FlightWindowImg} />
      </div>
    </div>
  );
};

export default AuthFormWrapper;
