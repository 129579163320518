import { Fragment, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  TravelNoSuitcase,
  TravelSuitcase,
  GenericAlarm,
  GenericUsers,
  TravelSeats,
  FilesText,
} from '@heathmont/moon-icons-tw';
import { IFlightBookingOfferAdditionalDetails } from './types';
import {
  wrapperCN,
  iconCN,
  itemCN,
} from './styles';
import { ReactComponent as CurrencyUsdSvg } from '../../static/icons/currency-usd.svg';
import Tooltip from '../Tooltip';
import { CABIN_LABEL } from '../../enums/CabinTypes';
import {
  getBaggageDescription,
  hasOvernightLayover,
  isBaggageIncluded,
  getBaggageLabel,
} from '../../utils/flight-offer';
import { extractFlightOfferFareRules } from '../../utils/fare-rules';
import { mergeClassnames } from '@heathmont/moon-core-tw';
import { btnReset } from '../../styles/buttons';

const renderFareDetail = (fareRule) => {
  const fareRuleJSX = (
    <>
      <span className={iconCN}>
        <CurrencyUsdSvg className='text-moon-24' />
      </span>
      {fareRule.label}
    </>
  );

  return fareRule.description ? (
    <Tooltip label={fareRule.description}>
      <span className="flex">{fareRuleJSX}</span>
    </Tooltip>
  ) : (
    fareRuleJSX
  );
};

const renderBaggageInfo = (includedCheckedBags) => {
  const baggageIncluded = isBaggageIncluded(includedCheckedBags);
  const baggageInfoJSX = (
    <>
      <span className={iconCN}>
        {baggageIncluded ? (
          <TravelSuitcase className='text-moon-24' />
        ) : (
          <TravelNoSuitcase className='text-moon-24' />
        )}
      </span>
      {getBaggageLabel(includedCheckedBags)}
    </>
  );

  return baggageIncluded ? (
    <Tooltip label={getBaggageDescription({ bags: includedCheckedBags, detailed: true })}>
      <span className="flex">{baggageInfoJSX}</span>
    </Tooltip>
  ) : (
    baggageInfoJSX
  );
};

export const renderItem = ({
  withSeparator,
  isLoading,
  renderTitle,
  renderIcon,
}: {
  withSeparator: boolean;
  isLoading: boolean;
  renderTitle: () => string | JSX.Element,
  renderIcon?: () => JSX.Element,
}) => {
  return (
    <div className={itemCN}>
      {withSeparator && <span className='text-popo mx-[7.5px] my-[0]'>•</span>}
      {isLoading ? (
        <Skeleton style={{ height: '100%', width: '90px' }} inline />
      ) : (
        <>
          {renderIcon && (
            <span className={iconCN}>
              {renderIcon()}
            </span>
          )}
          {renderTitle()}
        </>
      )}
    </div>
  )
};

const FlightBookingOfferAdditionalDetails: React.FC<IFlightBookingOfferAdditionalDetails> = ({
  detailedFareRules,
  fareDetails,
  isLoading,
  offer,
  setIsMoreFareRulesModalOpen,
}) => {
  const fareRules = useMemo(() => extractFlightOfferFareRules(offer), []);
  const overnightLayover = useMemo(() => {
    return offer.itineraries.some(it => hasOvernightLayover(it));
  }, offer.itineraries);
  const numberOfPassengersLabel = useMemo(() => {
    return `${offer?.travelerPricings?.length} passenger${offer?.travelerPricings?.length > 1 ? 's' : ''}`;
  }, [offer?.travelerPricings]);

  return (
    <div className={wrapperCN}>
      {renderItem({
        withSeparator: false,
        isLoading,
        renderTitle: () => numberOfPassengersLabel,
        renderIcon: () => <GenericUsers className='text-moon-24' />,
      })}

      {renderItem({
        withSeparator: true,
        isLoading,
        renderTitle: () => CABIN_LABEL[fareDetails.cabin],
        renderIcon: () => <TravelSeats className='text-moon-24' />,
      })}

      {renderItem({
        withSeparator: true,
        isLoading,
        renderTitle: () => renderBaggageInfo(fareDetails?.includedCheckedBags),
      })}

      {fareRules.map((fareRule, index) => (
        <Fragment key={index}>
          {renderItem({
            withSeparator: true,
            isLoading,
            renderTitle: () => renderFareDetail(fareRule),
          })}
        </Fragment>
      ))}

      {overnightLayover && renderItem({
        withSeparator: true,
        isLoading,
        renderTitle: () => 'Overnight layover',
        renderIcon: () => (
          <GenericAlarm className='text-moon-24 text-dodoria' />
        ),
      })}
      
      {detailedFareRules?.length > 0 && renderItem({
        withSeparator: true,
        isLoading,
        renderTitle: () => (
          <span
            className={mergeClassnames('underline', btnReset)}
            onClick={() => setIsMoreFareRulesModalOpen(true, offer?.id)}
          >
            Fare rules
          </span>
        ),
        renderIcon: () => <FilesText className='text-moon-24' />,
      })}
    </div>
  );
};

export default FlightBookingOfferAdditionalDetails;
