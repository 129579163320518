import { connect } from 'react-redux';
import ChangeDocumentForm from './ChangeDocumentForm.ui';
import { updateCurrentUserDocument } from '../../store/auth/actions';

const mapStateToProps = ({
  authReducer,
}) => {
  return {
    currentUser: authReducer.currentUser,
  };
};

const mapDispatchToProps = {
  updateCurrentUserDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocumentForm);
