import { TripPurpose } from '../../enums/TripPurpose';
import request from '../../utils/request';

export const handleFetchUserTripPurposes = async ({
  setIsTripPurposesLoading,
  setTripPurposes,
}: {
  setIsTripPurposesLoading: (isLoading: boolean) => void;
  setTripPurposes: (tripPurposes: TripPurpose[]) => void;
}) => {
  try {
    setIsTripPurposesLoading(true);
    const response = await request.get('white-label/user/purposes');

    if (response?.data?.purposes?.length) {
      setTripPurposes(response.data.purposes);
    } else {
      setTripPurposes([]);
    }
  } catch (err) {
    setTripPurposes([]);
  } finally {
    setIsTripPurposesLoading(false);
  }
};
