import { connect } from 'react-redux';
import FlightBookingPaymentChange from './FlightBookingAdditionalPayment.ui';
import {
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  setPaymentPayerType,
} from '../../store/flightBookings/actions';
import { IAuthReducer } from '../../store/auth/reducer';

const mapStateToProps = ({
  flightBookingsReducer,
  authReducer,
}: {
  flightBookingsReducer: any;
  authReducer: IAuthReducer;
}) => {
  return {
    currentUser: authReducer.currentUser,
    payment: flightBookingsReducer.payment,
  };
};

const mapDispatchToProps = {
  handleSelectPaymentType,
  handleSelectPaymentCoin,
  setPaymentPayerDetails,
  setPaymentPayerType,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightBookingPaymentChange);
