import request from '../../utils/request';

export const formatDocumentTypes = (documentTypes = []): { value: number, label: string }[] => {
  return documentTypes.map(documentType => ({
    value: documentType.id,
    label: documentType.name,
  }));
};

export const formatCountries = (countries = []): { value: number, label: string, flag: string }[] => {
  return countries.map(country => ({
    value: country.id,
    label: country.name,
    flag: country.flag,
  }));
};

export const formatAirlines = (airlines = []): { value: number, label: string }[] => {
  return airlines.map(airline => ({
    value: airline.value,
    label: airline.title,
  }));
};

export const getOptionsOnInit = async (options, setOptions) => {
  try {
    setOptions({ ...options, loading: true });
  
    const [
      travelDocumentsTypesResponse,
      countriesResponse,
      airlinesResponse,
    ] = await Promise.all([
      request.get('white-label/travel-documents-types'),
      request.get('white-label/countries'),
      request.get('white-label/airlines'),
    ]);

    setOptions({
      ...options,
      travelDocumentsTypes: formatDocumentTypes(travelDocumentsTypesResponse?.data?.travelDocumentsTypes),
      countries: formatCountries(countriesResponse?.data?.countries),
      airlines: formatAirlines(airlinesResponse?.data?.airlines),
      loading: false,
    });
  } catch (err) {
    setOptions({ ...options, loading: false });
  }
};
