import externalAirlinesClass from 'airline-codes';
import { FlightOfferSegment } from '../../enums/FlightOfferSegment';


export const getFlightNumber = (segment: FlightOfferSegment) => {
  const carrierCode = segment?.carrierCode;
  return carrierCode ? `${carrierCode}-${segment.number}` : segment.number;
};

export const getAirlineName = (segment: FlightOfferSegment) => {
  const exceptions = {
    RK: 'Ryanair UK',
  };

  const carrierCode = segment?.carrierCode;
  const carrierName = segment?.carrierName;

  if (!carrierCode && !carrierName) {
    return null;
  }

  if (exceptions[segment.carrierCode]) {
    return exceptions[segment.carrierCode];
  }

  const airline = externalAirlinesClass.findWhere({ iata: carrierCode });

  if (airline) {
    return airline.get('name');
  }

  return carrierName || carrierCode;
};
