import { paymentSchema } from '../../components/PaymentFormWrapper/schema';
import { Payment } from '../../enums/Payment';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import request from '../../utils/request';

export const isPaymentTypeSupportLowCostBookings = (paymentType: PAYMENT_TYPE): boolean => {
  const PAYMENT_TYPES_FOR_LOW_COST_BOOKINGS = [
    PAYMENT_TYPE.CRYPTO,
    PAYMENT_TYPE.CARD,
  ];
  return PAYMENT_TYPES_FOR_LOW_COST_BOOKINGS.includes(paymentType);
};

export const fetchUserPrevPaymentData = async () => {
  try {
    const response = await request.get('white-label/user/payments');

    if (response?.data?.payments?.length) {
      return response.data.payments[0];
    }

    return null;
  } catch (err) {
    return null;
  }
}

export const isPaymentValid = async (payment: Payment) => {
  try {
    await paymentSchema.validate({ payment });
    return true;
  } catch (err) {
    return false;
  }
}

const isPaymentTypeAvailable = (paymentType: PAYMENT_TYPE, availablePaymentTypes: PAYMENT_TYPE[]) => {
  return availablePaymentTypes.some(availablePaymentType => (
    availablePaymentType === paymentType
  ));
}

export const definePaymentType = (prevPayment: Payment, availablePaymentTypes: PAYMENT_TYPE[]) => {
  return isPaymentTypeAvailable(prevPayment.paymentType, availablePaymentTypes)
    ? prevPayment.paymentType
    : availablePaymentTypes.length
      ? availablePaymentTypes[0]
      : prevPayment.paymentType;
};
