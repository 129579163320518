import Yup from '../../utils/validation';

const VALIDATIONS = {
  PASSWORD: {
    MAX: 50,
    MIN: 8,
  },
};

const validateMessages = {
  newPassword: {
    required: 'New password cannot be empty',
    min: `New password cannot be less than ${VALIDATIONS.PASSWORD.MIN} characters`,
    max: `New password cannot be longer than ${VALIDATIONS.PASSWORD.MAX} characters`,
    upperCase: 'New password must contain an uppercase English character: A-Z',
    lowerCase: 'New password must contain an lowercase English character: a-z',
    digit: 'New password must contain a digit: 0-9',
    specialSymbol: 'New password must contain a special character: !#$%&()*+-?~@=',
  },
  confirmPassword: {
    required: 'Confirm password cannot be empty',
    match: 'Passwords do not match',
  },
};

export const resetPasswordSchema = Yup
  .object()
  .shape({
    newPassword: Yup
      .string()
      .required(validateMessages.newPassword.required)
      .min(VALIDATIONS.PASSWORD.MIN, validateMessages.newPassword.min)
      .max(VALIDATIONS.PASSWORD.MAX, validateMessages.newPassword.max)
      .includeUpperCase(validateMessages.newPassword.upperCase)
      .includeLowerCase(validateMessages.newPassword.lowerCase)
      .includeDigit(validateMessages.newPassword.digit)
      .includeSpecialSymbol(validateMessages.newPassword.specialSymbol)
      .withoutSpaces(),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('newPassword')], validateMessages.confirmPassword.match)
      .required(validateMessages.confirmPassword.required),
  });

export const getInitialValues = (code: string): {
  confirmPassword: string;
  newPassword: string;
  code: string;
} => {
  return {
    confirmPassword: '',
    newPassword: '',
    code,
  };
};
