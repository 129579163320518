import { mergeClassnames } from '@heathmont/moon-core-tw';
import { ILineBreakWithText } from './types';

const LineBreakWithText: React.FC<ILineBreakWithText> = ({
  text,
}) => {
  const lineCN = mergeClassnames(['w-full', 'h-px', 'bg-trunks', 'tablet:bg-beerus']);
  return (
    <div className='w-full flex items-center justify-center'>
      <div className={lineCN} />
      <span className='flex-shrink-0 text-trunks flex items-center justify-center text-moon-12 h-6 w-16'>
        {text}
      </span>
      <div className={lineCN} />
    </div>
  );
};

export default LineBreakWithText;

