import { CurrentUser } from '../../enums/CurrentUser';
import {
  UPDATE_CURRENT_USER_DOCUMENT,
  UPDATE_CURRENT_USER_PROFILE,
  SET_ACCESS_TOKEN,
  SET_CURRENT_USER,
} from './actionTypes';

export interface IAuthReducer {
  isAuthenticated: boolean;
  currentUser: CurrentUser | null;
  token: string | null;
};

const initState:IAuthReducer = {
  isAuthenticated: false,
  currentUser: null,
  token: localStorage.getItem('token'),
};

const authReducer = (state = initState, action): IAuthReducer => {
  switch (action.type) {
    case UPDATE_CURRENT_USER_DOCUMENT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          document: action.payload,
        },
      };
    
    case UPDATE_CURRENT_USER_PROFILE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };

    case SET_CURRENT_USER: 
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
      };

    case SET_ACCESS_TOKEN: 
      return {
        ...state,
        token: action.payload,
      };
  
    default:
      return state;
  }
};

export default authReducer;
