import moment from 'moment';
import Yup from '../../utils/validation';
import { CurrentUserProfile } from '../../enums/CurrentUserProfile';
import { CurrentUser } from '../../enums/CurrentUser';
import { extractCountryCodeAndNumber } from '../../utils/phone';

const VALIDATIONS = {
  NAME: {
    MAX: 20,
    MIN: 2,
  },
  PASSENGER_AGE: {
    MAX: 100,
    MIN: 18,
  },
};

const validateMessages = {
  firstName: {
    validName: 'First name can contain only Latin characters and hyphens',
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `First name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  lastName: {
    validName: 'Last name can contain only Latin characters and hyphens',
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `Last name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  gender: {
    required: 'Gender cannot be empty',
  },
  email: {
    disallowedSymbol: 'Email cannot contain "+"',
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  birthday: {
    required: 'Birth date cannot be empty',
    max: `Age cannot be more then ${VALIDATIONS.PASSENGER_AGE.MAX} years old`,
    min: `Age cannot be less then ${VALIDATIONS.PASSENGER_AGE.MIN} years old`,
  },
  phoneCountryCode: {
    required: 'Country cannot be empty',
  },
  phoneNumber: {
    required: 'Telephone number cannot be empty',
  },
};

export const userProfileSchema = Yup
  .object()
  .shape({
    firstName: Yup
      .string()
      .required(validateMessages.firstName.required)
      .min(VALIDATIONS.NAME.MIN, validateMessages.firstName.min)
      .max(VALIDATIONS.NAME.MAX, validateMessages.firstName.max)
      .withoutSpaces()
      .validName(validateMessages.firstName.validName)
      .isInLatinCharacters(),
    lastName: Yup
      .string()
      .required(validateMessages.lastName.required)
      .min(VALIDATIONS.NAME.MIN, validateMessages.lastName.min)
      .max(VALIDATIONS.NAME.MAX, validateMessages.lastName.max)
      .withoutSpaces()
      .validName(validateMessages.lastName.validName)
      .isInLatinCharacters(),
    gender: Yup
      .string()
      .required(validateMessages.gender.required),
    birthday: Yup
      .date()
      .required(validateMessages.birthday.required)
      .test('max-age', validateMessages.birthday.max, (value) => (
        moment().diff(moment(value), 'years') <= VALIDATIONS.PASSENGER_AGE.MAX
      ))
      .test('min-age', validateMessages.birthday.min, (value) => (
        moment().diff(moment(value), 'years') >= VALIDATIONS.PASSENGER_AGE.MIN
      ))
      .nullable(),
    phoneCountryCode: Yup
      .string()
      .required(validateMessages.phoneCountryCode.required),
    phoneNumber: Yup
      .number()
      .required(validateMessages.phoneNumber.required),
    phone: Yup
      .string()
      .isValidPhoneNumber(),
  });

export const getInitialValues = (currentUser: CurrentUser): CurrentUserProfile => {
  const { phoneCountryCode, phoneNumber } = extractCountryCodeAndNumber(currentUser.phone);
  return {
    phoneCountryCode,
    phoneNumber,
    firstName: currentUser.firstName,
    birthday: currentUser.birthday,
    lastName: currentUser.lastName,
    gender: currentUser.gender,
    phone: currentUser.phone,
  };
};
