import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { IncludedBagOptionValue } from '../../enums/IncludedBag';
import { Passenger } from '../../enums/Passenger';

export const isAllBagOptionsHeterogeneous = (bags: IncludedBagOptionValue[]): boolean => {
  const byOfferId = groupBy(bags, 'offerId');
  return Object.keys(byOfferId).every((offerId) => {
    return uniqBy(byOfferId[offerId], (bag) => {
      let labels: (string | number)[] = [bag.quantity];
      
      if (bag.weight) {
        labels.push(bag.weight);
      }

      if (bag.weightUnit) {
        labels.push(bag.weightUnit);
      }

      return labels.join('_');
    }).length === 1
  });
};

export const isBagOptionsSinglePerFlight = (bags: IncludedBagOptionValue[]): boolean => {
  return uniqBy(bags, 'label').length !== bags.length;
};

export const validateBaggages = (bags: IncludedBagOptionValue[], passengers: Passenger[]) => {
  const validations = passengers.map(passenger => {
    const passengerBags = bags.filter(bag => bag.passengerId === passenger.id);

    if (isBagOptionsSinglePerFlight(passengerBags)) {
      return 'Only 1 additional baggage option can be added to the outbound flight and 1 for return flight';
    }
    
    if (!isAllBagOptionsHeterogeneous(passengerBags)) {
      return 'Bag options can not be heterogeneous';
    }

    return null;
  });

  if (validations.every(validation => !validation)) {
    return null;
  }

  return validations;
};
