import Yup from '../../utils/validation';

const VALIDATIONS = {
  PASSWORD: {
    MAX: 50,
    MIN: 8,
  },
};

const validateMessages = {
  email: {
    disallowedSymbol: 'Email cannot contain "+"',
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  password: {
    required: 'Password cannot be empty',
    min: `Password cannot be less than ${VALIDATIONS.PASSWORD.MIN} characters`,
    max: `Password cannot be longer than ${VALIDATIONS.PASSWORD.MAX} characters`,
  },
};

export const authenticationSchema = Yup
  .object()
  .shape({
    email: Yup
      .string()
      .email(validateMessages.email.valid)
      .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
      .required(validateMessages.email.required),
    password: Yup
      .string()
      .required(validateMessages.password.required)
      .min(VALIDATIONS.PASSWORD.MIN, validateMessages.password.min)
      .max(VALIDATIONS.PASSWORD.MAX, validateMessages.password.max)
      .withoutSpaces(),
    remember: Yup.bool(),
  });

export const getInitialValues = (): {
  password: string;
  remember: boolean;
  email: string;
} => {
  return {
    remember: false,
    password: '',
    email: '',
  };
};