import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from '../ErrorBoundary';
import * as i18n from '../../utils/i18n';
import request from '../../utils/request';
import Routes from '../Routes';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import './App.scss';
import { IApp } from './types';

const App: React.FC<IApp> = ({ isAuthenticated, token, setCurrentUser }) => {
  const [isReady, setIsReady] = useState<boolean>(false);

  const handleGetCurrentUser = async (token) => {
    try {
      const response = await request.get('white-label/user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.user) {
        setCurrentUser(response.data.user);
      }

      setIsReady(true);
    } catch (err) {
      setIsReady(true);
    }
  };

  useEffect(() => {
    request.init();
    i18n.init();
  }, []);

  useEffect(() => {
    if (token) {
      handleGetCurrentUser(token);
    } else {
      setIsReady(true);
    }
  }, [token]);

  useEffect(() => {
    if (isAuthenticated) {
      request.setAuthorized();
    }
  }, [isAuthenticated]);

  if (!isReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <ErrorBoundary>
        <Routes />
        <ToastContainer />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
