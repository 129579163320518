import { CreditCardOrder } from '../../enums/CreditCardOrder';
import request from '../../utils/request';
import { showToast } from '../../utils/common';
import { isPaymentStuck } from '../../utils/payments';
import { isBookingHasLowCostOffers } from '../../utils/flights';
import { extractPaymentError } from '../../utils/errors';

export const fetchOrder = async ({
  amaClientRef,
  initial,
  proceedAfterSuccessfulPayment,
  setPaymentDetails,
  setPaymentError,
  setIsLoading,
  setCanRetry,
  setOffers,
  setOrder,
}: {
  amaClientRef: string,
  initial: boolean,
  proceedAfterSuccessfulPayment: (params: { transactionKey: string, isLowCostBooking: boolean, initial: boolean, trips: any[] }) => void,
  setPaymentDetails: (details: string) => void,
  setPaymentError: (error: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
  setCanRetry: (canRetry: boolean) => void; 
  setOffers: (offers: any[]) => void;
  setOrder: (order: CreditCardOrder) => void;
}) => {
  setIsLoading(true);

  try {
    const { data } = await request.get(`white-label/payments/status/${amaClientRef}`);

    if (data?.error) {
      setPaymentDetails(data?.details);
      setCanRetry(data?.canRetry);
      setPaymentError(true);
      setIsLoading(false);
      return ;
    }

    if (data?.confirmed) {
      return proceedAfterSuccessfulPayment({
        isLowCostBooking: isBookingHasLowCostOffers(data?.offers),
        transactionKey: data?.transaction?.id,
        initial,
        trips: data?.trips,
      });
    }

    if (isPaymentStuck(data?.transaction)) {
      setCanRetry(true);
      setPaymentError(true);
      setPaymentDetails('Your payment is stuck while processing');
    } else {
      setPaymentDetails(data?.details);
      setPaymentError(data?.error);
      setCanRetry(data?.canRetry);
    }

    setOffers(data?.offers);
    setOrder(data?.transaction);
    setIsLoading(false);
  } catch (err) {
    const { errorMessage, canRetry } = extractPaymentError(err);
    setPaymentDetails(errorMessage);
    setPaymentError(true);
    setCanRetry(canRetry);
    setIsLoading(false);
  }
};

export const refreshOrder = async ({
  amaClientRef,
  setRefreshTransactionLoading,
  proceedAfterSuccessfulPayment,
  setPaymentDetails,
  setPaymentError,
  setIsLoading,
  setCanRetry,
  setOffers,
  setOrder,
}: {
  amaClientRef: string,
  setRefreshTransactionLoading: (refreshTransactionLoading: boolean) => void,
  proceedAfterSuccessfulPayment: (params: { transactionKey: string, isLowCostBooking: boolean, initial: boolean, trips: any[] }) => void,
  setPaymentDetails: (details: string) => void,
  setPaymentError: (error: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
  setCanRetry: (canRetry: boolean) => void;
  setOffers: (offers: any[]) => void;
  setOrder: (order: CreditCardOrder) => void;
}) => {
  try {
    setRefreshTransactionLoading(true);

    await request.get(`white-label/payments/refresh/${amaClientRef}`);

    setIsLoading(true);
    setPaymentError(false);
    setRefreshTransactionLoading(false);
  
    await fetchOrder({
      amaClientRef,
      initial: false,
      proceedAfterSuccessfulPayment,
      setPaymentDetails,
      setPaymentError,
      setIsLoading,
      setCanRetry,
      setOffers,
      setOrder,
    });
  } catch (err) {
    setRefreshTransactionLoading(false);
    return showToast(err.message, 'error');
  }
};
