import { useNavigate, Link } from 'react-router-dom';
import { IHeaderAuth } from './types';
import { Popover, MenuItem } from "@heathmont/moon-core-tw";
import { GenericUser, TravelAirplane, SoftwareLogOut } from '@heathmont/moon-icons-tw';
import { avatarCN, linkCN } from './styles';
import { logout } from '../../utils/auth';

const HeaderAuth: React.FC<IHeaderAuth> = ({
  isAuthenticated,
  currentUser,
  light,
}) => {
  const navigate = useNavigate();

  const extractFirstLetterFromName = (name: string) => {
    return name.substring(0, 1);
  };

  if (isAuthenticated && currentUser) {
    return (
      <Popover position="bottom-end">
        <Popover.Trigger>
          <div className={avatarCN(light)}>
            {extractFirstLetterFromName(currentUser.firstName)}{extractFirstLetterFromName(currentUser.lastName)}
          </div>
        </Popover.Trigger>
        <Popover.Panel className="p-1 flex flex-col gap-1 max-w-44">
          <MenuItem onClick={() => navigate('/account')}>
            <GenericUser className="text-bulma text-moon-24 stroke-2" />
            <MenuItem.Title>
              <span className="font-medium">Profile</span>
            </MenuItem.Title>
          </MenuItem>
          <MenuItem onClick={() => navigate('/account/bookings')}>
            <TravelAirplane className="text-bulma text-moon-24 stroke-2" />
            <MenuItem.Title>
              <span className="font-medium">My bookings</span>
            </MenuItem.Title>
          </MenuItem>
          <MenuItem onClick={logout}>
            <SoftwareLogOut className="text-bulma text-moon-24 stroke-2" />
            <MenuItem.Title>
              <span className="font-medium">Logout</span>
            </MenuItem.Title>
          </MenuItem>
        </Popover.Panel>
      </Popover>
    );
  }

  return (
    <div className='flex gap-2'>
      <Link className={linkCN(light)} to='/sign-in'>
        Sign in
      </Link>
      <Link className={linkCN(light)} to='/sign-up'>
        Sign up
      </Link>
    </div>
  );
};

export default HeaderAuth;
