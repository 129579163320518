import { connect } from 'react-redux';
import { IAuthReducer } from '../../store/auth/reducer';
import PublicRoute from './Header.ui';

const mapStateToProps = ({ authReducer }: { authReducer: IAuthReducer }) => ({
  isAuthenticated: authReducer.isAuthenticated,
  currentUser: authReducer.currentUser,
});

export default connect(mapStateToProps)(PublicRoute);
