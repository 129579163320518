import request from '../../utils/request';
import { PaymentRequest } from '../../enums/PaymentRequest';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import { Trip } from '../../enums/Trip';
import { GENDER } from '../../enums/GenderType';
import { initialPaymentData } from '../../store/flightBookings/reducer';
import { PAYMENT_TYPE } from '../../enums/PaymentTypes';
import { FLIGHT_BOOKING_STEP } from '../../enums/FlightBookingStep';

export const handleRedirect = async ({
  paymentType,
  token,
  navigate,
}: {
  paymentType: PAYMENT_TYPE,
  token: string,
  navigate: any,
}) => {
  if (paymentType === PAYMENT_TYPE.CRYPTO) {
    navigate(`/${FLIGHT_BOOKING_STEP.CRYPTO_PAYMENT_CHECKOUT}/?token=${token}`);
  } else if (paymentType === PAYMENT_TYPE.CARD) {
    navigate(`/${FLIGHT_BOOKING_STEP.CARD_PAYMENT_CHECKOUT}/?token=${token}`);
  } else {
    navigate(`/${FLIGHT_BOOKING_STEP.SUCCESSFUL_PAYMENT}/?token=${token}`);
  }
};

export const initAdditionalPayment = async ({
  payment,
  token,
}: {
  payment: Payment,
  token: string,
}) => {
  const response = await request.post(`white-label/payments/additional`, {
    payment,
    token,
  });
  return response;
};

export const formatTravelers = (trip: Trip): Passenger[] => {
  return [
    {
      documentRequired: false,
      loyaltyNumber: null,
      firstName: trip?.passenger?.firstName || '',
      lastName: trip?.passenger?.lastName || '',
      document: null,
      birthday: trip?.passenger?.birthday || '',
      gender: !!trip?.passenger?.isFemale ? GENDER.FEMALE : GENDER.MALE,
      phone: trip?.passenger?.phone || '',
      email: trip?.passenger?.email || '',
      id: 1,
    },
  ];
};

export const formatPayment = (payments: Payment[], paymentRequest: PaymentRequest): Payment => {
  const prevPayment = payments.length ? payments[0] : null;
  return prevPayment? prevPayment : initialPaymentData;
};

export const handleGetAdditionalPaymentDetails = async ({
  token,
  setIsAdditionalPaymentDataLoading,
  setAdditionalPaymentData,
  handleError,
  navigate,
}: {
  token: string;
  setIsAdditionalPaymentDataLoading: (isLoading: boolean) => void;
  setAdditionalPaymentData: (additionalPaymentData) => void;
  handleError: () => void;
  navigate: any,
}) => {
  setIsAdditionalPaymentDataLoading(true);
  try {
    const response = await request.get(`white-label/payments/additional`, {
      params: {
        token,
      },
    });
    
    if (response?.data?.token) {
      return navigate(`/${FLIGHT_BOOKING_STEP.SUCCESSFUL_PAYMENT}/?token=${response.data.token}`);
    }
  
    setAdditionalPaymentData({
      ...response?.data,
      travelers: formatTravelers(response?.data?.trip),
      payment: formatPayment(response?.data?.trip?.payments, response?.data?.paymentRequest),
    });
    setIsAdditionalPaymentDataLoading(false);
  } catch (err) {
    setIsAdditionalPaymentDataLoading(false);
    handleError();
  }
};
