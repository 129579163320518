import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@heathmont/moon-core-tw';
import { IAuthForm } from './types';
import { FormikProvider } from 'formik';
import AuthFormContent from '../AuthFormContent';
import AuthFormWrapper from '../AuthFormWrapper';

const AuthForm: React.FC<IAuthForm> = ({
  submitButtonTitle,
  isLoading,
  canRetry = true,
  success,
  error,
  value,
  title,
  renderAdditionalText,
  handleTryAgain,
  renderSubTitle,
  renderFields,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const handleNavigateToSignInPage = () => {
    navigate('/sign-in');
  };

  const handleOnSubmit = async (e) => {
    await onSubmit(e);
    document.getElementById('container').scrollTo(0, 0);
  };

  return (
    <AuthFormWrapper>
      {success && (
        <AuthFormContent
          buttonTitle='Sign in'
          title='Success'
          text={success}
          onClick={handleNavigateToSignInPage}
        />
      )}
    
      {error && (
        <AuthFormContent
          buttonTitle={canRetry ? 'Try again' : 'Sign in'}
          title='Unsuccess'
          text={error}
          onClick={canRetry ? handleTryAgain : handleNavigateToSignInPage}
        />
      )}
    
      {!success && !error && (
        <>
          <AuthFormContent
            title={title}
            text={renderSubTitle()}
          />
          <FormikProvider value={value}>
            <form className='w-full flex flex-col gap-2 tablet:gap-4' onSubmit={handleOnSubmit}>
              {renderFields()}
              <Button className='w-full mt-2' disabled={isLoading || !isEmpty(value?.errors)} size='xl' type='submit'>
                {submitButtonTitle}
              </Button>
            </form>
          </FormikProvider>
          {renderAdditionalText && (
            <p className='text-moon-16'>
              {renderAdditionalText()}
            </p>
          )}
        </>
      )}
    </AuthFormWrapper>
  );
};

export default AuthForm;
